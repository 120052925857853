import NavItem from './NavItem'

const AdsIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path d="M6.375 7.875H11.625" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.25 13.8223H8.25L11.5875 16.0423C12.0825 16.3723 12.75 16.0198 12.75 15.4198V13.8223C15 13.8223 16.5 12.3223 16.5 10.0723V5.57227C16.5 3.32227 15 1.82227 12.75 1.82227H5.25C3 1.82227 1.5 3.32227 1.5 5.57227V10.0723C1.5 12.3223 3 13.8223 5.25 13.8223Z" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

const AdsMenu = () => (
  <NavItem to="/ads">
    <div className="flex flex-col items-center"><AdsIcon /></div>
    <span className="ml-2 leading-[15px]">Ads</span>
  </NavItem>
)

export default AdsMenu
