import { useState, useEffect } from 'react'
import NotificationManager from '~/services/NotificationManager'
import Input from '~/components/layout/Input'
import Button from '~/components/layout/Button'
import Drawer from '~/components/layout/Drawer'
import BlockAdvertiser from '~/components/Settings/BlockAdvertiser'
import { useGetUserQuery, useUpdateUserMutation } from '../store/api/userApiSlice'

const Settings = () => {
  const [showAdvertisers, setShowAdvertisers] = useState(false)
  const [user, setUser] = useState()
  const { data, status } = useGetUserQuery()
  useEffect(() => {
    if(status === "fulfilled") setUser(data?.user)
  }, [status])

  const [updateUserData, { isSuccess }] = useUpdateUserMutation();

  const onNameChange = e => {
    setUser(prev => ({ ...prev, name: e.target.value }))
  }

  const onCurrentPasswordChange = e => {
    setUser(prev => ({ ...prev, current_password: e.target.value }))
  }

  const onNewPasswordChange = e => {
    setUser(prev => ({ ...prev, password: e.target.value }))
  }

  const onConfirmPasswordChange = e => {
    setUser(prev => ({ ...prev, password_confirmation: e.target.value }))
  }

  const updateBlockedAdvertisers = (selected) => {
    updateUser({blocked_advertisers: selected})
    setShowAdvertisers(false)
  }

  const onSave = () => {
    const payload = {
      name: user.name
    }

    if (user.current_password?.length) {
      payload.current_password = user.current_password
      payload.password = user.password
      payload.password_confirmation = user.password_confirmation
    }
    updateUser(payload);
  }

  const updateUser = (payload) => {
    updateUserData(payload).unwrap().then((data) => {
      NotificationManager.success({ boldText: 'User', text: 'have been successfully updated' })
    })
    .catch(e => {
      if (e.response.status === 422) {
        NotificationManager.error({ boldText: 'Error', text: e.response.data.errors.join('. ') })
      } else {
        NotificationManager.error({ boldText: 'Error', text: 'We are sorry but something went wrong' })
      }
    })
  }

  return (
    <div className="flex flex-row flex-wrap flex-1 items-center justify-center flex-grow content-start">
      <div className="w-[500px] flex flex-col">
        <div className="text-center text-8 my-14">
          Settings
        </div>
        {user &&
          <>
            <div className='w-max'>
              <Button secondary onClick={() => setShowAdvertisers(prev => !prev)}>
                <span className="ml-1">Block Advertisers</span>
              </Button>
            </div>
            <div className="text flex-col">
              <div className="text mt-6">
                <label className="text-sm text-secondary font-medium">Name</label>
                <div className="mt-2">
                  <Input large value={user.name} onChange={onNameChange} />
                </div>
              </div>
              <div className="text mt-4">
                <label className="text-sm text-secondary font-medium">Current Password</label>
                <div className="mt-2">
                  <Input type="password" autoComplete="current-password" large value={user.current_password} onChange={onCurrentPasswordChange} />
                </div>
              </div>
              <div className="text mt-4">
                <label className="text-sm text-secondary font-medium">New Password</label>
                <div className="mt-2">
                  <Input type="password" autoComplete="new-password" large value={user.password} onChange={onNewPasswordChange} />
                </div>
              </div>
              <div className="text mt-4">
                <label className="text-sm text-secondary font-medium">Confirm Password</label>
                <div className="mt-2">
                  <Input type="password" autoComplete="new-password" large value={user.password_confirmation} onChange={onConfirmPasswordChange} />
                </div>
              </div>
            </div>
            <div className="flex justify-end mt-6">
              <Button onClick={onSave}>Save</Button>
            </div>
          </>
        }
      </div>
      {showAdvertisers &&
        <Drawer isOpen={showAdvertisers} setIsOpen={setShowAdvertisers} >
          <div className="flex flex-col h-full py-5">
            <BlockAdvertiser id="block_advertiser" name="Block Advertisers" setShowAdvertisers={setShowAdvertisers} blocked={user.blocked_advertisers} updateBlockedAdvertisers={updateBlockedAdvertisers}/>
          </div>
        </Drawer>
      }
    </div>
  )
}

export default Settings
