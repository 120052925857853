import React, { useState, useEffect } from 'react'
import { Outlet, useParams, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setUpdateSwipe } from '~/store/reducers/updateSwipeSlice'
import api from '~/api'
import Button from '~/components/layout/Button'
import Tabs from '~/components/layout/Tabs'
import EditIcon from '~/components/icons/Edit'
import TrashIcon from '~/components/icons/Trash'
import AmazonProductContent from '~/components/Listings/AmazonProductContent'
import AliExpressProductContent from '~/components/Listings/AliExpressProductContent'
import AdContent from '~/components/Listings/AdContent'
import TikTokPostContent from '~/components/Listings/TikTokPostContent'
import TrendingProductContent from '~/components/Listings/TrendingProductContent'
import AdvertiserContent from '~/components/Listings/AdvertiserContent'
import AdProductContent from '~/components/Listings/AdProductContent'
import EditListingContainer from '~/components/Listings/EditListing'
import ShareSwipe from '~/components/Listings/ShareSwipe'
import Modal from '~/components/layout/Modal'

const Listing = () => {
  const params = useParams()
  const id = parseInt(params.id)
  const [swipe, setSwipe] = useState()
  const [isEditOpen, setIsEditOpen] = useState(false)
  const [isDeleteOpen, setDeleteIsOpen] = useState(false)
  const [isShareOpen, setShareIsOpen] = useState(false)
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState('amazon_product')
  const [swipeGroups, setSwipeGroups] = useState([])
  const dispatch = useDispatch();

  useEffect(() => {
    api.apiSwipes.get({ id }).then(data => setSwipe(data.swipe))
  }, [id])

  useEffect(() => {
    api.apiSwipeGroups.list().then(data => {
      setSwipeGroups(data.swipeGroups)
    })
  }, [])

  const onTabClick = tab => {
    setActiveTab(tab)
  }

  const updateSwipe = (name, keyword, groupId) => {
    api.apiSwipes.update({ data: { id: id, swipe: { name, trigger_keyword: keyword, swipe_group_id: groupId } } })
    .then(data => {
      setSwipe(prev => ({ ...prev, name: data.swipe.name }))
      dispatch(setUpdateSwipe(true))
      setIsEditOpen(false)
    })
  }

  const deleteSwipe = () => {
    api.apiSwipes.destroy({ id }).then(() => {
      deleteSwipe(id)
      dispatch(setUpdateSwipe(true))
      navigate('/')
    })
  }

  const renderTab = () => {
    switch (activeTab) {
      case 'amazon_product': return <AmazonProductContent swipeId={swipe.id} update={setSwipe} />
      case 'ali_express_product': return <AliExpressProductContent swipeId={swipe.id} update={setSwipe} />
      case 'tik_tok_post': return <TikTokPostContent swipeId={swipe.id} update={setSwipe} />
      case 'ad': return <AdContent swipeId={swipe.id} update={setSwipe} />
      case 'trending_product': return <TrendingProductContent swipeId={swipe.id} update={setSwipe} />
      case 'advertiser': return <AdvertiserContent swipeId={swipe.id} update={setSwipe} />
      case 'ad_product': return <AdProductContent swipeId={swipe.id} update={setSwipe} />
      default: return null
    }
  }

  if (!swipe) {
    return null
  }

  const tabsOptions = [
    { value: 'amazon_product', label: 'Amazon Products', extra: swipe.productsCount.toString() },
    { value: 'ali_express_product', label: 'AliExpress Supplier', extra: swipe.aliExpressProductsCount.toString() },
    { value: 'tik_tok_post', label: 'TikTok Posts', extra: swipe.tikTokPostsCount.toString() },
    { value: 'ad', label: 'Ads', extra: swipe.adsCount.toString() },
    { value: 'trending_product', label: 'Trending Products', extra: swipe.trendingProductsCount.toString() },
    { value: 'advertiser', label: 'Advertisers', extra: swipe.advertisersCount.toString() },
    { value: 'ad_product', label: 'AdProduct', extra: swipe.adProductsCount.toString() }
  ]

  return (
    <div className="flex flex-row flex-wrap flex-1 flex-grow content-start py-[38px] px-[50px]">
      <div className="text-sm font-bold uppercase">Swipes</div>
      <div className="relative w-full overflow-x-auto mt-2">
        <div className="flex w-full justify-between">
          <div className="flex text-8">
            { swipe.name }
          </div>
          <div className="flex">
            <div className="flex mr-4">
              <Button secondary noShadow onClick={() => setShareIsOpen(true)}>
                <div className="flex items-center">
                  <span className="ml-1">Share</span>
                </div>
              </Button>
            </div>
            <div className="flex mr-4">
              <Button secondary noShadow red onClick={() => setDeleteIsOpen(true)}>
                <div className="flex items-center">
                  <TrashIcon />
                  <span className="ml-1">Delete</span>
                </div>
              </Button>
            </div>
            <div className="flex">
              <Button secondary noShadow onClick={() => setIsEditOpen(true)}>
                <div className="flex items-center">
                  <EditIcon />
                  <span className="ml-1">Edit</span>
                </div>
              </Button>
            </div>
          </div>
        </div>
        <div className="flex mt-12">
          <Tabs options={tabsOptions} tab={activeTab} onChange={onTabClick} />
        </div>
        { renderTab() }
        <Outlet />
      </div>
      <Modal isOpen={isShareOpen} onClose={() => setShareIsOpen(false)} headerText="Share Swipe">
        <ShareSwipe swipe={swipe} />
      </Modal>
      <Modal isOpen={isEditOpen} onClose={() => setIsEditOpen(false)} headerText="Edit Swipe">
        <EditListingContainer groups={swipeGroups} name={swipe.name} keyword={swipe.triggerKeyword} swipeGroupId={swipe.swipeGroupId} onCancel={() => setIsEditOpen(false)} onSubmit={updateSwipe} />
      </Modal>
      <Modal isOpen={isDeleteOpen} onClose={() => setDeleteIsOpen(false)} headerText="Delete Swipe">
        <div className="flex flex-col">
          <div className="flex text-secondary">Are you sure you want to delete this swipe?</div>
          <div className="flex flex-1 mt-6">
            <div className="flex flex-1">
              <Button full secondary onClick={() => setDeleteIsOpen(false)}>Cancel</Button>
            </div>
            <div className="flex flex-1 ml-6" onClick={deleteSwipe}>
              <Button red full>Delete</Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default React.memo(Listing)
