import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import ReactPaginate from 'react-paginate'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import Card from './Card'

dayjs.extend(relativeTime)
dayjs.extend(LocalizedFormat)

const Videos = props => {

  return (
    <div className="flex flex-col w-full xl:py-9 lg:py-8 py-6 xl:px-[60px] lg:px-[40px] px-[20px]">
      <div className="flex w-full justify-between mb-4">
        <div className="flex text-8">
          Videos
        </div>
      </div>
      <div className="w-full grid gap-6 grid-cols-4 -mt-1 lg:gap-6 lg:grid-cols-4 md:gap-4 md:grid-cols-3 sm:gap-2 sm:grid-cols-2">
        {
          props.products.map((p, index) => (
            <Card
              id={p.id}
              key={index}
              onClick={props.onProductClick}
              onVideoDelete={props.onDelete}
              productId={p.productId}
              videoUrl={p.videoUrl}
              title={p.title}
              createdAt={dayjs(p.createdAt).fromNow()}
            />
          ))
        }
      </div>
      {props.pagination.pagesCount > 1 &&
        <ReactPaginate
          onPageChange={props.onPageChange}
          pageRangeDisplayed={5}
          pageCount={props.pagination.pagesCount}
          renderOnZeroPageCount={null}
          className="pagination justify-end mt-4"
          previousLabel="Previous"
          nextLabel="Next"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          forcePage={props.pagination.page - 1}
        />
      }
    </div>
  )
}

export default Videos
