import React, { useState, useEffect } from 'react'
import { useParams, useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import api from '~/api'
import TrendingComponent from '~/components/Trending/Trending'

const Trending = () => {
  const [products, setProducts] = useState([])
  const [pagination, setPagination] = useState({totalCount: 1, page: 1, pagesCount: 1})
  const [searchParams, setSearchParams] = useSearchParams()
  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const onFiltersChange = value => {
    setSearchParams(value)
  }

  const created_at_from = searchParams.get('created_at_from')
  const created_at_to = searchParams.get('created_at_to')

  const filters = (() => {
    const result = {}

    if (created_at_from) {
      result.created_at_from = Number(created_at_from)
    }

    if (created_at_to) {
      result.created_at_to = Number(created_at_to)
    }

    return result
  })()

  const fetchTrendingProduct = (page = pagination.page) => {
    api.apiTrending.list({ query: { type: params.type, filters, page: page } }).then(data => {
      setPagination(data.meta)
      setProducts(data.trendingProducts)
    })
  }

  useEffect(() => {
    if (!params.type?.length) {
      navigate('/trending/aliexpress')
    } else {
      fetchTrendingProduct()
    }
  }, [params.type, created_at_from, created_at_to])

  const onTabClick = tab => {
    navigate(`/trending/${tab}`)
    setProducts([])
    setPagination({totalCount: 1, page: 1, pagesCount: 1})
  }

  const onProductClick = id => {
    setSearchParams({ ...filters, productId: id })
  }

  const appendSwipeId = (id, swipeId) => {
    setProducts(prev => prev.map(p => (
      p.productId === id
      ? { ...p, swipesIds: [...p.swipesIds, swipeId] }
      : p
    )))
  }

  const onPageChange = ({selected}) => {
    fetchTrendingProduct(selected + 1)
  }

  return (
    <TrendingComponent
      products={products}
      pagination={pagination}
      filters={filters}
      onPageChange={onPageChange}
      onFiltersChange={onFiltersChange}
      onTabClick={onTabClick}
      onProductClick={onProductClick}
      appendSwipeId={appendSwipeId}
    />
  )
}

export default React.memo(Trending)
