import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import Video from './Video'
import TrashIcon from '~/components/icons/Trash'
import { useRef } from 'react'

dayjs.extend(relativeTime)

const Card = props => {
  const deleteIconRef = useRef()

  const handleClick = () => {
    props.onClick(props.productId)
  }

  const handleDelete = (e) => {
    e.stopPropagation();
    e.preventDefault();
    props.onVideoDelete(props.id)
  }

  return (
    <div className="relative flex flex-col w-full mt-6 cursor-pointer rounded-lg bg-white shadow-[0_4px_50px_rgba(0,0,0,0.05)]" onClick={handleClick} onMouseEnter={() => deleteIconRef.current && deleteIconRef.current.classList.toggle('hidden')} onMouseLeave={() => deleteIconRef.current && deleteIconRef.current.classList.toggle('hidden')}>
      <div className="flex flex-col flex-auto w-full xl:p-5 lg:p-3 p-2">
        <div className="flex justify-center w-full h-full grow-1">
          <Video url={props.videoUrl} id={props.id}/>
        </div>
        <div className="flex flex-col w-full min-w-0 mt-4">
          <div className="flex text-[20px] leading-8 items-center">
            <div className="flex-auto truncate">{ props.title }</div>
          </div>
          <div className="flex w-full mt-1 text-xs text-gray leading-5">Video Created { props.createdAt }</div>
        </div>
      </div>
      <div className="flex hidden absolute top-0 right-0 bg-white p-3 rounded-full shadow-lg" onClick={handleDelete} ref={deleteIconRef} style={{boxShadow: "-5px 5px 5px 0px rgba(0,0,0,0.5)"}}><span onClick={props.onDelete}><TrashIcon height={20} width={20} /></span></div>
    </div>
  )
}

export default Card
