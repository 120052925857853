import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { Link, useParams, useNavigate } from 'react-router-dom'
import relativeTime from 'dayjs/plugin/relativeTime'
import api from '~/api'
import NotificationManager from '~/services/NotificationManager'
import { nFormatter } from '~/helpers/formatter'
import ArrowLeftIcon from '~/components/icons/ArrowLeft'
import AdsList from '~/components/Ads/AdsList'
import AdCard from '~/components/Ads/AdCard'
import Tabs from '~/components/layout/Tabs'
import AddToListing from '~/components/Research/AddToListing'
import TikTokIcon from '~/components/icons/TikTok'
import InstagramIcon from '~/components/icons/Instagram'
import FacebookIcon from '~/components/icons/Facebook'

dayjs.extend(relativeTime)

const tabsOptions = [
  { value: 'all', label: 'All Platforms' },
  { value: 'tik_tok', label: <TikTokIcon /> },
  { value: 'instagram', label: <InstagramIcon /> },
  { value: 'facebook', label: <FacebookIcon /> },
]

const Advertiser = () => {
  const { id: advertiserId } = useParams()
  const [advertiser, setAdvertiser] = useState()
  const [activeTab, setActiveTab] = useState('all')
  const [ads, setAds] = useState({ ads: [], meta: {} })
  const [currentPage, setCurrentPage] = useState(1)
  const [isModalOpen, setModalOpen] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const navigate = useNavigate()

  useEffect(() => {
    api.apiAdvertisers.get({ id: advertiserId })
    .then(data => setAdvertiser(data.advertiser))
  }, [advertiserId])

  useEffect(() => {
    api.apiAds.list({ query: { page: currentPage, type: 'advertiser', advertiser_id: advertiserId, tab: activeTab  } }).then(data => {
      setAds(data)
    })
  }, [currentPage, activeTab])

  const onPageChange = ({ selected }) => {
    setCurrentPage(selected + 1)
  }

  const onAdClick = id => {
    navigate(`/ads/${id}`)
  }

  const addToSwipe = id => {
    setSelectedProduct(id)
    setModalOpen(true)
  }

  const onModalClose = () => {
    setModalOpen(false)
    setSelectedProduct(null)
  }

  const onSubmitToSwipe = (swipeId, successFn) => {
    api.apiSwipes.addProducts({ data: {
      id: swipeId,
      product_type: 'Ad',
      product_ids: [selectedProduct]
    } }).then(() => {
      setAds(prev => ({
        ...prev,
        ads: prev.ads.map(ad => (
          ad.id === selectedProduct
          ? { ...ad, swipesIds: [...ad.swipesIds, swipeId] }
          : ad
        ))
      }))
      if (typeof successFn === 'function') successFn()
      onModalClose()
      NotificationManager.success({ boldText: 'Ad', text: 'has been successfully added to swipe' })
    })
  }

  if (!advertiser) return

  return (
    <div className="flex flex-row flex-wrap flex-1 flex-grow content-start py-4 px-[60px]">
      <div className="w-full flex flex-wrap mt-2  ">
        <Link className="flex items-center w-[60px] ml-[-15px] mr-[15px] px-[15px] cursor-pointer" to={-1}><ArrowLeftIcon /></Link>
      </div>
      <div className="w-full mt-6">
        <div className="text-8 font-medium">
          Advertiser Details
        </div>
      </div>
      <div className="w-full mt-10">
        <div className="flex">
          <div className="flex flex-col w-[345px] min-w-[345px]">
            <img src={advertiser.logoUrl} />
          </div>
          <div className="flex-auto ml-10">
            <div className="flex-auto flex flex-col bg-white rounded-lg p-[30px] shadow-[0_4px_50px_rgba(0,0,0,0.05)]">
              <div className="flex justify-between">
                <div className="flex">
                  <div className="flex flex-col ml-3">
                    <div className="flex items-center">
                      <div className="text-lg font-medium leading-5">{ advertiser.name }</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray/10 rounded-lg mt-[30px] p-6">
                <div className="flex flex-0 w-full justify-between items-center font-medium">
                  <div className="flex-1 flex flex-col items-center border-r border-r-gray/20">
                    <div className="">{ nFormatter(advertiser.impressions) }</div>
                    <div className="mt-1 text-[8px] text-gray uppercase tracking-wider">Impressions</div>
                  </div>
                  <div className="flex-1 flex flex-col items-center border-r border-r-gray/20">
                    <div className="">{ nFormatter(advertiser.likes) }</div>
                    <div className="mt-1 text-[8px] text-gray uppercase tracking-wider">Likes</div>
                  </div>
                  <div className="flex-1 flex flex-col items-center border-r border-r-gray/20">
                    <div className="">{ nFormatter(advertiser.comments) }</div>
                    <div className="mt-1 text-[8px] text-gray uppercase tracking-wider">Comments</div>
                  </div>
                  <div className="flex-1 flex flex-col items-center border-r border-r-gray/20">
                    <div className="">{ nFormatter(advertiser.shares) }</div>
                    <div className="mt-1 text-[8px] text-gray uppercase tracking-wider">Shares</div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col mt-[30px] rounded-lg border border-gray/20 text-sm">
                <div className="flex-auto flex border-b border-b-gray/20">
                  <div className="flex-0 basis-[195px] p-6 border-r border-r-gray/20 min-w-[195px]">
                    Link
                  </div>
                  <div className="flex-auto px-6 py-3 break-all">
                  {advertiser.links.map(link => (
                    <li key={link}>{link}</li>
                  ))}
                  </div>
                </div>
                <div className="flex-auto flex border-b border-b-gray/20">
                  <div className="flex-0 basis-[195px] p-6 border-r border-r-gray/20 min-w-[195px]">
                    Ads Count
                  </div>
                  <div className="flex-auto p-6 break-all">
                    { advertiser.adsCount }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full mt-10">
        <div className="text-xl">Ads</div>
        <div className="w-full mt-4">
          <div className="flex mb-">
            <Tabs options={tabsOptions} tab={activeTab} onChange={setActiveTab} />
          </div>
          <AdsList
            pagesCount={ads.meta.pagesCount}
            currentPage={currentPage}
            onPageChange={onPageChange}
          >
            {
              ads.ads.map(ad => (
                <AdCard
                  key={ad.id}
                  ad={ad}
                  onClick={onAdClick}
                  isFavourite={!!ad.swipesIds.length}
                  addToSwipe={addToSwipe}
                />
              ))
            }
          </AdsList>
        </div>
      </div>
      {isModalOpen &&
        <AddToListing selectedIds={[selectedProduct]} onSubmit={onSubmitToSwipe} onClose={onModalClose} />
      }
    </div>
  )
}

export default React.memo(Advertiser)
