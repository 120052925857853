import { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import api from '~/api'
import AdProductCard from '~/components/AdProducts/AdProductCard'
import AdsList from '~/components/Ads/AdsList'

dayjs.extend(relativeTime)

const AdProductContent = props => {
  const [adProducts, setAdProducts] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [pagesCount, setPagesCount] = useState(1)
  useEffect(() => {
    api.apiAdProducts.list({ query: { swipe_id: props.swipeId, page: currentPage } }).then(data => {
      setAdProducts(data.adProducts)
      setPagesCount(data.meta.pagesCount)
    })
  }, [props.swipeId, currentPage])

  const removeFromSwipe = id => {
    api.apiSwipes.deleteProducts({ data: {
      id: props.swipeId,
      product_type: 'AdProduct',
      product_ids: [id]
    } }).then(data => {
      setAdProducts(prev => prev.filter(ap => ap.id !== id))
      props.update(data.swipe)
    })
  }

  const onPageChange = ({ selected }) => {
    setCurrentPage(selected + 1)
  }

  return (
    <div className="">
      <AdsList
        pagesCount={pagesCount}
        currentPage={currentPage}
        onPageChange={onPageChange}
      >
        {
          adProducts.map(product => (
            <AdProductCard
              key={product.id}
              product={product}
              isFavourite
              addToSwipe={removeFromSwipe}
            />
          ))
        }
      </AdsList>
    </div>
  )
}

export default AdProductContent
