import LoadingIcon from '~/components/icons/Loading'

const Video = props => (
  <div className="m-4">
    {
      props.video.status === 'done'
      ? <video className="w-[200px] h-[200px]" controls autoPlay={false} muted playsInline>
        <source src={props.video.url} type="video/mp4" />
      </video>
      : <div className="flex items-center justify-center bg-gray1 w-[200px] h-[200px] text-lg bg-primary text-white">
        <LoadingIcon />Processing...
      </div>
    }
  </div>
)

export default Video
