import { useState, useRef, useEffect } from 'react'
import CheckCircleIcon from '~/components/icons/CheckCircle'
import NotificationManager from '~/services/NotificationManager'

const Notification = () => {
  const modalNode = useRef()
  const [notification, setNotification] = useState()
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    let hideId
    let nullifyId
    // TODO: add support for multiple notifications
    const removeListener = NotificationManager.on(message => {
      console.log('message', message)
      if (hideId) clearTimeout(hideId)
      if (nullifyId) clearTimeout(nullifyId)

      setNotification(message)
      setIsOpen(true)
      hideId = setTimeout(() => {
        setIsOpen(false)
        nullifyId = setTimeout(() => {
          setNotification(null)
        }, 250)
      }, message?.message?.time || 3000)
    })

    return removeListener
  }, [])

  useEffect(() => {
    if (isOpen) {
      modalNode.current.classList.remove('opacity-0')
      modalNode.current.classList.remove('pointer-events-none')
    } else {
      modalNode.current.classList.add('opacity-0')
      modalNode.current.classList.add('pointer-events-none')
    }
  }, [isOpen])

  return (
    <div ref={modalNode} className="transition-opacity opacity-0 z-[100] pointer-events-none fixed bottom-7 left-[50%] translate-x-[-50%] flex items-center justify-center rounded-[10px] shadow-[0_4px_50px_rgba(0,0,0,0.05)]">
      <div className="modal-container rounded-[10px] overflow-y-auto w-auto">
        <div className="flex items-center">
          <div className={`absolute flex items-center justify-center w-[75px] h-[75px] rounded-[10px] shadow-[0_10px_40px_rgba(7,19,48,0.1)] ${notification?.type === 'error' ? 'bg-error' : ( notification?.type == 'success' ? 'bg-success' : 'bg-secondary' )}`}>
            <CheckCircleIcon />
          </div>
          <div className="flex items-center h-[57px] pl-[89px] pr-4 bg-white">
            <span className="font-medium">{ notification?.message?.boldText }</span>
            <span className="text-secondary ml-1">{ notification?.message?.text }</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Notification
