import { useState } from 'react'
import { NavLink } from 'react-router-dom'

const NavItem = props => {
  const [isHover, setIsHover] = useState(false)

  const onMouseEnter = () => {
    setIsHover(true)
  }

  const onMouseLeave = () => {
    setIsHover(false)
  }

  return (
    <NavLink exact="false" to={props.to} className="w-full flex flex-col mt-1" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      {({ isActive }) => (
        <div className={`${isActive ? 'bg-[#3F71EF]' : 'bg-[#111112]'} rounded-[10px] pl-[2px]`}>
          <div className="bg-[#111112] rounded-lg">
            <div className={`px-3 py-[10px] flex items-center rounded-lg ${(isActive || isHover) ? 'text-white' : 'text-white/40'} ${isActive ? 'bg-white/[.07]' : ''}`}>
              { props.children }
            </div>
          </div>
        </div>
      )}
    </NavLink>
  )
}

export default NavItem
