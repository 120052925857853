import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Defaults to localStorage for web
import updateSwipeReducer from './reducers/updateSwipeSlice';
import amazonSearchProductsReducer from './reducers/amazonSearchProductsSlice';
import aliExpressSearchProductsReducer from './reducers/aliExpressSearchProductsSlice';
import trendsApi from './api/trendsApiSlice';
import userApi from './api/userApiSlice';

const persistedReducer = persistReducer({key: 'trends', storage}, trendsApi.reducer);
const userpersistedReducer = persistReducer({key: 'root', storage}, userApi.reducer);

const store = configureStore({
  reducer: {
    updateSwipe: updateSwipeReducer,
    amazonSearchProducts: amazonSearchProductsReducer,
    aliExpressSearchProducts: aliExpressSearchProductsReducer,
    [trendsApi.reducerPath]: persistedReducer,
    [userApi.reducerPath]: userApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat(trendsApi.middleware, userApi.middleware),
});

export const persistor = persistStore(store);
export const purgeState = () => persistor.purge()
export default store;
setupListeners(store.dispatch);
