import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { useParams, Link } from 'react-router-dom'
import relativeTime from 'dayjs/plugin/relativeTime'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import update from 'immutability-helper'
import api from '~/api'
import NotificationManager from '~/services/NotificationManager'
import Button from '~/components/layout/Button'
import EditableField from '~/components/Briefs/EditableField'
import BrandShow from '~/components/Briefs/BrandShow'
import BrandsList from '~/components/Briefs/BrandsList'
import BriefDetail from '~/components/Briefs/BriefDetail'
import NewBriefDetail from '~/components/Briefs/NewBriefDetail'
import NewBriefScene from '~/components/Briefs/NewBriefScene'
import BriefScene from '~/components/Briefs/BriefScene'
import Collapse from '~/components/Briefs/Collapse'
import SwipesList from '~/components/Briefs/SwipesList'
import AdCard from '~/components/Ads/AdCard'
import AdsList from '~/components/Ads/AdsList'
import ArrowLeftIcon from '~/components/icons/ArrowLeft'
import BrandProfileIcon from '~/components/icons/BrandProfile'
import BriefInspirationIcon from '~/components/icons/BriefInspiration'
import ModularDetailsIcon from '~/components/icons/ModularDetails'
import StoryboardIcon from '~/components/icons/Storyboard'
import NewInspirationIcon from '~/components/icons/NewInspiration'
import ShareIcon from '~/components/icons/Share'
import PreviewIcon from '~/components/icons/Preview'
import DropdownField from '~/components/Briefs/form/DropdownField'
import DueDateField from '~/components/Briefs/form/DueDateField'
import TextareaField from '~/components/Briefs/form/TextareaField'

dayjs.extend(relativeTime)

const statusOptions = [
  { value: 'todo', label: 'To Do' },
  { value: 'inprogress', label: 'In Progress' },
  { value: 'review', label: 'Needs Review' },
  { value: 'completed', label: 'Completed' },
]

const details = [
  { type: 'description', label: 'Description', description: 'Add a project description' },
  { type: 'size', label: 'Size & Aspect Ratios', description: 'Size & Aspect Ratios' },
  { type: 'format', label: 'Format', description: 'Add file format details' },
  { type: 'duration', label: 'Target Duration', description: 'Set the time' },
  { type: 'platforms', label: 'Platforms', description: 'Add platform detail' },
  { type: 'style', label: 'Content Style', description: 'Add content styling' },
  { type: 'products', label: 'Products', description: "Add project's products" },
  { type: 'assets', label: 'Assets & Files', description: 'Add assets and files' },
  { type: 'sound', label: 'Music and Sound', description: 'Add music or sound' }
]

const Brief = () => {
  const { id } = useParams()
  // const [swipeGroups, setSwipeGroups] = useState([])
  const [brief, setBrief] = useState()
  const [swipesListOpen, setSwipesListOpen] = useState(false)

  useEffect(() => {
    api.apiBriefs.get({ id }).then(data => setBrief(data.brief))
    // api.apiSwipeGroups.list().then(data => setSwipeGroups(data.swipeGroups))
  }, [])

  const onUpdate = (value, field) => {
    const payload = {
      data: {
        id,
        brief: { [field]: value }
      }
    }

    return api.apiBriefs.update(payload).then(data => setBrief(data.brief))
  }

  const onBrandSelect = brandId => {
    onUpdate(brandId, 'brief_brand_id')
  }

  const nullifyBrand = () => {
    setBrief(prev => ({ ...prev, briefBrand: null }))
  }

  const onBrandChange = e => {
    e.stopPropagation()
    nullifyBrand()
  }

  const onBrandDelete = () => {
    nullifyBrand()
  }

  const browseAds = e => {
    e.stopPropagation()
    setSwipesListOpen(true)
  }

  const onDetailAdd = detail => {
    setBrief(prev => ({ ...prev, details: [...prev.details, detail] }))
  }

  const onSceneAdd = scene => {
    setBrief(prev => ({ ...prev, scenes: [...prev.scenes, scene] }))
  }

  const onDetailDelete = detailId => {
    setBrief(prev => ({ ...prev, details: prev.details.filter(d => d.id !== detailId) }))
  }

  const onSceneDelete = sceneId => {
    setBrief(prev => ({ ...prev, scenes: prev.scenes.filter(s => s.id !== sceneId) }))
  }

  const onAdsAdd = ids => {
    api.apiBriefs.addAds({ data: { id: brief.id, ids } }).then(data => {
      setBrief(data.brief)
      setSwipesListOpen(false)
    })
  }

  const onAdRemove = adId => {
    api.apiBriefs.removeAd({ data: { id: brief.id, ad_id: adId } }).then(data => {
      setBrief(data.brief)
    })
  }

  const shortName = val => {
    const items = val.split(' ').splice(0, 2)
    if (items.length < 2) {
      return val.split(' ').splice(0, 2).map(el => el.charAt(0)).join('')
    }

    return val.slice(0, 2)
  }

  const moveScenePreview = (dragIndex, hoverIndex) => {
    setBrief(prev => ({
      ...prev,
      scenes: update(prev.scenes, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prev.scenes[dragIndex]],
        ],
      })
    }))
  }

  const onShare = () => {
    navigator.clipboard.writeText(`${window.location.origin}/public/briefs/${brief.cid}`)
    NotificationManager.success({ boldText: 'Share link', text: 'has been successfully copied to clipboard' })
  }

  const onPreview = () => {
    window.open(`/public/briefs/${brief.cid}`)
  }

  if (!brief) {
    return null
  }

  const brandId = brief.briefBrand?.id

  // const swipeGroupsOptions = swipeGroups.map(group => ({ value: group.id, label: group.name }))

  return (
    <div className="flex flex-col flex-1 flex-grow py-8 px-12">
      <div className="flex items-center">
        <Link to="/briefs"><ArrowLeftIcon /></Link>
        <div className="ml-4 text-lg font-medium">Create New Brief</div>
      </div>
      <div className="mt-8 flex-auto flex flex-col bg-white rounded-lg shadow-[0_12px_68px_0px_rgba(207,216,229,0.28)]">
        <div className="flex items-center justify-between flex p-6 border-b border-[#9FA6B7]/30">
          <div className="flex items-center">
            <div className="flex items-center justify-center text-white text-[20px] font-medium uppercase bg-[#96B4FF] w-12 h-12 rounded">{ shortName(brief.name) }</div>
            <div className="ml-5 flex items-center">
              <div className="text-2xl font-medium">
                <EditableField type="text" field="name" value={brief.name} onUpdate={onUpdate} minWidth="auto" icon />
              </div>
            </div>
          </div>
          <div className="ml-5 flex items-center">
            <div className="">
              <Button secondary onClick={onShare}><ShareIcon /><span className="ml-2">Share</span></Button>
            </div>
            <div className="ml-4">
              <Button secondary onClick={onPreview}><PreviewIcon /><span className="ml-2">Preview</span></Button>
            </div>
          </div>
        </div>
        <div className="flex flex-col flex px-6 py-7 border-b border-[#9FA6B7]/30">
          <div className="flex items-center">
            <div className="flex-none basis-[125px] flex items-center text-[#49546D]">
              <StatusIcon />
              <div className="ml-1 text-sm">Status:</div>
            </div>
            <div className="flex-auto flex">
              <div className="bg-gray2 rounded-[20px] px-2 py-1 text-sm font-medium flex items-center">
                <DropdownField
                  icon={<div className="mr-1 w-1.5 h-1.5 bg-[#3F71EF] rounded-full"></div>}
                  field="status"
                  value={brief.status}
                  options={statusOptions}
                  onChange={onUpdate}
                />
                {/*<EditableField type="select" field="status" options={statusOptions} label={statusOptions.find(s => s.value === brief.status)?.label} value={brief.status} onUpdate={onUpdate} />*/}
              </div>
            </div>
          </div>
          <div className="mt-2 flex items-center">
            <div className="flex-none basis-[125px] flex items-center text-[#49546D]">
              <DueDateIcon />
              <div className="ml-1 text-sm">Due Date:</div>
            </div>
            <div className="flex-auto flex">
              <div className="px-2 py-1 text-sm font-medium flex items-center">
                {/*<EditableField type="date" field="due_date" value={brief.dueDate} onUpdate={onUpdate} />*/}
                <DueDateField value={brief.dueDate} onUpdate={onUpdate} />
              </div>
            </div>
          </div>
          <div className="mt-2 flex items-center">
            <div className="flex-none basis-[125px] flex items-center text-[#49546D]">
              <DescIcon />
              <div className="ml-1 text-sm">Description:</div>
            </div>
            <div className="flex-auto flex">
              <div className="px-2 py-1 text-sm flex items-center">
                <TextareaField field="description" value={brief.description} onChange={onUpdate} placeholder="Add description" />
              </div>
            </div>
          </div>
        </div>
        <Collapse
          title="Brand Profile"
          description="Select the brand you are briefing for"
          icon={BrandProfileIcon}
          action={brandId &&
            <div>
              <Button onClick={onBrandChange}>Change Profile</Button>
            </div>
          }
        >
          {
            brandId
            ? <div className="flex flex-col">
              <BrandShow id={brandId} onDelete={onBrandDelete} />
            </div>
            : <BrandsList onSelect={onBrandSelect} />
          }
        </Collapse>
        <Collapse
          title="Inspirations"
          description="Attach or upload from your library"
          icon={BriefInspirationIcon}
          action={!!brief.ads.length &&
            <div>
              <Button onClick={browseAds}>Browse Swipe Files</Button>
            </div>
          }
        >
          { swipesListOpen && <SwipesList adIds={brief.ads.map(ad => ad.id)} onSelect={onAdsAdd} onClose={() => setSwipesListOpen(false)} /> }

          {
            brief.ads.length
            ? <div>
              <AdsList>
                {
                  brief.ads.map(ad => (
                    <AdCard
                      key={ad.id}
                      ad={ad}
                      isFavourite={false}
                      onUnselect={() => onAdRemove(ad.id)}
                      isSelected
                    />
                  ))
                }
              </AdsList>
            </div>
            : <div className="flex flex-col items-center justify-center">
              <div className="mb-5"><NewInspirationIcon /></div>
              <Button onClick={() => setSwipesListOpen(true)}>Browse Swipe Files</Button>
            </div>
          }
        </Collapse>
        <Collapse title="Modular Details" description="Write free-form or add the sections you need" icon={ModularDetailsIcon}>
          <div className="w-full flex flex-col">
            {
              brief.details.map(detail => (
                <BriefDetail
                  key={detail.id}
                  label={details.find(d => d.type === detail.detailType).label}
                  briefId={brief.id}
                  detail={detail}
                  onDelete={onDetailDelete}
                />
              ))
            }
            <div className="mt-2 flex">
              <NewBriefDetail details={details} brief={brief} onAdd={onDetailAdd} />
            </div>
          </div>
        </Collapse>
        <Collapse title="Storyboard" description="Layout your ad scene-by-scene" icon={StoryboardIcon}>
          <DndProvider backend={HTML5Backend}>
            <div className="w-full grid gap-4 grid-cols-3">
              {
                brief.scenes.map((scene, index) => (
                  <BriefScene
                    key={scene.id}
                    index={index}
                    briefId={brief.id}
                    scene={scene}
                    onDelete={onSceneDelete}
                    moveScenePreview={moveScenePreview}
                  />
                ))
              }
              <div className="mt-2 flex">
                <NewBriefScene brief={brief} onAdd={onSceneAdd} />
              </div>
            </div>
          </DndProvider>
        </Collapse>
      </div>
    </div>
  )
}

const StatusIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M10 1.66602V4.99935" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10 15V18.3333" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.10828 4.10742L6.46661 6.46576" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.5333 13.5332L15.8917 15.8915" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1.66663 10H4.99996" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15 10H18.3333" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M4.10828 15.8915L6.46661 13.5332" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.5333 6.46576L15.8917 4.10742" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

const DueDateIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M6.66663 1.66602V4.16602" stroke="currentColor" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.3334 1.66602V4.16602" stroke="currentColor" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.91663 7.57422H17.0833" stroke="currentColor" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18 7.16667V14.25C18 16.75 16.75 18.4167 13.8333 18.4167H7.16667C4.25 18.4167 3 16.75 3 14.25V7.16667C3 4.66667 4.25 3 7.16667 3H13.8333C16.75 3 18 4.66667 18 7.16667Z" stroke="currentColor" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.079 11.4167H13.0864" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.079 13.9167H13.0864" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.9962 11.4167H10.0037" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.9962 13.9167H10.0037" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.91197 11.4167H6.91945" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.91197 13.9167H6.91945" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

const DescIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M11.05 3.00002L4.20829 10.2417C3.94996 10.5167 3.69996 11.0584 3.64996 11.4334L3.34162 14.1333C3.23329 15.1083 3.93329 15.775 4.89996 15.6084L7.58329 15.15C7.95829 15.0834 8.48329 14.8084 8.74162 14.525L15.5833 7.28335C16.7666 6.03335 17.3 4.60835 15.4583 2.86668C13.625 1.14168 12.2333 1.75002 11.05 3.00002Z" stroke="currentColor" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.90833 4.20898C10.2667 6.50898 12.1333 8.26732 14.45 8.50065" stroke="currentColor" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M2.5 18.334H17.5" stroke="currentColor" strokeWidth="1.2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default React.memo(Brief)
