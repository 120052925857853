import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const trendsApi = createApi({
  reducerPath: 'trendsApi',
  baseQuery: fetchBaseQuery({ baseUrl: '/' }),
  endpoints: (builder) => ({
    getDailyTrends: builder.query({
      query: () => '/api/trending/daily_trends',
    }),
    getRealtimeTrends: builder.query({
      query: () => '/api/trending/realtime_trends',
    }),
  }),
});

export const { useGetDailyTrendsQuery, useGetRealtimeTrendsQuery } = trendsApi;
export default trendsApi;
