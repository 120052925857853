import NavItem from './NavItem'

const TrendingIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.16 13.613V12.0605" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" />
    <path d="M9 13.6128V10.5078" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" />
    <path d="M12.84 13.6123V8.94727" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" />
    <path d="M12.84 4.38672L12.495 4.79172C10.5825 7.02672 8.0175 8.60922 5.16 9.32172" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" />
    <path d="M10.6425 4.38672H12.84V6.57672" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.75 16.5H11.25C15 16.5 16.5 15 16.5 11.25V6.75C16.5 3 15 1.5 11.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 15 3 16.5 6.75 16.5Z" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

const ResearchMenu = () => (
  <NavItem exact="false" to="/trending">
    <div className="flex flex-col items-center"><TrendingIcon /></div>
    <span className="ml-2 leading-[15px]">Trending</span>
  </NavItem>
)

export default ResearchMenu
