import React, { useState } from 'react'
import ArrowDownSolidIcon from '~/components/icons/ArrowDownSolid'
import FolderIcon from '~/components/icons/Folder'
import TrashIcon from '~/components/icons/Trash'

const SwipeGroup = props => {
  const [isActive, setActive] = useState(props.isActive)

  return (
    <>
      <div className={`${isActive ? 'bg-[#3F71EF]' : 'bg-[#111112]'} rounded-[10px] pl-[2px]`} onClick={() => setActive(prev => !prev)}>
        <div className="bg-[#111112] rounded-lg">
          <div className={`px-3 py-[10px] flex items-center rounded-lg hover:text-white ${isActive ? 'text-white bg-white/[.07]' : 'text-white/40'}`}>
            <span className={isActive ? 'rotate-180' : ''}><ArrowDownSolidIcon /></span>
            <span className="ml-[7px]"><FolderIcon /></span>
            <span className="ml-2 flex-grow">{ props.name }</span>
            { isActive && !!props.children && props.children.length == 0 && <span onClick={props.onDelete}><TrashIcon /></span> }
          </div>
        </div>
      </div>
      { isActive && props.children }
    </>
  )
}

export default React.memo(SwipeGroup)
