import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import api from '~/api'
import NotificationManager from '~/services/NotificationManager'
import AdCard from '~/components/Ads/AdCard'
import AddToListing from '~/components/Research/AddToListing'
import Block from './Block'
import Carousel from './Carousel'

const itemWidth = 310

const TopTrendingProducts = () => {
  const [ads, setAds] = useState([])
  const navigate = useNavigate()
  const [isModalOpen, setModalOpen] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)

  useEffect(() => {
    api.apiDashboard.ads({ query: { limit: 10 } }).then(data => setAds(data.ads))
  }, [])

  const onViewAll = () => {
    navigate('/ads')
  }

  const onAdClick = id => {
    navigate(`/ads/${id}`)
  }

  const addToSwipe = id => {
    setSelectedProduct(id)
    setModalOpen(true)
  }

  const onModalClose = () => {
    setModalOpen(false)
    setSelectedProduct(null)
  }

  const onSubmitToSwipe = (swipeId, successFn) => {
    api.apiSwipes.addProducts({ data: {
      id: swipeId,
      product_type: 'Ad',
      product_ids: [selectedProduct]
    } }).then(() => {
      setAds(prev => (
        prev.map(ad => (
          ad.id === selectedProduct
          ? { ...ad, swipesIds: [...ad.swipesIds, swipeId] }
          : ad
        ))
      ))
      if (typeof successFn === 'function') successFn()
      onModalClose()
      NotificationManager.success({ boldText: 'Ad', text: 'has been successfully added to swipe' })
    })
  }

  return (
    <Block title="Top 10 Trending Ads" onViewAll={onViewAll}>
      <Carousel itemWidth={itemWidth + 30}>
        {
          ads.map((ad, index) => (
            <div style={{ width: `${itemWidth}px`, minWidth: `${itemWidth}px` }} className="mr-[30px]" key={index}>
              <AdCard
                ad={ad}
                onClick={onAdClick}
                isFavourite={!!ad.swipesIds.length}
                addToSwipe={addToSwipe}
              />
            </div>
          ))
        }
      </Carousel>
      {isModalOpen &&
        <AddToListing selectedIds={[selectedProduct]} onSubmit={onSubmitToSwipe} onClose={onModalClose} />
      }
    </Block>
  )
}

export default TopTrendingProducts
