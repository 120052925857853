import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'
import api from '~/api'
import AdsComponent from '~/components/Ads/Ads'
import AdStoreTable from '~/components/AdStores/Table'

const AdStores = () => {
  const [loading, setLoading] = useState(true)
  const [adStores, setAdStores] = useState([])

  useEffect(() => {
    api.apiAdStores.list({ query: { limit: 100 } }).then(data => {
      setAdStores(data.adStores)
      setLoading(false)
    })
  }, [])

  console.log('adStores', adStores)

  return (
    <AdsComponent>
      <div className="w-full flex flex-col">
        <div className="mt-[22px] flex text-8">AdStores</div>
        <div className="mt-12 flex flex-col">
          <AdStoreTable adStores={adStores} />
        </div>
      </div>
    </AdsComponent>
  )
}

export default React.memo(AdStores)
