import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import api from '~/api'
import NotificationManager from '~/services/NotificationManager'
import AddToListing from '~/components/Research/AddToListing'
import AdProductCard from '~/components/AdProducts/AdProductCard'
import Block from './Block'
import Carousel from './Carousel'

const itemWidth = 287

const OurPicks = () => {
  const [adProducts, setAdProducts] = useState([])
  const navigate = useNavigate()
  const [isModalOpen, setModalOpen] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)

  useEffect(() => {
    api.apiDashboard.ourPicks({ query: { limit: 20 } }).then(data => setAdProducts(data.adProducts))
  }, [])

  const addToSwipe = id => {
    setSelectedProduct(id)
    setModalOpen(true)
  }

  const onModalClose = () => {
    setModalOpen(false)
    setSelectedProduct(null)
  }

  const onSubmitToSwipe = swipeId => {
    api.apiSwipes.addProducts({ data: {
      id: swipeId,
      product_type: 'AdProduct',
      ad_product_id: selectedProduct
    } }).then(() => {
      setAdProducts(prev => (
        prev.map(product => (
          product.id === selectedProduct
          ? { ...product, swipesIds: [...product.swipesIds, swipeId] }
          : product
        ))
      ))
      setModalOpen(false)
      NotificationManager.success({ boldText: 'Product', text: 'has been successfully added to swipe' })
    })
  }

  return (
    <Block title="SignalBoost Sniped">
      <Carousel itemWidth={itemWidth + 30}>
        {
          adProducts.map(product => (
            <div style={{ width: `${itemWidth}px`, minWidth: `${itemWidth}px` }} className="mr-[30px]" key={product.id}>
              <AdProductCard
                product={product}
                isFavourite={!!product.swipesIds?.length}
                addToSwipe={addToSwipe}
              />
            </div>
          ))
        }
      </Carousel>
      {isModalOpen &&
        <AddToListing onSubmit={onSubmitToSwipe} onClose={onModalClose} />
      }
    </Block>
  )
}

export default OurPicks
