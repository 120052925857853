import { useState, useEffect, useRef } from 'react'
import Input from '~/components/layout/Input'
import CollapseArrowIcon from '~/components/icons/CollapseArrow'
import CheckboxCheckedIcon from '~/components/icons/CheckboxChecked'

const ALL_OPTIONS = { id: 'all', name: 'All' }

const SelectFilter = props => {
  const [isOpen, setIsOpen] = useState(false)
  const [selected, setSelected] = useState(props.selected || [])
  const [countryFilter, setCountryFilter] = useState('')
  const checkboxRef = useRef()

  const options = props.options.filter(option => option.name.indexOf(countryFilter) > -1)

  useEffect(() => {
    if (selected.length === 0 || selected.find(s => s === ALL_OPTIONS.id)) {
      props.onChange(props.id, ALL_OPTIONS.id)
    } else {
      props.onChange(props.id, selected)
    }
  }, [selected])

  useEffect(() => {
    if(props.selected && Array.isArray(props.selected)) {
      setSelected(props.selected)
    }
  }, [props.selected])

  const onCountryFilterChange = e => {
    setCountryFilter(e.target.value)
  }

  const onOptionSelect = (id, e) => {
    e.stopPropagation()
    setSelected(prev => {
      const allOptions = options.map(c => c.id)

      if (id === ALL_OPTIONS.id) {
        return prev.length === options.length ? [] : allOptions
      }

      if (prev.includes(id)) {
        return prev.filter(prevId => prevId !== id)
      }

      const newOptions = [...prev, id]

      // if (options.length - 1 === newOptions.length) {
      //   return allOptions
      // }

      if (options.length === newOptions.length) {
        return allOptions
      }

      return newOptions
    })
  }

  const isSelected = (id, parentId) => {
    if (id === ALL_OPTIONS.id) {
      return selected.length === options.length
    }

    return selected.includes(id) || selected.includes(parentId)
  }

  const onOptionClick = e => {
    if (e.target === checkboxRef.current) {
      return
    }
    e.preventDefault()
    setIsOpen(prev => !prev)
  }

  const filterEnabled = !!selected.length

  const onCheckboxClick = e => {
    e.stopPropagation()

    if (filterEnabled) {
      setSelected([])
      setIsOpen(false)
    }
  }

  return (
    <label htmlFor="select-filters-main" onClick={onOptionClick} className="flex flex-col cursor-pointer">
      <div className="flex items-start py-4 px-4 transition-colors hover:bg-blue1/10">
        <div onClick={onCheckboxClick} className={`flex items-center justify-center w-5 h-5 border border-gray rounded-[3px] ${filterEnabled ? 'bg-blue1 border-none' : ''}`}>
          { filterEnabled && <div><CheckboxCheckedIcon /></div> }
        </div>
        <div className="flex flex-auto w-full leading-4 ml-2">
          <div className="flex flex-auto items-center text-sm font-medium select-none">
            { props.name }
          </div>
          <div className="flex items-center w-4">
            <CollapseArrowIcon className={`duration-250 transition-transform ${isOpen ? 'rotate-[-180deg]' : 'rotate-0'}`} />
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-auto items-start justify-between ml-7 px-4" onClick={e => e.stopPropagation()}>
        <div className={`border-b border-b-blue1/20 flex flex-col w-full transition-[max-height] ease-[cubic-bezier(0,1,0,1)] duration-250 ${isOpen ? 'ease-in-out max-h-[300px] mb-4 pb-4' : 'max-h-0'}`}>
          <div className="overflow-y-auto">
            <div className="border-b border-b-blue1/20">
              <Input value={countryFilter} onChange={onCountryFilterChange} placeholder="Search" />
            </div>
            {
              options.map(option => (
                <div key={option.id} className="flex items-center my-2 mt-4 last:mb-0">
                  <input id={`option-filters-checkbox-${option.id}`} checked={isSelected(option.id)} onChange={onOptionSelect.bind(this, option.id)} type="checkbox" className="w-5 h-5" />
                  <label htmlFor={`option-filters-checkbox-${option.id}`} className="flex flex-auto w-full leading-4 ml-2">
                    <div className="flex w-full">
                      <div className="flex flex-col flex-auto">
                        <div className="flex flex-auto items-center text-sm font-medium select-none">{ option.component || option.name }</div>
                      </div>
                    </div>
                  </label>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </label>
  )
}

export default SelectFilter
