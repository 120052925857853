import { useState, useEffect } from 'react'

const Gallery = props => {
  const [mainImage, setMainImage] = useState(null)
  const [images, setImages] = useState([])

  useEffect(() => {
    if(props.images) {
      const fetchPromises = props.images.map((img) => fetchImage(img));
      Promise.all(fetchPromises)
        .then((resolvedImages) => {
          setImages(resolvedImages.filter((image) => image !== null));
        })
        .catch((error) => {
          console.error('Error fetching images:', error);
        });
    }
  }, [])

  useEffect(() => {
    if(images && images.length > 0) setMainImage(images[0])
  },[images])

  const onImageClick = img => {
    setMainImage(img)
  }

  const fetchImage = (src) => {
    if (src.includes('alicdn.com')) {
      return fetch(src, {
        referrerPolicy: 'no-referrer',
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.blob();
        })
        .then((blob) => {
          return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = () => {
              resolve(reader.result);
            };
            reader.readAsDataURL(blob);
          });
        })
        .catch((error) => {
          console.error('Error fetching image:', error);
          return null; // Handle errors gracefully, return null or some default value
        });
    } else {
      return Promise.resolve(src);
    }
  };
  return (
    <div className="flex flex-wrap w-full">
      <div className="flex flex-col items-center justify-center w-full rounded-lg overflow-hidden max-h-[500px]">
        {
          mainImage &&
          <>
          {
            mainImage?.endsWith('.mp4')
            ? <video className="h-[207px]" controls autoPlay={false} muted playsInline>
              <source src={mainImage} type="video/mp4" />
            </video>
            : <img className="object-contain h-[207px]" src={mainImage} />
          }
          </>
        }
      </div>
      <div className="w-full grid gap-2 grid-cols-4 mt-2">
        {
          images.map((image, index) => (
            <div key={index} className="flex flex-col items-center justify-center w-full rounded-lg overflow-hidden hover:scale-[1.03] transition-transform cursor-pointer" onClick={onImageClick.bind(this, image)}>
              {
                image?.endsWith('.mp4')
                ? <video className="object-contain w-[111px] h-[76px]" src={image} />
                : <img className="object-contain w-[111px] h-[76px]" src={image} />
              }
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default Gallery
