import { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import api from '~/api'
import NotificationManager from '~/services/NotificationManager'
import { Products } from '~/components/Research/AliExpressSearchProduct'
import BulkActions from '~/components/Listings/BulkActions'
import AddToListing from '~/components/Research/AddToListing'

dayjs.extend(relativeTime)

const AliExpressProductContent = props => {
  const [products, setProducts] = useState([])
  const [selectedProducts, setSelectedProducts] = useState([])
  const [isModalOpen, setModalOpen] = useState(false)
  const [pagination, setPagination] = useState(null)
  const [sortBy, setSortBy] = useState(["revenue", "desc"])
  const [page, setPage] = useState(1)

  const getProducts = () => {
    api.apiAliExpressProducts.list({ query: { swipe_id: props.swipeId, page, sortBy } }).then(data => {
      setProducts(data.products)
      setPagination(data.meta)
    })
  }

  const onPageChange = (e) => {
    setPage(e.selected + 1)
  }

  const onSort = (value) => {
    setSortBy(value)
    setPage(1)
  }

  useEffect(() => {
    getProducts()
  }, [page, sortBy, props.swipeId])

  const onBulkDelete = () => {
    api.apiSwipes.deleteProducts({ data: {
      id: props.swipeId,
      product_type: 'AliExpressProduct',
      product_ids: selectedProducts
    } }).then(data => {
      props.update(data.swipe)
      setProducts(prev => prev.filter(p => !selectedProducts.includes(p.productId)))
      setSelectedProducts([])
    })
  }

  const onBulkCancel = () => {
    setSelectedProducts([])
  }

  const onBulkSubmit = () => {
    setModalOpen(true)
  }

  const onModalClose = () => {
    setModalOpen(false)
    setSelectedProducts([])
  }

  const onSelectionChange = ids => {
    setSelectedProducts(ids)
  }

  const onSubmitToSwipe = swipeId => {
    api.apiSwipes.addProducts({ data: {
      id: swipeId,
      product_type: 'AliExpressProduct',
      product_ids: selectedProducts
    } }).then(() => {
      onModalClose()
      NotificationManager.success({ boldText: `${selectedProducts.length} product`, text: 'have been successfully added to swipe' })
    })
  }

  return (
    products && products.length > 0 &&
    <>
      <div>
        <Products
          data={products}
          pagination={pagination}
          sortBy={sortBy}
          onPageChange={onPageChange}
          onSort={onSort}
          selectedIds={selectedProducts}
          onSelectionChange={onSelectionChange}
        />
      </div>
      {
        isModalOpen
        ? <AddToListing exceptSwipeIds={[props.swipeId]} selectedIds={selectedProducts} onSubmit={onSubmitToSwipe} onClose={onModalClose} />
        : <BulkActions
          isOpen={selectedProducts.length}
          selectedIds={selectedProducts}
          onDelete={onBulkDelete}
          onCancel={onBulkCancel}
          onAddToSwipe={onBulkSubmit}
        />
      }
    </>
  )
}

export default AliExpressProductContent
