import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import api from '~/api'
import AdCard from '~/components/Ads/AdCard'

dayjs.extend(relativeTime)

const ProductTab = props => {
  const [ads, setAds] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    api.apiAds.list({ query: { type: 'product', ad_id: props.id, limit: 100 } })
    .then(data => setAds(data.ads))
  }, [props.id])

  const onAdClick = id => {
    navigate(`/ads/${id}`)
  }

  return (
    <div className="flex">
      <div className="w-full grid gap-6 grid-cols-4 -mt-1 lg:gap-6 lg:grid-cols-4 md:gap-4 md:grid-cols-3 sm:gap-2 sm:grid-cols-2">
        {
          ads.map(ad => (
            <AdCard
              key={ad.id}
              ad={ad}
              onClick={onAdClick}
              isFavourite={!!ad.swipesIds.length}
            />
          ))
        }
      </div>
    </div>
  )
}

export default React.memo(ProductTab)
