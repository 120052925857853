import React, { useEffect } from 'react'
import { useGetRealtimeTrendsQuery } from '../../store/api/trendsApiSlice'
import { LineChart, Line } from 'recharts';


function RealtimeTrends() {
  const { data, status, isLoading, isSuccess, error, refetch, startedTimeStamp } = useGetRealtimeTrendsQuery()

  useEffect(() => {
    if(status === "fulfilled" && (new Date() - new Date(startedTimeStamp) > 3600000)) refetch()
  }, [status])

  let content = <></>;
  if (isLoading){
    content = <p>Loading...</p>
  }
  else if (isSuccess && data){
    content = data.slice(0,5).map((item,index) => {
      return (
        <div className="border-slate-300 border-b-[0.5px] last:border-0" key={index}>
          <div className="py-4 px-8 flex items-start justify-center gap-7 h-full">
            <div className="flex-1 flex flex-row items-start justify-between h-full">
              <div className="flex flex-row items-start h-full">
                <div className="item-no pr-2 text-xl text-slate-500">{index+1}</div>
                <div className="title px-3 text-slate-800">
                  <div className="text-slate-800 text-xl overflow-hidden truncate line-clamp-1 whitespace-normal">{item["title"]}</div>
                  <div className="mt-1"><a className="text-sm text-indigo-500 overflow-hidden truncate line-clamp-2 whitespace-normal" href={item["articles"][0]["url"]} dangerouslySetInnerHTML={{ __html: item["articles"][0]["articleTitle"]}} /><span className="text-sm text-slate-400 ml-2">{item["articles"][0]["source"]} • {item["articles"][0]["time"]}</span></div>
                </div>
              </div>
              <div className="search-count flex flex-col h-[55px] justify-between min-w-[86px]">
                <div className="text-slate-800 text-xl">
                  <LineChart width={86} height={50} data={item["timelineData"].map(entry => ({time: new Date(parseInt(entry.time) * 1000), value: entry.value}))}>
                    <Line type="linear" dataKey="value" stroke="#8884d8" dot={false} />
                  </LineChart>
                </div>
                <div className="text-slate-400 text-[11px] w-full text-center">Past 24hr</div>
              </div>
            </div>
            <div className="image-wraper">
              <a href={item["image"]["newsUrl"]} >
                <div className="relative h-[86px] w-[86px]">
                  <img className="rounded-lg h-full w-full object-cover" src={item["image"]["imgUrl"]} />
                  <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 text-white text-[11px] leading-[11px] w-full text-center pb-1 px-1 bg-gradient-to-t from-black via-black to-transparent rounded-b-lg"  >
                    {item["image"]["source"]}
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      )
    })
  }

  return (
    <div className="h-full flex flex-col">
      <div className="text-8 my-[22px]">Realtime Search Trends</div>
      <div className="w-full flex-1 drop-shadow-[0_4px_10px_5px_rgba(0,0,0,0.05)] rounded-[10px] bg-white cursor-pointer">
          {content}
      </div>
    </div>
  )
}

export default RealtimeTrends
