import { useState, useEffect } from 'react'
import api from '~/api'

const BrandsList = props => {
  const [brands, setBrands] = useState([])

  useEffect(() => {
    api.apiBriefBrands.list().then(data => setBrands(data.briefBrands))
  }, [])

  const onClick = id => {
    props.onSelect(id)
  }

  const onCreate = () => {
    api.apiBriefBrands.create().then(data => {
      setBrands(prev => [...prev, data.briefBrand])
      props.onSelect(data.briefBrand.id)
    })
  }

  console.log('brands', brands)

  return (
    <div className="mt-5 w-full grid grid-cols-4 gap-4 text-sm">
      <div onClick={onCreate} className="group h-32 select-none cursor-pointer flex flex-col items-center justify-center rounded-xl border-2 border-dashed border-gray/20 hover:border-border-hover focus:border-border-gray1 transition duration-200">
        Create brand profile
      </div>
      {
        brands.map(brand => (
          <div key={brand.id} onClick={() => onClick(brand.id)} className="group h-32 cursor-pointer flex flex-col items-center justify-center rounded-xl border-2 border-gray/20 hover:border-border-hover focus:border-border-gray1 transition duration-200">
            <div className="w-10 h-10">
              {
                brand.logoUrl
                ? <img className="w-full h-full rounded-full" src={brand.logoUrl} />
                : <div className="w-full h-full rounded-full bg-gray/20" />
              }
            </div>
            <div className="mt-3">{ brand.name }</div>
          </div>
        ))
      }
    </div>
  )
}

export default BrandsList
