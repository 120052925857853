import { useState } from 'react'

const Toggle = props => {
  const [value, setValue] = useState(props.value)

  const handleClick = val => {
    setValue(val)
    props.onChange(val)
  }

  return (
    <div className="flex p-[3px] rounded-lg bg-[rgba(159,166,183,0.1)]">
      {
        props.options.map(option => (
          <div key={option.value} className={`rounded-md px-5 py-2 text-xs font-medium cursor-pointer bg-${option.value === value ? 'white' : 'inherit'}`} onClick={() => handleClick(option.value)}>
            { option.label }
          </div>
        ))
      }
    </div>
  )
}

export default Toggle
