import { useState, useEffect } from 'react'
import Input from '~/components/layout/Input'
import Select from '~/components/layout/Select'
import Button from '~/components/layout/Button'
import DatePicker from '~/components/layout/DatePicker'
import PencilIcon from '~/components/icons/Pencil'

const getEditComponent = type => {
  switch (type) {
    case 'text': return Input
    case 'select': return Select
    case 'date': return DatePicker
    default: return null
  }
}

const EditableField = props => {
  const { value: initialValue, label, type, field, ...editProps } = props
  const [isEditing, setIsEditing] = useState(false)
  const [value, setValue] = useState(props.value)

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  const onSave = () => {
    props.onUpdate(value, field).then(() => {
      setIsEditing(false)
    })
  }

  const onClick = () => {
    if (!isEditing) {
      setIsEditing(true)
    }
  }

  const onChange = e => {
    setValue(e.target.value)
  }

  const hasValue = label || value || props.placeholder

  const EditComponent = getEditComponent(type)

  return (
    <div className="flex">
      {
        isEditing && EditComponent
        ? <div className="flex items-center relative">
          <div className="flex rounded border border-gray/20">
            <EditComponent autoFocus value={value} onChange={onChange} {...editProps} />
          </div>
          <div className="flex ml-2">
            <Button onClick={onSave}>Save</Button>
          </div>
        </div>
        : <div onClick={onClick} style={{ minWidth: props.minWidth || (hasValue ? 'auto' : '200px') }} className="flex items-center rounded h-[38px] cursor-pointer border-b border-white hover:border-b hover:border-gray/20">
          { label || value || <div className="text-gray">{ props.placeholder }</div> }
          { props.icon && <div className="ml-2 cursor-pointer text-[#3F71EF]"><PencilIcon /></div> }
        </div>
      }
    </div>
  )
}

export default EditableField
