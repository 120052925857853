const Ebay = () => (
  <svg width="58" height="23" viewBox="0 0 58 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6_4)">
      <path d="M7.42046 5.02002C3.37287 5.02002 0 6.73698 0 11.9177C0 16.022 2.26804 18.6064 7.52478 18.6064C13.7124 18.6064 14.109 14.5305 14.109 14.5305H11.1109C11.1109 14.5305 10.4681 16.7252 7.34198 16.7252C4.79582 16.7252 2.96458 15.0052 2.96458 12.5946H14.4227V11.0816C14.4227 8.69666 12.9086 5.02002 7.42046 5.02002ZM7.31576 6.95347C9.73941 6.95347 11.3917 8.43825 11.3917 10.6636H3.03081C3.03081 8.30101 5.18764 6.95347 7.31576 6.95347Z" fill="#E53238"/>
      <path d="M14.4206 0.00335693V16.0048C14.4206 16.9131 14.3557 18.1884 14.3557 18.1884H17.2152C17.2152 18.1884 17.318 17.2725 17.318 16.4353C17.318 16.4353 18.7308 18.6457 22.5724 18.6457C26.6177 18.6457 29.3656 15.8369 29.3656 11.8133C29.3656 8.06983 26.8417 5.05912 22.5789 5.05912C18.5872 5.05912 17.3469 7.2146 17.3469 7.2146V0.00335693H14.4206ZM21.8408 7.03831C24.588 7.03831 26.335 9.07723 26.335 11.8133C26.335 14.7472 24.3173 16.6665 21.8606 16.6665C18.9285 16.6665 17.3469 14.377 17.3469 11.8393C17.3469 9.4746 18.766 7.03831 21.8408 7.03831Z" fill="#0064D2"/>
      <path d="M36.7785 4.65967C30.6894 4.65967 30.2989 7.99368 30.2989 8.52657H33.3297C33.3297 8.52657 33.4886 6.5801 36.5695 6.5801C38.5715 6.5801 40.1229 7.49658 40.1229 9.25814V9.88521H36.5695C31.8522 9.88521 29.3583 11.2653 29.3583 14.0658C29.3583 16.8218 31.6625 18.3213 34.7766 18.3213C39.0205 18.3213 40.3876 15.9763 40.3876 15.9763C40.3876 16.9091 40.4594 17.8282 40.4594 17.8282H43.1537C43.1537 17.8282 43.0494 16.6889 43.0494 15.96V9.65992C43.0494 5.52887 39.7173 4.65967 36.7785 4.65967ZM40.1229 11.7664V12.6025C40.1229 13.693 39.4499 16.4041 35.4884 16.4041C33.3192 16.4041 32.3891 15.3215 32.3891 14.0656C32.3891 11.7811 35.5212 11.7664 40.1229 11.7664Z" fill="#F5AF02"/>
      <path d="M41.1112 5.54297H44.5208L49.4143 15.3467L54.2964 5.54297H57.3852L48.4924 22.9967H45.2524L47.8185 18.1312L41.1112 5.54297Z" fill="#86B817"/>
    </g>
    <defs>
      <clipPath id="clip0_6_4">
        <rect width="57.3852" height="23" fill="white"/>
      </clipPath>
    </defs>
  </svg>
)

export default Ebay
