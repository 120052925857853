import api from '~/api'

const NewBriefScene = props => {
  const onCreate = () => {
    api.apiBriefScenes.create({ data: { brief_id: props.brief.id } }).then(data => {
      props.onAdd(data.briefScene)
    })
  }

  return (
    <div className="cursor-pointer p-4 mt-2">
      <div onClick={onCreate}>Add New &#x2795;</div>
    </div>
  )
}

export default NewBriefScene
