import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import api from '~/api'
import VideosComponent from '~/components/Videos/Videos'
import NotificationManager from '~/services/NotificationManager'

const Videos = () => {
  const navigate = useNavigate();

  const [products, setProducts] = useState([])
  const [pagination, setPagination] = useState({totalCount: 1, page: 1, pagesCount: 1})

  const fetchTrendingProduct = (page = pagination.page) => {
    api.apiTrendingVideos.list({ query: { page: page } }).then(data => {
      setPagination(data.meta)
      setProducts(data.trendingProductVideos)
    })
  }

  useEffect(() => {
      fetchTrendingProduct()
  }, [])

  const onProductClick = id => {
    const product = products.find(product => product.productId === id);
    if (product) {
      const url = `/trending/${product.productMarketplace}?productId=${id}`;
      navigate(url);
    }
  }

  const onDelete = id => {
    api.apiTrendingVideos.destroy({id}).then((res) => {
      setProducts(products.filter(item => item.id !== id))
    }).catch((err) => {
      NotificationManager.error({ boldText: 'Error', text: 'We are sorry but something went wrong' })
    });
  }

  const onPageChange = ({selected}) => {
    fetchTrendingProduct(selected + 1)
  }

  return (
    <VideosComponent
      products={products}
      pagination={pagination}
      onPageChange={onPageChange}
      onProductClick={onProductClick}
      onDelete={onDelete}
    />
  )
}

export default Videos
