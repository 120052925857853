import { useState, useEffect } from 'react'
import { useDebounce } from 'use-debounce'
import Input from '~/components/layout/Input'

const Products = props => {
  const [value, setValue] = useState(props.value || '')
  const [debouncedValue] = useDebounce(value, 500)

  useEffect(() => {
    if (debouncedValue !== props.value) {
      props.onChange(debouncedValue)
    }
  }, [debouncedValue])

  const onLinkChange = (val, index) => {
    setValue(prev => prev.map((p, i) => (
      i === index
      ? { ...p, link: val }
      : p
    )))
  }

  const onNotesChange = (val, index) => {
    setValue(prev => prev.map((p, i) => (
      i === index
      ? { ...p, notes: val }
      : p
    )))
  }

  const onNew = () => {
    const newVal = [...value, { link: '', notes: '' }]
    setValue(newVal)
    props.onChange(newVal)
  }

  const onDelete = index => {
    const newVal = value.filter((v, i) => i !== index)
    setValue(newVal)
    props.onChange(newVal)
  }

  return (
    <div className="flex flex-col p-4">
      {
        value.map((v, index) => (
          <div key={index} className="mt-4 w-full flex items-center first:mt-0 text-sm">
            <div className="flex-1">
              <div className="pl-4 font-medium">Link</div>
              <div>
                <Input
                  style={{ width: '40%' }}
                  value={v.link}
                  onChange={e => onLinkChange(e.target.value, index)}
                  placeholder="https://example.com"
                />
              </div>
            </div>
            <div className="flex-1">
              <div className="pl-4 font-medium">Notes</div>
              <div>
                <Input
                  style={{ width: '40%' }}
                  value={v.notes}
                  onChange={e => onNotesChange(e.target.value, index)}
                  placeholder="Description..."
                />
              </div>
            </div>
            <div className="flex-none cursor-pointer" onClick={() => onDelete(index)}>
              &#x2715;
            </div>
          </div>
        ))
      }
      <div className="mt-4 cursor-pointer text-sm" onClick={onNew}>Add New &#x2795;</div>
    </div>
  )
}

export default Products
