import { useRef } from 'react'
import { Link } from 'react-router-dom'
import ProgressiveImage from 'react-progressive-graceful-image'
import { Tooltip } from 'react-tooltip'
import lookup from 'country-code-lookup'
import { nFormatter } from '~/helpers/formatter'
import TrendingFavoriteIcon from '~/components/icons/TrendingFavorite'
import AudienceIcon from '~/components/icons/Audience'
import CalendarIcon from '~/components/icons/Calendar'

const getCountry = code => {
  if (!code) return ''

  return lookup.byIso(code)?.country || 'ALL'
}

const newVideoHeight = 300

const BriefRow = props => {
  // const [ad, setAd] = useState(props.ad)
  const { brief } = props
  const { briefBrand: brand, swipeGroup } = brief
  const videoRef = useRef()
  const handleClick = () => {
    props.onClick(ad.id)
  }

  const getVideoWidth = () => {
    if (!ad.size?.length) return 170

    const [videoWidth, videoHeight] = ad.size.split('/')
    return videoWidth * newVideoHeight / videoHeight
  }

  const onVideoMouseEnter = () => {
    videoRef.current.play()
  }

  const onVideoMouseLeave = () => {
    videoRef.current.pause()
  }

  const onVideoClick = e => {
    e.preventDefault()
    e.stopPropagation()
    e.target.muted = !e.target.muted
  }

  const addToSwipe = e => {
    e.stopPropagation()

    props.addToSwipe(ad.id)
  }

  return (
    <Link className="flex items-center px-3 py-5 border-b border-gray/20 cursor-pointer hover:bg-white/40 transition-all" to={`/briefs/${brief.id}`}>
      <div className="flex-1 basis-1/6">{ props.brief.name }</div>
      <div className="flex-1">{ brand?.name }</div>
      <div className="flex-1">{ swipeGroup.name }</div>
      <div className="flex-1">{ brief.status }</div>
      <div className="flex-1">{ brief.dueDate }</div>
    </Link>
  )
}

export default BriefRow
