import { useState, useEffect } from 'react'
import api from '~/api'
import Button from '~/components/layout/Button'
import SearchIcon from '~/components/icons/Search'
import ReactPaginate from 'react-paginate'

const BlockAdvertiser = props => {
  const [advertisers, setAdvertisers] = useState([])
  const [blockedAdvertiser, setBlockedAdvertiser] = useState([])
  const [advertiserSearch, setAdvertiserSearch] = useState('')
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(0)

  useEffect(() => {
    api.apiAdvertisers.advertisersList({ query: {page: page, search: advertiserSearch}}).then(data => {
      setAdvertisers(data.advertisers)
      setPageCount(data.meta.pageCount)
      if(page !== data.meta.page) setPage(data.meta.page)
    })
  }, [page, advertiserSearch])

  useEffect(() => {
    setBlockedAdvertiser(props.blocked)
  }, [props.blocked])

  const onPageChange = e => {
    setPage(e.selected + 1)
  }

  const handleBlock = (option) => setBlockedAdvertiser(prev => prev.includes(option.id) ? prev.filter(prevId => prevId !== option.id) : [...prev, option.id])

  return (
    <>
      <div className="flex flex-col items-start py-4 px-4 gap-4 sticky top-0 bg-white">
        <div className="flex font-bold">
          { props.name }
        </div>
        <div className="flex items-center w-full rounded-lg bg-white h-10 px-1.5 shadow-[0_4px_50px_rgba(0,0,0,0.05)]">
          <SearchIcon className="ml-3"/>
          <input value={advertiserSearch} onChange={(e) => setAdvertiserSearch(e.target.value)} type="search" className="form-control h-[30px] relative flex-auto min-w-0 block w-full px-2 text-base font-normal bg-white bg-clip-padding transition ease-in-out m-0 focus:outline-none" placeholder="Search" aria-label="Search" aria-describedby="button-addon3" />
        </div>
      </div>
      <div className='border-b border-b-blue1/20 flex flex-col grow:1 overflow-y-auto gap-6 mb-4 pb-4 mx-7 px-4' onClick={e => e.stopPropagation()}>
        {
          advertisers.map(option => (
            <div key={option.id} className="flex items-center gap-4">
              <input id={`option-filters-checkbox-${option.id}`} checked={blockedAdvertiser.includes(option.id)} onChange={() => handleBlock(option)} type="checkbox" className="w-4 h-4 cursor-pointer" />
              <label htmlFor={`option-filters-checkbox-${option.id}`} className="flex flex-auto items-center w-full leading-4 gap-2 cursor-pointer">
                <img className="rounded-full w-[35px] h-[35px]" src={option.logoUrl} />
                <div className="text-sm font-medium select-none">{ option.name }</div>
              </label>
            </div>
          ))
        }
        {
          pageCount > 1 &&
          <ReactPaginate
            onPageChange={onPageChange}
            pageRangeDisplayed={1}
            pageCount={pageCount}
            renderOnZeroPageCount={null}
            className="pagination justify-center"
            previousLabel="Previous"
            nextLabel="Next"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
          />
        }
      </div>
      <div className="mt-5 px-4 sticky bottom-0">
        <div className="flex flex-1 gap-6">
          <div className="flex-1">
            <Button large full className="w-full" style={{ height: 48 }} onClick={() => props.updateBlockedAdvertisers(blockedAdvertiser)}>Update</Button>
          </div>
          <div className="flex-1">
            <Button large full secondary className="w-full" style={{ height: 48 }} onClick={() => props.setShowAdvertisers(false)}>Close</Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default BlockAdvertiser
