import { useEffect, useState } from 'react'
import api from '~/api'
import RangeFilter from '~/components/Filters/RangeFilter'
import SelectFilter from '~/components/Filters/SelectFilter'
import DateFilter from '~/components/Filters/DateFilter'
import Button from '~/components/layout/Button'
import ArrowLeft from '~/components/icons/ArrowLeft'
import SearchIcon from '~/components/icons/Search'
import countriesMap from '~/helpers/countriesMap'

const checkFilterPresence = (key, value) => {
  switch (key) {
    case 'country_code':
    case 'cta_button':
    case 'platform':
    case 'objective':
    case 'landing_page': return value !== 'all' && value.length
    default: return value.min || value.max
  }
}

const landingPageOptions = [
  { id: 'landing', name: 'With Landing Page' },
  { id: 'no_landing', name: 'Without Landing Page' }
]

const categories = [
  { id: 'E-commerce', name: 'E-commerce' },
  { id: 'App', name: 'App' }
]

const Filters = props => {
  const [filters, setFilters] = useState(props.filters || {})
  const [filterValues, setFilterValues] = useState([])

  useEffect(() => {
    if (props.filters && Object.keys(props.filters)) {
      setFilters(props.filters)
    }
  }, [props.filters])

  useEffect(() => {
    api.apiAds.filterValues().then(data => setFilterValues(data.filterValues))
  }, [])

  const onChange = (field, value) => {
    setFilters(prev => ({ ...prev, [field]: value }))
  }

  const onApply = () => {
    const resultFilters = {}

    Object.keys(filters).forEach(filterKey => {
      const filterValue = filters[filterKey]

      if (checkFilterPresence(filterKey, filterValue)) {
        resultFilters[filterKey] = filterValue
      }
    })

    props.onChange(resultFilters)
  }

  const onCancel = () => {
    props.onCancel()
  }

  const countries = []
  const countryFilterValues = filterValues.find(v => v.filterType === 'country')

  Object.keys(countriesMap).forEach(code => {
    const data = countriesMap[code]

    if (countryFilterValues?.values.includes(data.name)) {
      countries.push({
        id: code,
        name: data.name,
        component: <div className="flex items-center">
          <span className="text-2xl mr-1">{ data.emoji }</span>
          <span>{ data.name }</span>
        </div>
      })
    }
  })

  const ctaButtonFilterValues = filterValues.find(v => v.filterType === 'cta_button')
  const ctaButtons = (ctaButtonFilterValues?.values || []).map(val => ({ id: val, name: val }))

  const platformFilterValues = filterValues.find(v => v.filterType === 'platform')
  const platforms = (platformFilterValues?.values || []).map(val => ({ id: val, name: val }))

  return (
    <div className="flex flex-col h-full py-5">
      <div className="flex items-center px-4">
        <div className="mr-4" onClick={props.onClose}><ArrowLeft /></div>
        <div className="flex text-xl">Filters</div>
      </div>
      <div className="flex mt-6 w-full px-4">
        <div className="flex flex-1">
          <div className="input-group relative flex items-center w-full rounded-lg bg-white h-9 px-1.5 shadow-[0_4px_50px_rgba(0,0,0,0.05)]">
            <SearchIcon className="ml-3" />
            <input type="search" className="form-control h-[30px] relative flex-auto min-w-0 block w-full px-2 text-base font-normal bg-white bg-clip-padding transition ease-in-out m-0 focus:outline-none" placeholder="Search" aria-label="Search" aria-describedby="button-addon3" />
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-auto mt-6 overflow-y-auto">
        <SelectFilter id="country_code" name="Countries" onChange={onChange} options={countries} selected={filters.country_code} />
        <SelectFilter id="objective" name="Category" onChange={onChange} options={categories} selected={filters.objective} />
        <DateFilter id="first_seen" name="First Seen" onChange={onChange} value={filters.first_seen} />
        <DateFilter id="last_seen" name="Last Seen" onChange={onChange} value={filters.last_seen} />
        <SelectFilter id="cta_button" name="CTA Button" onChange={onChange} options={ctaButtons} selected={filters.cta_button} />
        <SelectFilter id="platform" name="Platform" onChange={onChange} options={platforms} selected={filters.platform} />
        <SelectFilter id="landing_page" name="Landing Page" onChange={onChange} options={landingPageOptions} selected={filters.landing_page} />
        <RangeFilter id="impressions" name="Impressions" onChange={onChange} value={filters.impressions} />
        <RangeFilter id="popularity" name="Popularity" onChange={onChange} value={filters.popularity} />
        <RangeFilter id="seen_diff" name="Days" onChange={onChange} value={filters.seen_diff} />
        <RangeFilter id="like_rate" name="Like Rate" onChange={onChange} value={filters.like_rate} />
      </div>
      <div className="flex flex-col flex-0 mt-5 px-4">
        <div className="flex flex-1">
          <div className="flex flex-1">
            <Button large full className="w-full" style={{ height: 48 }} onClick={onApply}>Apply</Button>
          </div>
          <div className="flex flex-1 ml-6">
            <Button large full secondary className="w-full" style={{ height: 48 }} onClick={onCancel}>Cancel</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Filters
