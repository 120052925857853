import React from 'react'
import dayjs from 'dayjs'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import relativeTime from 'dayjs/plugin/relativeTime'
import AnalysisTab from '~/components/Ad/AnalysisTab'
import AdvertiserTab from '~/components/Ad/AdvertiserTab'
import ProductTab from '~/components/Ad/ProductTab'
import ArrowLeftIcon from '~/components/icons/ArrowLeft'

dayjs.extend(relativeTime)

const Ad = () => {
  const { id } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  const activeTab = searchParams.get('tab')

  const renderActiveTab = () => {
    switch (activeTab) {
      case 'advertiser': return <AdvertiserTab id={Number(id)} />
      case 'product': return <ProductTab id={Number(id)} />
      default: return <AnalysisTab id={Number(id)} />
    }
  }

  const onTabChange = tab => {
    if (tab?.length) {
      setSearchParams({ tab })
    } else {
      setSearchParams({})
    }
  }

  return (
    <div className="flex flex-row flex-wrap flex-1 flex-grow content-start py-4 px-[60px] select-none">
      <div className="w-full flex flex-wrap mt-2  ">
        <Link className="flex items-center w-[60px] ml-[-15px] mr-[15px] px-[15px] cursor-pointer" to={-1}><ArrowLeftIcon /></Link>
      </div>
      <div className="w-full mt-6">
        <div className="text-8 font-medium">
          Ad Details
        </div>
      </div>
      <div className="w-full mt-6">
        <div className="flex border-b border-b-gray/20 font-medium">
          <div onClick={() => onTabChange('')} className={`first:ml-0 ml-8 -mb-[1px] py-[11px] cursor-pointer ${!activeTab ? 'text-blue1 border-b-2 border-b-blue1' : 'text-secondary'}`}>
            Ad Analysis
          </div>
          <div onClick={() => onTabChange('advertiser')} className={`first:ml-0 ml-8 -mb-[1px] py-[11px] cursor-pointer text-secondary ${activeTab === 'advertiser' ? 'text-blue1 border-b-2 border-b-blue1' : 'text-secondary'}`}>
            Ads From Same Advertiser
          </div>
          <div onClick={() => onTabChange('product')} className={`first:ml-0 ml-8 -mb-[1px] py-[11px] cursor-pointer text-secondary ${activeTab === 'product' ? 'text-blue1 border-b-2 border-b-blue1' : 'text-secondary'}`}>
            Ads From Same Product Name
          </div>
        </div>
      </div>
      <div className="w-full mt-10">
        { renderActiveTab() }
      </div>
    </div>
  )
}

export default React.memo(Ad)
