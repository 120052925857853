import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import relativeTime from 'dayjs/plugin/relativeTime'
import api from '~/api'
import Tabs from '~/components/layout/Tabs'
import Button from '~/components/layout/Button'
import Input from '~/components/layout/Input'
import Modal from '~/components/layout/Modal'
import BriefRow from '~/components/Briefs/BriefRow'

dayjs.extend(relativeTime)

const tabsOptions = [
  { value: 'briefs', label: 'Briefs' },
  { value: 'brands', label: 'Brands' },
]

const Briefs = () => {
  const [briefs, setBriefs] = useState([])
  const navigate = useNavigate()
  const [isModalOpen, setModalOpen] = useState(false)
  const [newBriefName, setNewBriefName] = useState('')

  useEffect(() => {
    api.apiBriefs.list().then(data => {
      setBriefs(data.briefs)
    })
  }, [])

  const onNewBriefNameChange = e => {
    setNewBriefName(e.target.value)
  }

  const onBriefSubmit = () => {
    api.apiBriefs.create({ data: { brief: { name: newBriefName } } }).then(data => {
      setModalOpen(false)
      setBriefs(prev => [...prev, data.brief])
    })
  }

  const setActiveTab = tab => {
    navigate(`/${tab}`)
  }

  return (
    <div className="flex flex-row flex-wrap flex-1 flex-grow content-start py-4 px-[60px]">
      <div className="flex-1 mt-[22px] flex items-center justify-between">
        <div className="text-8 basis-[150px]">Briefs</div>
        <div className="flex-auto flex justify-center">
          <div className="flex">
            <Tabs options={tabsOptions} tab="briefs" onChange={setActiveTab} />
          </div>
        </div>
        <div className="text-8 min-w-[120px]">
          <Button onClick={() => setModalOpen(true)}>Create New</Button>
        </div>
      </div>
      <div className="w-full flex flex-col mt-5">
        <div className="flex items-center px-3 py-2 border-b border-gray/20 cursor-pointer text-gray">
          <div className="flex-1 basis-1/6">Name</div>
          <div className="flex-1">Brand</div>
          <div className="flex-1">Swipe Group</div>
          <div className="flex-1">Status</div>
          <div className="flex-1">Due Date</div>
        </div>
        {
          briefs.map(brief => (
            <BriefRow key={brief.id} className="" brief={brief} />
          ))
        }
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)} headerText="Create a Brief" wide>
        <Input value={newBriefName} onChange={onNewBriefNameChange} placeholder="Brief Name" />
        <div className="mt-4 flex justify-end">
          <Button onClick={() => onBriefSubmit()}>Create</Button>
        </div>
      </Modal>
    </div>
  )
}

export default React.memo(Briefs)
