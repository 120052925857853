import { useNavigate, Link } from 'react-router-dom'
import { nFormatter } from '~/helpers/formatter'
import s from './Table.module.sass'

const Table = props => {
  const navigate = useNavigate()

  const onClick = id => {
  }

  const onAdClick = id => {
    navigate(`/ads/${id}`)
  }

  const onAdvertiserClick = id => {
    navigate(`/advertisers/${id}`)
  }

  return (
    <table className={s.Table}>
      <thead>
        <tr>
          <th>Store</th>
          <th>Ads</th>
          <th>Ad Impressions</th>
          <th>Top Ads</th>
          <th>Top Advertisers</th>
        </tr>
      </thead>
      <tbody>
        {
          props.adStores.map(a => (
            <tr key={a.id}>
              <td>
                <div className="flex items-center cursor-pointer" onClick={() => onClick(a.id)}>
                  <div>
                    { a.logoUrl ? <img className="w-12 h-12 rounded-[5px]" src={a.logoUrl} /> : <div className="w-12 h-12 bg-gray/20 rounded-[5px]" /> }
                  </div>
                  <div className="ml-4 font-medium text-[16px]">{ a.host }</div>
                </div>
              </td>
              <td>{ nFormatter(a.adsCount) }</td>
              <td>{ nFormatter(a.impressions) }</td>
              <td>
                <div className="flex items-center">
                  {
                    a.topAds.map((newAd, index) => (
                      <Link to={`/ads/${newAd.id}`} key={index} className="relative mr-1 cursor-pointer">
                        <img className="rounded-lg w-[45px] h-[53px]" src={newAd.coverUrl} />
                        <div className="absolute text-white text-[10px] bottom-0 left-0 w-full flex items-center justify-center">{ nFormatter(newAd.impressions) }</div>
                      </Link>
                    ))
                  }
                </div>
              </td>
              <td>
                <div className="flex items-center">
                  {
                    a.topAdvertisers.map((advertiser, index) => (
                      <div key={index} className="relative mr-1 cursor-pointer" onClick={() => onAdvertiserClick(advertiser.id)}>
                        <img className="rounded-lg w-[45px] h-[53px]" src={advertiser.logoUrl} />
                      </div>
                    ))
                  }
                </div>
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  )
}

export default Table
