import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import api from '~/api'
import TrendingFavoriteIcon from '~/components/icons/TrendingFavorite'

dayjs.extend(relativeTime)

const AdvertiserContent = props => {
  const [advertisers, setAdvertisers] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    api.apiAdvertisers.list({ query: { swipe_id: props.swipeId } }).then(data => setAdvertisers(data.advertisers))
  }, [props.swipeId])

  const onClick = id => {
    navigate(`/advertisers/${id}`)
  }

  const removeFromSwipe = (e, id) => {
    e.preventDefault()
    e.stopPropagation()

    api.apiSwipes.deleteProducts({ data: {
      id: props.swipeId,
      product_type: 'Advertiser',
      product_ids: [id]
    } }).then(data => {
      setAdvertisers(prev => prev.filter(adv => adv.id !== id))
      props.update(data.swipe)
    })
  }

  return (
    <div className="p-5">
      <div className="w-full grid gap-6 grid-cols-4 -mt-1 lg:gap-6 lg:grid-cols-4 md:gap-4 md:grid-cols-3 sm:gap-2 sm:grid-cols-2">
        {
          advertisers.map(advertiser => (
            <div key={advertiser.id} className="flex" onClick={() => onClick(advertiser.id)}>
              <div className="">
                <div className="w-10 h-10 bg-gray rounded-full">
                  <img className="rounded-full" src={advertiser.logoUrl} />
                </div>
              </div>
              <div className="flex flex-col ml-3">
                <div className="flex items-center">
                  <div className="text-lg font-medium leading-5">{ advertiser.name }</div>
                </div>
              </div>
              <div className="flex-0 text-gray ml-2" onClick={e => removeFromSwipe(e, advertiser.id)}>
                <TrendingFavoriteIcon $active={!!advertiser?.swipesIds?.length} />
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default AdvertiserContent
