const Facebook = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" height="21" fill="#1977F2" width="98" version="1.1" viewBox="0 0 74.9 12.9">
    <g>
      <defs>
        <rect y="0" width="66.9" height="12.9"/>
      </defs>
      <path d="M5.2,2c-0.8,0-1,0.4-1,1.1v1.3h2.1L6.1,6.5H4.2v6.3H1.7V6.5H0V4.4h1.7V3.2C1.7,1.1,2.5,0,4.9,0   C5.4,0,6,0,6.3,0.1V2"/>
      <path d="M6.5,8.3c0-2.3,1.1-4.1,3.4-4.1c1.3,0,2,0.6,2.4,1.5V4.4h2.4v8.3h-2.4v-1.3c-0.4,0.8-1.1,1.4-2.4,1.4   c-2.3,0-3.4-1.7-3.4-4.1 M9,8.9c0,1.2,0.5,2.1,1.6,2.1c1,0,1.6-0.8,1.6-1.9V8.2c0-1.2-0.5-1.9-1.6-1.9C9.5,6.2,9,7.1,9,8.3V8.9z"/>
      <path d="M20,4.3c1,0,1.9,0.2,2.4,0.6l-0.6,1.8c-0.4-0.2-1-0.4-1.7-0.4c-1.4,0-2,0.8-2,2.1v0.5c0,1.3,0.6,2.1,2,2.1   c0.7,0,1.3-0.2,1.7-0.4l0.6,1.8c-0.5,0.4-1.4,0.6-2.4,0.6c-3,0-4.3-1.6-4.3-4.1V8.4C15.7,5.8,17,4.3,20,4.3"/>
      <path d="M22.7,8.9V8.2c0-2.4,1.3-3.9,4.1-3.9c2.6,0,3.7,1.6,3.7,3.9v1.3h-5.3c0.1,1.1,0.6,1.6,2,1.6   c0.9,0,1.9-0.2,2.7-0.5l0.5,1.7c-0.7,0.3-2,0.6-3.2,0.6C23.9,12.9,22.7,11.4,22.7,8.9 M25.2,7.8h3V7.6c0-0.9-0.4-1.6-1.5-1.6   C25.7,6,25.2,6.7,25.2,7.8"/>
      <path d="M39.9,8.9c0,2.3-1.1,4.1-3.4,4.1c-1.3,0-2.1-0.6-2.5-1.4v1.3h-2.4V0.2L34.1,0v5.6c0.4-0.7,1.2-1.3,2.3-1.3   c2.3,0,3.4,1.7,3.4,4.1 M37.4,8.3c0-1.2-0.5-2-1.7-2c-1,0-1.6,0.7-1.6,1.9V9c0,1.2,0.6,1.9,1.6,1.9c1.2,0,1.7-0.9,1.7-2V8.3z"/>
      <path d="M40.7,8.8V8.4c0-2.4,1.4-4.1,4.1-4.1C47.6,4.3,49,6,49,8.4v0.4c0,2.4-1.4,4.1-4.1,4.1   C42.1,12.9,40.7,11.2,40.7,8.8 M46.5,8.2c0-1.1-0.5-2-1.6-2c-1.2,0-1.6,0.9-1.6,2V9c0,1.1,0.5,2,1.6,2c1.2,0,1.6-0.9,1.6-2V8.2z"/>
      <path d="M49.8,8.8V8.4c0-2.4,1.4-4.1,4.1-4.1c2.8,0,4.1,1.7,4.1,4.1v0.4c0,2.4-1.4,4.1-4.1,4.1   C51.2,12.9,49.8,11.2,49.8,8.8 M55.6,8.2c0-1.1-0.5-2-1.6-2c-1.2,0-1.6,0.9-1.6,2V9c0,1.1,0.5,2,1.6,2c1.2,0,1.6-0.9,1.6-2V8.2z"/>
      <polyline points="61.6,8.4 64.1,4.4 66.8,4.4 64.1,8.5 66.9,12.8 64.2,12.8 61.6,8.7 61.6,12.8 59.1,12.8 59.1,0.2    61.6,0  "/>
    </g>
  </svg>
)

export default Facebook;