import { useState, useEffect } from 'react'
import { useDebounce } from 'use-debounce'

const TextField = props => {
  const [val, setVal] = useState(props.value || '')
  const [debouncedValue] = useDebounce(val, 500)

  useEffect(() => {
    if (debouncedValue !== props.value) {
      props.onChange(debouncedValue, props.field)
    }
  }, [debouncedValue])

  const onChange = e => {
    setVal(e.target.value)
  }

  return (
    <div className="w-full">
      <input
        className="w-full outline-none"
        value={val}
        onChange={onChange}
        placeholder={props.placeholder}
      />
    </div>
  )
}

export default TextField
