import NavItem from './NavItem'

const ProductsIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path d="M9.05671 8.64762L9.05611 8.64797C9.02899 8.66372 8.98301 8.6689 8.93833 8.64475L3.16938 5.30325C3.16927 5.30319 3.16916 5.30312 3.16904 5.30305C3.13649 5.28398 3.11485 5.25201 3.10826 5.21521C3.10195 5.17996 3.11159 5.15784 3.12468 5.14319L3.12469 5.14321L3.12826 5.13915C3.30148 4.94204 3.49026 4.79053 3.67761 4.68883L3.67762 4.68885L3.68228 4.68627L7.74728 2.43627L7.74728 2.43628L7.75024 2.43462C8.06477 2.25838 8.51846 2.15195 9.0075 2.15195C9.49655 2.15195 9.95023 2.25838 10.2648 2.43462L10.2648 2.43463L10.2677 2.43627L14.3327 4.68627L14.3327 4.68629L14.3374 4.68883C14.5157 4.78564 14.7035 4.93911 14.883 5.13518C14.8925 5.1485 14.9016 5.17239 14.8946 5.20981C14.8868 5.25112 14.8632 5.28415 14.831 5.30304C14.8309 5.30311 14.8307 5.30318 14.8306 5.30325L9.05671 8.64762Z" fill="currentColor" stroke="currentColor" strokeWidth="1.3" />
    <path d="M3.65569 13.6758L3.65331 13.6745C3.32404 13.4869 2.98473 13.1303 2.72536 12.6793C2.46536 12.2271 2.3225 11.7438 2.3225 11.3477V7.47765C2.3225 7.42135 2.34811 7.38588 2.38122 7.36607C2.39844 7.35577 2.41528 7.35155 2.42928 7.3512C2.44112 7.3509 2.45774 7.35299 2.48077 7.36602L7.85607 10.4835C7.89813 10.5133 7.9225 10.563 7.9225 10.6052V15.7202C7.9225 15.7752 7.89804 15.8097 7.86801 15.829C7.85231 15.8391 7.83644 15.844 7.82239 15.845C7.81001 15.8459 7.79271 15.8445 7.76879 15.8329L7.485 16.4177L7.77115 15.834C7.00909 15.4604 5.98039 14.921 5.13726 14.4723C4.71676 14.2485 4.34432 14.0483 4.07703 13.904C3.9434 13.8319 3.8361 13.7737 3.76228 13.7337L3.67755 13.6877L3.65569 13.6758Z" fill="currentColor" stroke="currentColor" strokeWidth="1.3" />
    <path d="M10.0775 15.7202V10.6052C10.0775 10.563 10.1019 10.5133 10.1439 10.4835L15.5192 7.36601C15.5423 7.35299 15.5589 7.3509 15.5707 7.3512C15.5847 7.35155 15.6016 7.35577 15.6188 7.36607C15.6519 7.38588 15.6775 7.42135 15.6775 7.47765V11.3477C15.6775 11.7438 15.5346 12.2271 15.2746 12.6793C15.0153 13.1303 14.676 13.4869 14.3467 13.6745L14.3443 13.6758L14.3224 13.6877L14.2377 13.7337C14.1639 13.7737 14.0566 13.8319 13.923 13.904C13.6557 14.0483 13.2832 14.2485 12.8627 14.4723C12.0202 14.9207 10.9924 15.4596 10.2305 15.8332C10.2069 15.8445 10.1899 15.8459 10.1776 15.845C10.1636 15.844 10.1477 15.8391 10.132 15.829C10.102 15.8097 10.0775 15.7752 10.0775 15.7202Z" fill="currentColor" stroke="currentColor" strokeWidth="1.3" />
  </svg>
)

const ProductsMenu = () => (
  <NavItem to="/products">
    <div className="flex flex-col items-center"><ProductsIcon /></div>
    <span className="ml-2 leading-[15px]">Products</span>
  </NavItem>
)

export default ProductsMenu
