import React, { useState, useEffect } from 'react'
import Button from '~/components/layout/Button'
import ArrowDownIcon from '~/components/icons/ArrowDown'
import SearchIcon from '~/components/icons/Search'

const defaultSearchTypeOptions = [
  { id: 'text', label: 'Ad Text' },
  { id: 'keyword', label: 'Ad Keyword' },
  { id: 'advertiser', label: 'Advertiser' },
  { id: 'link', label: 'Link' }
]

const Search = props => {
  const searchTypeOptions = props.searchTypeOptions || defaultSearchTypeOptions
  const [searchTypeOpened, setSearchTypeOpened] = useState(false)
  const [searchValue, setSearchValue] = useState(props.searchValue || '')
  const [activeSearchType, setActiveSearchType] = useState(props.searchType || searchTypeOptions?.[0]?.id || 'text')

  useEffect(() => {
    setSearchValue(props.searchValue || '')
  }, [props.searchValue])

  const onSearchTypeSelect = (e, val) => {
    e.stopPropagation()
    setActiveSearchType(val)
    setSearchTypeOpened(false)
  }

  const onSearchValueChange = e => {
    setSearchValue(e.target.value)
  }

  const onSearch = () => {
    const trimValue = searchValue?.trim()

    props.onSearch(trimValue?.length ? { type: activeSearchType, value: trimValue } : {})
  }

  const onSearchKeyDown = e => {
    if (e.key === 'Enter') {
      onSearch()
    }
  }

  return (
    <div className="w-auto flex-1 border-b-1">
      <div className="flex justify-center">
        <div className="input-group relative flex items-center w-full rounded-lg bg-white h-12 px-1.5 shadow-[0_4px_50px_rgba(0,0,0,0.05)]">
          <div className="flex items-center h-full">
            <div className="relative flex justify-between items-center w-[133px] h-full px-4 cursor-pointer border-r border-[#F1F2F4] select-none" onClick={() => setSearchTypeOpened(prev => !prev)}>
              <div className="text-sm">
                { searchTypeOptions.find(o => o.id === activeSearchType)?.label || searchTypeOptions[0].label }
              </div>
              <div className="ml-1">
                <ArrowDownIcon />
              </div>
              {searchTypeOpened &&
                <div className="flex flex-col absolute top-9 bg-white px-4 w-[133px] -left-1.5 rounded-b-lg text-sm px-4 py-2 z-10">
                  {
                    searchTypeOptions.map(el => (
                      <div key={el.id} className={`px-1.5 py-1 ${activeSearchType === el.id ? 'text-blue1' : ''}`} onClick={e => onSearchTypeSelect(e, el.id)}>{ el.label }</div>
                    ))
                  }
                </div>
              }
            </div>
          </div>
          <SearchIcon className="ml-3" />
          <input type="search" value={searchValue} onKeyDown={onSearchKeyDown} onChange={onSearchValueChange} className="form-control h-[30px] relative flex-auto min-w-0 block w-full px-2 text-base font-normal text-gray-700 bg-white bg-clip-padding transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" placeholder="Search" aria-label="Search" aria-describedby="button-addon3" />
          <div className="flex items-center h-full">
            <Button onClick={onSearch}>Search</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.memo(Search)
