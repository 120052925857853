import { ReactComponent as PanelIcon } from '~/components/Home/PanelIcon.svg'
import OurPicks from './OurPicks'
import TopTrendingProducts from './TopTrendingProducts'
import TopSeasonalDropshipProducts from './TopSeasonalDropshipProducts'
import TopAdvertisers from './TopAdvertisers'
import TopAds from './TopAds'
import TopAdProducts from './TopAdProducts'
import TopStores from './TopStores'
import s from './Home.module.sass'

const Home = () => (
  <div className={`${s.Home} pt-0`}>
    <div className={`${s.panel} !hidden`}>
      <div className="flex flex-col text-white">
        <div className="flex flex-col flex-1 text-white justify-between">
          <div className="">
            <div className="font-medium text-4xl leading-[50px]">Welcome to Research Tool!</div>
            <div className="mt-1.5 font-normal text-sm max-w-[392px] opacity-80">Not sure how to get started? We prepare for you video tutorial where you can find out how to use Research Tool</div>
          </div>
          <div className="w-[143px] py-3 px-4 flex flex-row items-center bg-white rounded text-[#5A94F1] cursor-pointer shadow-[0_4px_4px_rgba(98,146,223,0.15)]">
            <div className="">
              <svg width="11" height="13" viewBox="0 0 11 13" fill="none">
                <path d="M9.73501 5.66533C10.334 6.06057 10.334 6.93943 9.73501 7.33468L2.05074 12.405C1.38589 12.8437 0.5 12.3668 0.5 11.5703L0.5 1.4297C0.5 0.633149 1.38589 0.15633 2.05074 0.595022L9.73501 5.66533Z" fill="currentColor" />
              </svg>
            </div>
            <div className="ml-1 font-medium text-sm leading-[15px]">Watch tutorial</div>
          </div>
        </div>
        <div className="opacity-50 pointer-events-none">
          <div className={s.panelLayout1}></div>
          <div className={s.panelLayout2}></div>
          <div className={s.panelLayout3}></div>
        </div>
      </div>
      <div className="absolute top-0 right-12 bottom-0"><PanelIcon /></div>
    </div>
    <TopSeasonalDropshipProducts />
    <OurPicks />
    <TopTrendingProducts />
    <TopAdvertisers />
    <TopAds />
    <TopAdProducts />
    <TopStores />
  </div>
)

export default Home
