import NavItem from './NavItem'

const BriefsIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path d="M12.1425 1.5H5.8575C3.1275 1.5 1.5 3.1275 1.5 5.8575V12.1425C1.5 14.8725 3.1275 16.5 5.8575 16.5H12.1425C14.8725 16.5 16.5 14.8725 16.5 12.1425V5.8575C16.5 3.1275 14.8725 1.5 12.1425 1.5ZM7.4775 11.175L5.79 12.8625C5.6775 12.975 5.535 13.0275 5.3925 13.0275C5.25 13.0275 5.1 12.975 4.995 12.8625L4.4325 12.3C4.2075 12.0825 4.2075 11.7225 4.4325 11.505C4.65 11.2875 5.0025 11.2875 5.2275 11.505L5.3925 11.67L6.6825 10.38C6.9 10.1625 7.2525 10.1625 7.4775 10.38C7.695 10.5975 7.695 10.9575 7.4775 11.175ZM7.4775 5.925L5.79 7.6125C5.6775 7.725 5.535 7.7775 5.3925 7.7775C5.25 7.7775 5.1 7.725 4.995 7.6125L4.4325 7.05C4.2075 6.8325 4.2075 6.4725 4.4325 6.255C4.65 6.0375 5.0025 6.0375 5.2275 6.255L5.3925 6.42L6.6825 5.13C6.9 4.9125 7.2525 4.9125 7.4775 5.13C7.695 5.3475 7.695 5.7075 7.4775 5.925ZM13.17 12.465H9.2325C8.925 12.465 8.67 12.21 8.67 11.9025C8.67 11.595 8.925 11.34 9.2325 11.34H13.17C13.485 11.34 13.7325 11.595 13.7325 11.9025C13.7325 12.21 13.485 12.465 13.17 12.465ZM13.17 7.215H9.2325C8.925 7.215 8.67 6.96 8.67 6.6525C8.67 6.345 8.925 6.09 9.2325 6.09H13.17C13.485 6.09 13.7325 6.345 13.7325 6.6525C13.7325 6.96 13.485 7.215 13.17 7.215Z" fill="currentColor" />
  </svg>
)

const BriefsMenu = () => (
  <NavItem to="/briefs">
    <div className="flex flex-col items-center"><BriefsIcon /></div>
    <span className="ml-2 leading-[15px]">Briefs</span>
  </NavItem>
)

export default BriefsMenu
