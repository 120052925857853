import React, { useEffect, useRef } from 'react'
import { easepick } from '@easepick/core'
import dayjs from 'dayjs'

const DatePicker = props => {
  const nodeStartRef = useRef()

  useEffect(() => {
    const picker = new easepick.create({
      element: nodeStartRef.current,
      css: ['https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css'],
      zIndex: 10,
      grid: 1,
      calendars: 1,
      plugins: []
    })

    picker.on('select', e => {
      props.onChange({ target: { value: dayjs(e.detail.date).format('YYYY-MM-DD') } })
      // setMin(dayjs(e.detail.start).format('YYYY-MM-DD'))
      // setMax(dayjs(e.detail.end).format('YYYY-MM-DD'))
    })

    setTimeout(() => {
      picker.show()
    }, 150)

    return () => {
      picker.destroy()
    }
  }, [])

  return (
    <div className="flex items-center rounded h-[36px] px-[16px]">
      <div className="" ref={nodeStartRef}>{ props.value }</div>
    </div>
  )
}

export default React.memo(DatePicker)
