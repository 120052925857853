import React, { useState, useEffect } from 'react'
import api from '~/api'
import Modal from '~/components/layout/Modal'
import Select from '~/components/layout/Select'
import Input from '~/components/layout/Input'
import Button from '~/components/layout/Button'
import TrashIcon from '~/components/icons/Trash'

const sortOptions = [
  { label: 'Date Added', value: 'created_at' },
  { label: 'First Seen', value: 'first_seen' },
  { label: 'Last Seen', value: 'last_seen' },
  { label: 'Impressions', value: 'impressions' },
  { label: 'Popularity', value: 'popularity' },
  { label: 'Days', value: 'days' }
]

const filtersMap = {
  countryCode: 'Country',
  ctaButton: 'Cta Button',
  platform: 'Platform',
  landingPage: 'Landing Page',
  objective: 'Category',
  impressions: 'Impressions',
  popularity: 'Popularity',
  seenDiff: 'Days',
  likeRate: 'Like Rate',
  firstSeen: 'First Seen',
  lastSeen: 'Last Seen'
}

const AdvancedSearch = props => {
  const [isEditSearchesModalOpen, setEditSearchesModalOpen] = useState(false)
  const [isSaveSearchModalOpen, setSaveSearchModalOpen] = useState(false)
  const [newSearchValue, setNewSearchValue] = useState('')
  const [savedSearches, setSavedSearches] = useState([])
  const [savedSearchId, setSavedSearchId] = useState()

  useEffect(() => {
    api.apiAdsFilters.list().then(data => {
      setSavedSearches(data.adsFilters)
    })
  }, [])

  const onSortChange = e => {
    props.setCurrentSortBy(e.target.value)
  }

  const onSaveSearchClose = () => {
    setSaveSearchModalOpen(false)
  }

  const onNewSearchValueChange = e => {
    setNewSearchValue(e.target.value)
  }

  const onSearchSave = () => {
    props.onSearchSave?.(newSearchValue).then(data => {
      setNewSearchValue('')
      setSaveSearchModalOpen(false)
      setSavedSearches(prev => [...prev, data.adsFilter])
      setSavedSearchId(data.adsFilter.id)
    })
  }

  const onSavedSearchDelete = id => {
    props.onSavedSearchDelete?.(id).then(data => {
      setSavedSearches(prev => prev.filter(ps => ps.id !== data.adsFilter.id))
    })
  }

  const onSavedSearchChange = e => {
    setSavedSearchId(e.target.value)

    const savedSearch = savedSearches.find(s => s.id === Number(e.target.value))

    if (savedSearch) {
      props.onSavedSearchChange?.(savedSearch)
    }
  }

  const savedSearchesOptions = [{ value: '', label: 'Select Search' }, ...savedSearches.map(s => ({ value: s.id, label: s.name }))]

  const renderSearchFilter = (name, value) => {
    switch (name) {
      case 'impressions':
      case 'popularity':
      case 'seenDiff':
      case 'likeRate':
      case 'firstSeen':
      case 'lastSeen':
        return <>{ value.min || 0 } - { value.max || '\u221E' }</>
      default: {
        return <>{ value.join(', ') }</>
      }
    }
  }

  const renderSearch = search => (
    <div className="flex min-w-max">
      {search.search &&
        <div className="flex-0 my-4 mr-4">
          <div className="flex py-1 px-4 rounded border border-blue mt-1">{ search.search?.type }: { search.search?.value }</div>
        </div>
      }
      <div className="flex-0 flex flex-col items-start my-4">
        {
          Object.keys(search.filters).map((fk, i) => (
            <div className="flex py-1 px-2 rounded border border-blue mt-1" key={i}><span className="ml-1">{ filtersMap[fk] }: { renderSearchFilter(fk, search.filters[fk]) }</span></div>
          ))
        }
      </div>
      {search.sortBy?.length &&
        <div className="flex-0 ml-4 my-4">
          <div className="flex py-1 px-4 rounded border border-blue mt-1">Sort By { search.sortBy }</div>
        </div>
      }
    </div>
  )

  return (
    <div className="w-full mr-4 mt-4 flex justify-between">
      <div className="flex items-center">
        <div className="text-sm text-blue1 underline cursor-pointer" onClick={props.showFilters}>Advanced filters</div>
        {typeof props.setCurrentSortBy === 'function' &&
          <div className="text-sm ml-4 cursor-pointer">
            <Select options={props.sortOptions || sortOptions} value={props.currentSortBy} onChange={onSortChange} />
          </div>
        }
      </div>
      {typeof props.onSearchSave === 'function' &&
        <>
          <div className="flex items-center">
            <div className="text-sm text-blue1 underline ml-4 cursor-pointer" onClick={() => setSaveSearchModalOpen(true)}>Save Current Search</div>
            <div className="text-sm ml-4 cursor-pointer">
              <Select options={savedSearchesOptions} value={savedSearchId} onChange={onSavedSearchChange} />
            </div>
            <div className="text-sm text-blue1 underline ml-4 cursor-pointer" onClick={() => setEditSearchesModalOpen(true)}>Edit</div>
          </div>
          <Modal isOpen={isSaveSearchModalOpen} onClose={onSaveSearchClose} headerText="Save Current Search">
            <div>
              <Input value={newSearchValue} onChange={onNewSearchValueChange} placeholder="Search Name" />
            </div>
            <div className="mt-4 flex justify-end">
              <Button onClick={onSearchSave}>Save</Button>
            </div>
          </Modal>
          <Modal isOpen={isEditSearchesModalOpen} onClose={() => setEditSearchesModalOpen(false)} headerText="Edit Searches" wide>
            <table className="table-auto w-full">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Contain</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {
                  savedSearches.map(search => (
                    <tr key={search.id} className="text-xs">
                      <td>{ search.name }</td>
                      <td>{ renderSearch(search) }</td>
                      <td><div className="flex items-center cursor-pointer" onClick={() => onSavedSearchDelete(search.id)}><TrashIcon />&nbsp;Delete</div></td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </Modal>
        </>
      }
    </div>
  )
}

export default React.memo(AdvancedSearch)
