import NavItem from './NavItem'

const AIICON = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 512 512" >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="currentColor" transform="translate(64 64)">
        <path d="M320 64v256H64V64h256zm-148.25 64h-24.932L99.484 256h22.493l8.936-25.023h56.662L196.32 256h23.847L171.75 128zm88.344 0h-22.402v128h22.402V128zm-101 21.475l22.315 63.858h-44.274l21.96-63.858zM341.335 256H384v42.667h-42.667V256zm-256 85.333H128V384H85.333v-42.667zm85.333 0h42.666V384h-42.666v-42.667zM85.333 0H128v42.667H85.333V0zM256 341.333h42.667V384H256v-42.667zM170.667 0h42.666v42.667h-42.666V0zM256 0h42.667v42.667H256V0zm85.333 170.667H384v42.666h-42.667v-42.666zM0 256h42.667v42.667H0V256zM341.333 85.333H384V128h-42.667V85.333zM0 170.667h42.667v42.666H0v-42.666zm0-85.334h42.667V128H0V85.333z"></path>
      </g>
    </g>
  </svg>
);

const AICopyMenu = () => (
  <NavItem to="/ai">
    <div className="flex flex-col items-center"><AIICON /></div>
    <span className="ml-2 leading-[15px]">AI Copy</span>
  </NavItem>
)

export default AICopyMenu
