import { useState } from 'react'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import dayjs from 'dayjs'
import Toggle from '~/components/Toggle'
import Select from '~/components/layout/Select'

const timeNow = dayjs()

const CustomDot = ({ cx, cy }) => (
  <svg x={cx - 3} y={cy - 3} width="6" height="6" viewBox="0 0 6 6" fill="none">
    <circle cx="3" cy="3" r="2.75" fill="white" stroke="#4886EA" strokeWidth="0.5" />
    <circle cx="3" cy="3" r="2" fill="#4886EA" />
  </svg>
)

const History = props => {
  const [dataKey, setDataKey] = useState('orders')
  const timeFormat = props.interval == "month" ? 'MMM' : 'DD MMM'
  const data = props.data.map(r => ({
    time: dayjs(r.parseAt).format(timeFormat),
    orders: r.ordersCount,
    price: r.priceCents / 100
  }))

  return (
    <div className="flex flex-col w-full">
      <div className="flex w-full justify-between items-center">
        <Toggle options={[{ value: 'orders', label: 'Sales History' }, { value: 'price', label: 'Price History' }]} value={dataKey} onChange={setDataKey} />
      </div>
      {!!data.length &&
        <div className="flex-auto mt-6">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              width={500}
              height={150}
              data={data}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <CartesianGrid strokeDasharray="6 6" vertical={false} stroke="#F3F5F9" />
              <XAxis dataKey="time" axisLine={false} tickLine={false} tick={{ fontSize: 10 }} />
              <YAxis axisLine={false} tickLine={false} domain={[0, dataMax => (dataMax * 1.2)]} tick={{ fontSize: 10 }} width={40} tickFormatter={val => parseInt(val)} />
              <Tooltip />
              <Area type="basic" dataKey={dataKey} stroke="#4886EA" strokeWidth={2} fill="#f7f7f9" dot={<CustomDot />} isAnimationActive={false} />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      }
    </div>
  )
}

export default History
