import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import Drawer from '~/components/layout/Drawer'
import TrendingProductContainer from '~/components/Trending/TrendingProduct'

const TrendingProduct = ({ id, filters, addToSwipe }) => {
  const [, setSearchParams] = useSearchParams()
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(true)
  }, [])

  const toggleOpen = () => {
    setIsOpen(false)
    setTimeout(() => {
      setSearchParams(filters)
    }, 250)
  }

  return (
    <Drawer isOpen={isOpen} setIsOpen={toggleOpen}>
      { id && <TrendingProductContainer id={id} addToSwipe={addToSwipe} /> }
    </Drawer>
  )
}

export default TrendingProduct
