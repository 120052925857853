import { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import api from '~/api'
import ReactPaginate from 'react-paginate'
import { PostCard } from '../Research/TikTokPostSearch'
dayjs.extend(relativeTime)

const TikTokPostContent = props => {
  const [posts, setPosts] = useState({ list: [], meta: {} })
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    api.apiTikTokPosts.list({ query: { swipe_id: props.swipeId, page: currentPage} }).then(data => setPosts({list: data.posts, meta: data.meta}))
  }, [props.swipeId, currentPage])

  const onPageChange = ({ selected }) => {
    setCurrentPage(selected + 1)
  }

  const removeFromSwipe = id => {
    api.apiSwipes.deleteProducts({ data: {
      id: props.swipeId,
      product_type: 'TikTokPost',
      product_ids: [id]
    } }).then(data => {
      setPosts({...posts, list: posts.list.filter(a => a.id !== id)})
      props.update(data.swipe)
    })
  }

  return (
    <div className="">
      <div className="relative w-full overflow-x-auto mt-7">
        <div className="w-full grid -mt-1 lg:gap-6 xl:grid-cols-4 md:gap-4 lg:grid-cols-3 gap-2 grid-cols-2">
          {
            posts.list.map(post => {
              return (
                <PostCard post={post} key={post.awemeId} onClick={() => null} onAddToSwipe={removeFromSwipe}/>
              )
            })
          }
        </div>
      </div>
      {posts.meta.pages > 1 &&
        <ReactPaginate
          onPageChange={onPageChange}
          pageRangeDisplayed={5}
          pageCount={posts.meta.pages}
          renderOnZeroPageCount={null}
          className="pagination justify-end mt-4"
          previousLabel="Previous"
          nextLabel="Next"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          forcePage={posts.meta.page - 1}
        />
      }
    </div>
  )
}

export default TikTokPostContent
