const values = [
  { value: 'video', label: 'Video' },
  { value: 'image', label: 'Still Image' },
  { value: 'carousel', label: 'Carousel' },
  { value: 'gif', label: 'GIF' }
]

const Format = props => {
  const isSelected = val => (
    props.value === val
  )

  return (
    <div className="p-4 flex items-center">
      {
        values.map(v => (
          <div
            key={v.value}
            style={{ background: isSelected(v.value) ? 'rgba(86, 147, 245, 0.3)' : 'transparent' }}
            className="mr-4 py-2 px-4 rounded-full border border-gray/20 text-sm cursor-pointer"
            onClick={() => props.onChange(v.value)}
          >
            { v.label }
          </div>
        ))
      }
    </div>
  )
}

export default Format
