const BarCode = () => (
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.1665 7.99935V5.91602C2.1665 3.84102 3.8415 2.16602 5.9165 2.16602H7.99984" stroke="#5693F5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13 2.16602H15.0833C17.1583 2.16602 18.8333 3.84102 18.8333 5.91602V7.99935" stroke="#5693F5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M18.8335 13.834V15.084C18.8335 17.159 17.1585 18.834 15.0835 18.834H13.8335" stroke="#5693F5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.99984 18.8333H5.9165C3.8415 18.8333 2.1665 17.1583 2.1665 15.0833V13" stroke="#5693F5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.25016 6.33398V8.00065C9.25016 8.83398 8.8335 9.25065 8.00016 9.25065H6.3335C5.50016 9.25065 5.0835 8.83398 5.0835 8.00065V6.33398C5.0835 5.50065 5.50016 5.08398 6.3335 5.08398H8.00016C8.8335 5.08398 9.25016 5.50065 9.25016 6.33398Z" stroke="#5693F5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.9167 6.33398V8.00065C15.9167 8.83398 15.5 9.25065 14.6667 9.25065H13C12.1667 9.25065 11.75 8.83398 11.75 8.00065V6.33398C11.75 5.50065 12.1667 5.08398 13 5.08398H14.6667C15.5 5.08398 15.9167 5.50065 15.9167 6.33398Z" stroke="#5693F5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.25016 13V14.6667C9.25016 15.5 8.8335 15.9167 8.00016 15.9167H6.3335C5.50016 15.9167 5.0835 15.5 5.0835 14.6667V13C5.0835 12.1667 5.50016 11.75 6.3335 11.75H8.00016C8.8335 11.75 9.25016 12.1667 9.25016 13Z" stroke="#5693F5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.9167 13V14.6667C15.9167 15.5 15.5 15.9167 14.6667 15.9167H13C12.1667 15.9167 11.75 15.5 11.75 14.6667V13C11.75 12.1667 12.1667 11.75 13 11.75H14.6667C15.5 11.75 15.9167 12.1667 15.9167 13Z" stroke="#5693F5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default BarCode
