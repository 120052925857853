const ShareArrow = () => (
  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.5 6.66602C16.8807 6.66602 18 5.54673 18 4.16602C18 2.7853 16.8807 1.66602 15.5 1.66602C14.1193 1.66602 13 2.7853 13 4.16602C13 5.54673 14.1193 6.66602 15.5 6.66602Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M5.5 12.5C6.88071 12.5 8 11.3807 8 10C8 8.61929 6.88071 7.5 5.5 7.5C4.11929 7.5 3 8.61929 3 10C3 11.3807 4.11929 12.5 5.5 12.5Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.5 18.334C16.8807 18.334 18 17.2147 18 15.834C18 14.4533 16.8807 13.334 15.5 13.334C14.1193 13.334 13 14.4533 13 15.834C13 17.2147 14.1193 18.334 15.5 18.334Z" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.6582 11.2578L13.3499 14.5745" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.3415 5.42578L7.6582 8.74245" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

export default ShareArrow
