import NavItem from './NavItem'

const HelpIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.75 13.8223H9.75L6.41249 16.0423C5.91749 16.3723 5.25 16.0198 5.25 15.4198V13.8223C3 13.8223 1.5 12.3223 1.5 10.0723V5.57227C1.5 3.32227 3 1.82227 5.25 1.82227H12.75C15 1.82227 16.5 3.32227 16.5 5.57227V10.0723C16.5 12.3223 15 13.8223 12.75 13.8223Z" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.00005 8.51953V8.36206C9.00005 7.85206 9.31507 7.58205 9.63007 7.36455C9.93757 7.15455 10.245 6.88456 10.245 6.38956C10.245 5.69956 9.69005 5.14453 9.00005 5.14453C8.31005 5.14453 7.75507 5.69956 7.75507 6.38956" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8.99662 10.3125H9.00337" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

const HelpMenu = () => (
  <a href="mailto:support@signalboost.co" className="block bg-[#111112] rounded-[10px] pl-[2px]">
    <div className="px-3 py-[10px] flex items-center rounded-lg text-white/40 hover:text-white">
      <div className="flex flex-col items-center"><HelpIcon /></div>
      <span className="ml-2 leading-[15px]">Help</span>
    </div>
  </a>
)

export default HelpMenu
