import ReactDOM from 'react-dom/client'
import axios from 'axios'
import Qs from 'qs'
import App from '~/components/App'
import '~/stylesheets/app.sass'

window.addEventListener('DOMContentLoaded', () => {
  const csrfToken = document.querySelector('meta[name=csrf-token]').content
  axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
  axios.defaults.headers.common.Accept = 'application/json'

  // Format nested params correctly
  axios.interceptors.request.use(config => (
    {
      ...config,
      paramsSerializer: params => (
        Qs.stringify(params, { arrayFormat: 'brackets', encode: false })
      )
    }
  ))

  const container = document.getElementById('app')

  if (container) {
    const root = ReactDOM.createRoot(container)
    // root.render(
    //   <React.StrictMode>
    //     <App />
    //   </React.StrictMode>
    // )
    root.render(
      <App />
    )
  }
})
