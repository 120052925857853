import { useEffect, useState } from 'react'
import { useSearchParams, useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import { DateRangePicker } from 'react-date-range'
import ReactPaginate from 'react-paginate'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import api from '~/api'
import NotificationManager from '~/services/NotificationManager'
import TrendingProduct from '~/pages/TrendingProduct'
import Button from '~/components/layout/Button'
import TrendingBanner from '~/images/TrendingBanner.png'
import FilterIcon from '~/components/icons/Filter'
import AddToListing from '~/components/Research/AddToListing'
import Card from './Card'

dayjs.extend(relativeTime)
dayjs.extend(LocalizedFormat)

const Trending = props => {
  const [searchParams] = useSearchParams()
  const params = useParams()
  const productId = searchParams.get('productId')
  const activeTab = params.type
  const [filters, setFilters] = useState(props.filters)
  const [showFilters, setShowFilters] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [product, setProduct] = useState(null)

  useEffect(() => {
    if(productId && props.products && props.products.length > 0) {
      const productData = props.products.find(p => p.productId === productId)
      if (productData) {
        setProduct(productData)
        return;
      }
      api.apiTrending.get({ id: productId }).then(res => {
        setProduct(res.trendingProduct)
      })
    } else {
      setProduct(null)
    }
  }, [productId, props.products])

  const handleSelect = ranges => {
    const { startDate, endDate } = ranges.selection
    setFilters(prev => ({
      ...prev,
      created_at_from: dayjs(startDate).valueOf(),
      created_at_to: dayjs(endDate).valueOf()
    }))
  }

  const applyFilters = () => {
    setShowFilters(false)
    props.onFiltersChange(filters)
  }

  const clearFilters = () => {
    props.onFiltersChange({})
  }

  const selectionRange = {
    key: 'selection',
    startDate: dayjs(filters.created_at_from).toDate(),
    endDate: dayjs(filters.created_at_to).toDate()
  }

  const addToSwipe = id => {
    setSelectedProduct(id)
    setModalOpen(true)
  }

  const onModalClose = () => {
    setModalOpen(false)
    setSelectedProduct(null)
  }

  const onSubmitToSwipe = (swipeId, successFn) => {
    api.apiSwipes.addProducts({ data: {
      id: swipeId,
      product_type: 'TrendingProduct',
      product_ids: [selectedProduct]
    } }).then(() => {
      if (typeof successFn === 'function') successFn()
      onModalClose()
      NotificationManager.success({ boldText: 'Trending Product', text: 'has been successfully added to swipe' })
      props.appendSwipeId(selectedProduct, swipeId)
    })
  }

  return (
    <div className="flex flex-col w-full xl:py-9 lg:py-8 py-6 xl:px-[60px] lg:px-[40px] px-[20px]">
      <div className="flex w-full justify-between gap-3">
        <div className="flex text-8">
          Trending
        </div>
        <div className="flex flex-wrap gap-1">
          <div className={`flex px-4 py-2 rounded-md cursor-pointer ${activeTab === 'aliexpress' ? 'bg-blue1 text-white' : 'bg-white'}`} onClick={props.onTabClick.bind(this, 'aliexpress')}>Aliexpress</div>
          <div className={`flex px-4 py-2 rounded-md cursor-pointer ${activeTab === 'top_trending' ? 'bg-blue1 text-white' : 'bg-white'}`} onClick={props.onTabClick.bind(this, 'top_trending')}>Trending DS Products</div>
          <div className={`flex px-4 py-2 rounded-md cursor-pointer ${activeTab === 'tiktok' ? 'bg-blue1 text-white' : 'bg-white'}`} onClick={props.onTabClick.bind(this, 'tiktok')}>TikTok</div>
          <div className={`flex px-4 py-2 rounded-md cursor-pointer ${activeTab === 'amazon' ? 'bg-blue1 text-white' : 'bg-white'}`} onClick={props.onTabClick.bind(this, 'amazon')}>Amazon</div>
          <div className={`flex px-4 py-2 rounded-md cursor-pointer ${activeTab === 'etsy' ? 'bg-blue1 text-white' : 'bg-white'}`} onClick={props.onTabClick.bind(this, 'etsy')}>Etsy</div>
          <div className={`flex px-4 py-2 rounded-md cursor-pointer ${activeTab === 'ebay' ? 'bg-blue1 text-white' : 'bg-white'}`} onClick={props.onTabClick.bind(this, 'ebay')}>Ebay</div>
          <div className={`flex px-4 py-2 rounded-md cursor-pointer ${activeTab === 'shopify' ? 'bg-blue1 text-white' : 'bg-white'}`} onClick={props.onTabClick.bind(this, 'shopify')}>Shopify</div>
          <div className={`flex px-4 py-2 rounded-md cursor-pointer ${activeTab === 'walmart' ? 'bg-blue1 text-white' : 'bg-white'}`} onClick={props.onTabClick.bind(this, 'walmart')}>Walmart</div>
          <div className={`flex px-4 py-2 rounded-md cursor-pointer ${activeTab === 'woocommerce' ? 'bg-blue1 text-white' : 'bg-white'}`} onClick={props.onTabClick.bind(this, 'woocommerce')}>WooCommerce</div>
        </div>
      </div>
      <div className="mt-5 grid grid-cols-12 w-full rounded-lg bg-white shadow-[0_4px_50px_rgba(0,0,0,0.05)]">
        <div className="col-span-7 xl:py-11 lg:py-8 py-5 xl:px-12 lg:px-8 px-4">
          <div className="text-xl">Find Trending products before they go viral</div>
          <div className="w-3/4 mt-4 text-secondary text-sm">Find the hottest dropshipped products by some of the top platforms in the world.</div>
        </div>
        <div className="col-span-5">
          <img className="h-full w-full object-cover" src={TrendingBanner} />
        </div>
      </div>
      <div className="mt-6 flex flex-col items-end">
        <div className="flex items-center justify-end">
          {(props.filters.created_at_from || props.filters.created_at_to) &&
            <>
              <div className="mr-1 text-sm">
                Created Date: <span className="text-sm font-medium">{dayjs(props.filters.created_at_from).format('ll')}</span> - <span className="text-sm font-medium">{dayjs(props.filters.created_at_to).format('ll')}</span>
              </div>
              <div className="mr-4 text-xs cursor-pointer" onClick={clearFilters}>(clear)</div>
            </>
          }
          { activeTab === 'aliexpress' &&
            <Button secondary onClick={() => setShowFilters(prev => !prev)}>
              <FilterIcon />
              <span className="ml-1">Advanced Filters</span>
            </Button>
          }
        </div>
        {showFilters &&
          <div className="mt-4 p-4 flex flex-col rounded-lg bg-white">
            <DateRangePicker
              ranges={[selectionRange]}
              onChange={handleSelect}
            />
            <div className="flex justify-end">
              <Button onClick={applyFilters}>Apply</Button>
            </div>
          </div>
        }
      </div>
      <div className="w-full grid gap-6 grid-cols-4 -mt-1 lg:gap-6 lg:grid-cols-4 md:gap-4 md:grid-cols-3 sm:gap-2 sm:grid-cols-2">
        {
          props.products.map((p, index) => (
            <Card
              key={index}
              onClick={props.onProductClick}
              productId={p.productId}
              imageSrc={p.image}
              title={p.title}
              updatedAt={dayjs(p.updatedAt).fromNow()}
              aliexpress={p.marketplace === 'aliexpress'}
              currentOrdersCount={p.currentOrdersCount}
              ordersCount={p.ordersCount}
              isFavourite={!!p.swipesIds.length}
              addToSwipe={addToSwipe}
              growth={p.growth}
            />
          ))
        }
      </div>
      {props.pagination.pagesCount > 1 &&
        <ReactPaginate
          onPageChange={props.onPageChange}
          pageRangeDisplayed={5}
          pageCount={props.pagination.pagesCount}
          renderOnZeroPageCount={null}
          className="pagination justify-end mt-4"
          previousLabel="Previous"
          nextLabel="Next"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          forcePage={props.pagination.page - 1}
        />
      }
      { product && <TrendingProduct id={product.productId} filters={props.filters} addToSwipe={addToSwipe} /> }
      {isModalOpen &&
        <AddToListing selectedIds={[selectedProduct]} onSubmit={onSubmitToSwipe} onClose={onModalClose} />
      }
    </div>
  )
}

export default Trending
