import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import DOMPurify from 'dompurify';
import consumer from '~/channels/consumer'
import NotificationManager from '~/services/NotificationManager'
import Rating from '~/components/layout/Rating'
import Button from '~/components/layout/Button'
import Input from '~/components/layout/Input'
import Modal from '~/components/layout/Modal'
import api from '~/api'
import Gallery from './Gallery'
import ArrowLeft from '~/components/icons/ArrowLeft'
import FavoriteIcon from '~/components/icons/FavoriteIcon'
import ExternalLinkIcon from '~/components/icons/ExternalLinkIcon'
import LoadingIcon from '~/components/icons/Loading'
import Video from './Video'
import GrowthChart from './GrowthChart'
import History from './History'
import {nFormatter} from '~/helpers/formatter'

dayjs.extend(relativeTime)

const USDFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

const TrendingProduct = props => {
  const [product, setProduct] = useState()
  const [isNewVideo, setIsNewVideo] = useState(false)
  const [newVideo, setNewVideo] = useState({ captions: {}, images: [] })
  const [isVideoSubmitting, setIsVideoSubmitting] = useState(false)
  const [moreDescription, setMoreDescription] = useState(false)

  useEffect(() => {
    api.apiTrending.get({ id: props.id }).then(data => setProduct(data.trendingProduct))

    const subscription = consumer.subscriptions.create({ channel: 'TrendingChannel', product_id: props.id }, {
      received(data) {
        switch (data.event) {
          case 'ready':
            setProduct(prev => ({
              ...prev,
              videos: prev.videos.map(video => video.id === data.video_id ? { ...video, status: 'done', url: data.url } : video)
            }))
            break
          case 'failed':
            NotificationManager.error({ boldText: 'Error', text: 'We are sorry but something went wrong' })
            break
          default: break
        }
      },
      search(params = {}) {
        this.perform('search', params)
      }
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [props.id])

  const location = useLocation()
  const navigate = useNavigate()

  const onBack = () => {
    navigate(location.pathname)
  }

  const addToSwipe = e => {
    e.stopPropagation()

    props.addToSwipe(props.id)
  }

  const onNewVideo = () => {
    setIsNewVideo(true)
    setNewVideo({ captions: {}, images: [] })
  }

  const onCreateVideo = () => {
    setIsVideoSubmitting(true)
    api.apiTrendingVideos.create({
      data: { trending_id: props.id, trending_video: newVideo }
    }).then(data => {
      setIsVideoSubmitting(false)
      setProduct(prev => ({ ...prev, videos: [...prev.videos, data.trendingProductVideo] }))
      setIsNewVideo(false)
      setNewVideo({ captions: {}, images: [] })
    }).catch(() => {
      NotificationManager.error({ boldText: 'Error', text: 'We are sorry but something went wrong' })
    })
  }

  const onNewVideoClose = () => {
    setIsNewVideo(false)
  }

  const onNewVideoChange = (field, e) => {
    setNewVideo(prev => ({ ...prev, captions: { ...prev.captions, [field]: e.target.value } }))
  }

  const onVideoImageSelect = image => {
    setNewVideo(prev => ({ ...prev, images: prev.images.includes(image) ? prev.images.filter(i => i !== image) : [...prev.images, image] }))
  }

  const renderDescription = () => {
    if (!product.description?.length) return

    const text = moreDescription ? product.description : product.description.substring(0, 100)

    return <>
      <div className="mb-2 text-lg font-medium leading-5">Description</div>
      { text.split('\n').map((value, index) => <div key={index} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value) }}></div>) }
      { !moreDescription && <div className="text-blue1 text-sm cursor-pointer" onClick={() => setMoreDescription(true)}>Read More</div> }
    </>
  }

  if (!product) return null

  return (
    <div className="flex flex-col h-full py-5">
      <div className="flex items-center px-4 border-b border-gray2 pb-5">
        <div className="mr-4 cursor-pointer" onClick={onBack}><ArrowLeft /></div>
        <div className="flex flex-auto flex-col">
          <div className="flex text-xl leading-6">{ product.title }</div>
          <div className="flex justify-between mt-2.5">
            <div className="">
              {product.marketplace === 'aliexpress' &&
                <div className="flex">
                  <Rating score={product.reviewRating} />
                  <div className="ml-2 flex text-sm">{ product.reviewRating }</div>
                </div>
              }
            </div>
            <div className="flex text-xs text-secondary">
              Updated { dayjs(product.updatedAt).fromNow() }
            </div>
          </div>
        </div>
      </div>
      <div className="flex mt-6 px-4">
        <Gallery images={product.images} />
      </div>
      <div className="flex mt-5 px-4">
        <div className="flex flex-1 py-4 bg-white rounded-lg shadow-[0_4px_50px_rgba(0,0,0,0.05)]">
          <div className="flex flex-col flex-1 px-4 border-r border-[rgba(159,166,183,0.2)]">
            <div className="text-[10px] uppercase font-medium text-gray">
              Price
            </div>
            <div className="mt-2 text-lg font-medium leading-5">
              { product.priceCents.map(price => USDFormatter.format(price / 100)).join('-') }
            </div>
          </div>
          {product.marketplace === 'aliexpress' &&
            <>
              <div className="flex flex-col flex-1 px-4 border-r border-[rgba(159,166,183,0.2)]">
                <div className="text-[10px] uppercase font-medium text-gray">
                  Last Month Revenue
                </div>
                <div className="mt-2 text-lg font-medium leading-5">
                 { product.researches.length > 0 ? nFormatter(product.researches[product.researches.length - 1].revenue/100) : 'N/A'}
                </div>
              </div>
              <div className="flex flex-1 px-4 items-center">
                <div className="flex flex-1 flex-col">
                  <div className="text-[10px] uppercase font-medium text-gray">
                    Sales
                  </div>
                  <div className="mt-2 text-lg font-medium leading-5">
                    { product.currentOrdersCount || product.ordersCount }
                  </div>
                </div>
                <div className="ml-2 flex flex-1">
                  {product.marketplace === 'aliexpress' && product.growth >= 0 &&
                    <GrowthChart value={product.growth} />
                  }
                </div>
              </div>
            </>
            }
        </div>
      </div>
      <div className="flex flex-col mt-3 px-4 text-xs">
        { renderDescription() }
      </div>
      <div className="flex flex-col mt-3 px-4 pb-6">
        <div className="text-xs">
          <div className="mb-2 text-lg font-medium leading-5">Videos</div>
          <div className="flex flex-wrap">
            {
              product.videos.map(video => (
                <Video key={video.id} video={video} />
              ))
            }
          </div>
        </div>
        <div className="mt-4">
          <Button onClick={onNewVideo}>Create Video</Button>
        </div>
      </div>
      {product.marketplace === 'aliexpress' &&
        <div className="flex flex-col mt-3 px-4 pb-6">
          <div className="mb-2 text-lg font-medium leading-5">History</div>
          <div className="flex h-[155px]">
            <History data={product.researches} interval={product.interval} />
          </div>
        </div>
      }
      <div className="flex py-5 flex-1 items-end">
        <div className="flex flex-1 items-center cursor-pointer pl-12" onClick={addToSwipe}>
          <FavoriteIcon $active={product.isFavourite} />
          <span className="ml-2 text-sm select-none">Add to my favorites</span>
        </div>
        {product.marketplace === 'aliexpress' &&
          <div className="flex flex-1 items-center cursor-pointer pl-12 border-l border-[rgba(159,166,183,0.3)]">
            <ExternalLinkIcon />
            <a href={product.url} target="_blank" rel="noreferrer" className="ml-2 text-sm select-none">See on AliExpress</a>
          </div>
        }
      </div>

      <Modal isOpen={isNewVideo} onClose={onNewVideoClose} headerText="Create New Video">
        <div className="flex flex-col">
          <div className="flex text-secondary">Text</div>
          <div className="flex flex-col mt-2">
            <div className="flex mb-2">
              <Input maxLength={25} value={newVideo.captions.text1} onChange={onNewVideoChange.bind(this, 'text1')} placeholder="Text 1 (max. 25 character)" />
            </div>
            <div className="flex mb-2">
              <Input maxLength={25} value={newVideo.captions.text2} onChange={onNewVideoChange.bind(this, 'text2')} placeholder="Text 2 (max. 25 character)" />
            </div>
            <div className="flex mb-2">
              <Input maxLength={25} value={newVideo.captions.text3} onChange={onNewVideoChange.bind(this, 'text3')} placeholder="Text 3 (max. 25 character)" />
            </div>
            <div className="flex mb-2">
              <Input maxLength={25} value={newVideo.captions.end_text} onChange={onNewVideoChange.bind(this, 'end_text')} placeholder="End Text (max. 25 character)" />
            </div>
          </div>
          <div className="flex text-secondary">Select Images</div>
          <div className="flex flex-wrap mt-2">
            {
              product.images.map(image => (
                <div key={image} className="relative flex mb-2 w-24 h-24" onClick={onVideoImageSelect.bind(this, image)}>
                  {newVideo.images.includes(image) &&
                    <div className="absolute top-1 right-1 bg-blue text-white rounded-[12px] w-6 h-6 flex items-center justify-center">{ newVideo.images.indexOf(image) + 1 }</div>
                  }
                  <img src={image} style={{ opacity: newVideo.images.includes(image) ? 1 : 0.5 }} />
                </div>
              ))
            }
          </div>
          <div className="flex flex-1 mt-6">
            <div className="flex flex-1">
              <Button full secondary onClick={onNewVideoClose}>Cancel</Button>
            </div>
            <div className="flex flex-1 ml-6" onClick={onCreateVideo}>
              <Button disabled={isVideoSubmitting} full>{ isVideoSubmitting ? <><LoadingIcon /> Creating Video...</> : 'Create Video' }</Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default TrendingProduct
