import { useState, useRef, useEffect } from 'react'
import { useDebounce } from 'use-debounce'
import Input from '~/components/layout/Input'

const Duration = props => {
  const [val, setVal] = useState(props.value || '')
  const [debouncedValue] = useDebounce(val, 500)

  useEffect(() => {
    if (debouncedValue !== props.value) {
      props.onChange(debouncedValue)
    }
  }, [debouncedValue])

  const onHoursChange = e => {
    setVal(prev => ({ ...prev, hours: e.target.value }))
  }

  const onMinutesChange = e => {
    setVal(prev => ({ ...prev, minutes: e.target.value }))
  }

  const onSecondsChange = e => {
    setVal(prev => ({ ...prev, seconds: e.target.value }))
  }

  return (
    <div className="flex">
      <div className="flex items-center mr-4">
        <Input
          style={{ width: '50px' }}
          value={val.hours}
          onChange={onHoursChange}
          placeholder="00"
        />
        <div>hr</div>
      </div>
      <div className="flex items-center mr-4">
        <Input
          style={{ width: '50px' }}
          value={val.minutes}
          onChange={onMinutesChange}
          placeholder="00"
        />
        <div>min</div>
      </div>
      <div className="flex items-center">
        <Input
          style={{ width: '50px' }}
          value={val.seconds}
          onChange={onSecondsChange}
          placeholder="00"
        />
        <div>sec</div>
      </div>
    </div>
  )
}

export default Duration
