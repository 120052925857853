import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import api from '~/api'
import Card from '~/components/Trending/Card'

dayjs.extend(relativeTime)

const TrendingProductContent = props => {
  const [trendingProducts, setTrendingProducts] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    api.apiTrending.list({ query: { swipe_id: props.swipeId } }).then(data => setTrendingProducts(data.trendingProducts))
  }, [props.swipeId])

  const onClick = product => {
    navigate(`/trending/${product.marketplace}?productId=${product.productId}`)
  }

  const removeFromSwipe = id => {
    api.apiSwipes.deleteProducts({ data: {
      id: props.swipeId,
      product_type: 'TrendingProduct',
      product_ids: [id]
    } }).then(data => {
      setTrendingProducts(prev => prev.filter(p => p.productId !== id))
      props.update(data.swipe)
    })
  }

  return (
    <div className="">
      <div className="w-full grid gap-6 grid-cols-4 -mt-1 lg:gap-6 lg:grid-cols-4 md:gap-4 md:grid-cols-3 sm:gap-2 sm:grid-cols-2">
        {
          trendingProducts.map((p, index) => (
            <Card
              key={index}
              onClick={() => onClick(p)}
              productId={p.productId}
              imageSrc={p.image}
              title={p.title}
              updatedAt={dayjs(p.updatedAt).fromNow()}
              aliexpress={p.marketplace === 'aliexpress'}
              currentOrdersCount={p.currentOrdersCount}
              ordersCount={p.ordersCount}
              isFavourite
              addToSwipe={removeFromSwipe}
              growth={p.growth}
            />
          ))
        }
      </div>
    </div>
  )
}

export default TrendingProductContent
