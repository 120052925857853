import { useState } from 'react'

const AdProductImage = props => {
  const [main, setMain] = useState(props.images[0])

  return (
    <div className="flex flex-col w-[345px] min-w-[345px]">
      <div className="w-[345px]">
        <img src={main} />
      </div>
      <div className="w-full mt-2 flex overflow-auto pb-3">
        {
          props.images.map((image, index) => (
            <img className="h-16 mr-2 hover:scale-[1.03] transition-transform cursor-pointer" key={index} onClick={() => setMain(image)} height="48px" src={image} />
          ))
        }
      </div>
    </div>
  )
}

export default AdProductImage
