import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import api from '~/api'
import AdCard from '~/components/Ads/AdCard'
import AdsList from '~/components/Ads/AdsList'

dayjs.extend(relativeTime)

const AdContent = props => {
  const [ads, setAds] = useState({ list: [], meta: {} })
  const [currentPage, setCurrentPage] = useState(1)
  const navigate = useNavigate()

  useEffect(() => {
    api.apiAds.list({ query: { swipe_id: props.swipeId, page: currentPage} }).then(data => setAds({list: data.ads, meta: data.meta}))
  }, [props.swipeId, currentPage])

  const onPageChange = ({ selected }) => {
    setCurrentPage(selected + 1)
  }

  const onAdClick = id => {
    navigate(`/ads/${id}`)
  }

  const removeFromSwipe = id => {
    api.apiSwipes.deleteProducts({ data: {
      id: props.swipeId,
      product_type: 'Ad',
      product_ids: [id]
    } }).then(data => {
      setAds({...ads, list: ads.list.filter(a => a.id !== id)})
      props.update(data.swipe)
    })
  }

  return (
    <div className="">
      <AdsList
        pagesCount={ads.meta.pagesCount}
        currentPage={currentPage}
        onPageChange={onPageChange}
      >
        {
          ads.list.map(ad => (
            <AdCard
              key={ad.id}
              ad={ad}
              onClick={onAdClick}
              isFavourite
              addToSwipe={removeFromSwipe}
            />
          ))
        }
      </AdsList>
    </div>
  )
}

export default AdContent
