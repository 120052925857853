import { useState, useEffect, useRef } from 'react'
import { easepick } from '@easepick/core'
import dayjs from 'dayjs'
import useClickOutside from '~/components/layout/hooks/useClickOutside'

const DueDateField = props => {
  const newRef = useRef()
  const [isOpen, setOpen] = useState(false)

  const nodeStartRef = useRef()

  console.log('asdasd', props.value)

  useEffect(() => {
    const picker = new easepick.create({
      element: nodeStartRef.current,
      css: ['https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css'],
      zIndex: 10,
      grid: 1,
      calendars: 1,
      plugins: [],
      date: dayjs(props.value).format('MMM D, YYYY'),
      format: 'MMM D, YYYY'
    })

    picker.on('select', e => {
      props.onUpdate(dayjs(e.detail.date).format('YYYY-MM-DD'), 'due_date')
      setOpen(false)
      // setMin(dayjs(e.detail.start).format('YYYY-MM-DD'))
      // setMax(dayjs(e.detail.end).format('YYYY-MM-DD'))
    })

    return () => {
      picker.destroy()
    }
  }, [])

  useClickOutside(newRef, () => setOpen(false))

  return (
    <div className="relative">
      <div ref={nodeStartRef} className="cursor-pointer" />
    </div>
  )
}

export default DueDateField
