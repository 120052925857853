import { useState, useEffect } from 'react'
import api from '~/api'
import Button from '~/components/layout/Button'
import EditableField from '~/components/Briefs/EditableField'
import Multiple from './Brand/Multiple'
import TextField from './form/TextField'
import BrandImageField from './form/BrandImageField'

const musicGenres = ['Acoustic', 'Ambient', 'Blues', 'Children', 'Cinematic', 'Classical', 'Corporate', 'Country', 'Electronic',
  'Fantasy', 'Folk', 'Funk', 'Hip Hop', 'Holiday', 'Indie', 'Jazz', 'Latin', 'Lofi & Chill Beats', 'Lounge', 'Pop', 'Reggae', 'Retro',
  'Rock', 'Singer-Songwriter', 'Soul & RnB', 'World', 'Worship']

const voices = ['Adventurous', 'Authentic', 'Authoritative', 'Bold', 'Caring', 'Edgy', 'Friendly', 'Informative', 'Innovative',
  'Inspiring', 'Minimalistic', 'Nostalgic', 'Playful', 'Quirky', 'Rebellious', 'Romantic', 'Sophisticated', 'Trustworthy', 'Whimsical', 'Witty']

const Field = props => (
  <div className="flex-auto flex items-center min-h-[38px]">
    <div className="flex-0 flex items-center basis-[225px] py-0 pr-3 min-w-[225px]">{ props.name }</div>
    <div className="flex-auto break-all">{ props.children }</div>
  </div>
)

const BrandProfile = props => {
  const [brand, setBrand] = useState()

  const { id } = props

  useEffect(() => {
    if (id) {
      api.apiBriefBrands.get({ id: props.id }).then(data => setBrand(data.briefBrand))
    } else {
      setBrand(null)
    }
  }, [id])

  const onUpdate = (value, field) => {
    const payload = {
      data: {
        id,
        brief_brand: { [field]: value }
      }
    }

    return api.apiBriefBrands.update(payload).then(data => setBrand(data.briefBrand))
  }

  const onDelete = () => {
    api.apiBriefBrands.destroy({ id }).then(() => {
      setBrand(null)
      if (typeof onDelete === 'function') {
        props.onDelete()
      }
    })
  }

  if (!brand) return

  const musicGenresOptions = musicGenres.map(g => ({ label: g, value: g }))
  const voicesOptions = voices.map(g => ({ label: g, value: g }))

  return (
    <div className="w-full">
      <div className="flex items-center border-b border-gray/20 pb-4">
        <div className="">
          <div className="w-10 h-10 flex items-center justify-center rounded-full border border-gray">
            <BrandImageField brandId={brand.id} value={brand.logoUrl} onUpdate={onUpdate} />
          </div>
        </div>
        <div className="ml-3">
          <EditableField type="text" field="name" value={brand.name} onUpdate={onUpdate} minWidth="auto" icon />
        </div>
      </div>
      <div className="mt-4 flex-auto flex flex-col">
        <div>
          <div className="text-sm font-medium">Brand Guidelines</div>
          <div className="text-xs text-gray">Add overarching brand context & details.</div>
        </div>
        <div className="mt-3 flex flex-col text-sm">
          <Field name="Brand Colors">
            <Multiple type="text" field="colors" value={brand.colors} onUpdate={onUpdate} defaultValue="#FFFFFF" width="80px" />
          </Field>
          <Field name="Brand Guidelines Doc">
            <TextField field="doc_url" value={brand.docUrl} onChange={onUpdate} placeholder="https://example.com/brand-guidelines" />
          </Field>
          <Field name="Fonts">
            <TextField field="fonts_url" value={brand.fontsUrl} onChange={onUpdate} placeholder="https://example.com/fonts" />
          </Field>
          <Field name="Music Genres">
            <Multiple type="select" options={musicGenresOptions} field="music_genres" value={brand.musicGenres} onUpdate={onUpdate} defaultValue="" />
          </Field>
          <Field name="Voice / Brand Personality">
            <Multiple type="select" options={voicesOptions} field="personality" value={brand.personality} onUpdate={onUpdate} defaultValue="" />
          </Field>
          <Field name="Tagline">
            <TextField field="tagline" value={brand.tagline} onChange={onUpdate} placeholder="Your tagline" />
          </Field>
        </div>
      </div>
      <div className="mt-5 flex-auto flex flex-col">
        <div>
          <div className="text-sm font-medium">Web Properties</div>
          <div className="text-xs text-gray">Link out to all areas where creatives can find you online.</div>
        </div>
        <div className="mt-2 flex flex-col text-sm">
          <Field name="Website">
            <TextField field="website_url" value={brand.websiteUrl} onChange={onUpdate} placeholder="https://example.com" />
          </Field>
          <Field name="Website Alt">
            <TextField field="website_alt_url" value={brand.websiteAltUrl} onChange={onUpdate} placeholder="https://altexample.com" />
          </Field>
          <Field name="Facebook">
            <TextField field="facebook_url" value={brand.facebookUrl} onChange={onUpdate} placeholder="https://facebook.com/pages/pageId" />
          </Field>
          <Field name="Instagram">
            <TextField field="instagram_url" value={brand.instagramUrl} onChange={onUpdate} placeholder="https://instagram.com/username" />
          </Field>
          <Field name="TikTok">
            <TextField field="tiktok_url" value={brand.tiktokUrl} onChange={onUpdate} placeholder="https://tiktok.com/@username" />
          </Field>
          <Field name="LinkedIn">
            <TextField field="linkedin_url" value={brand.linkedinUrl} onChange={onUpdate} placeholder="https://linkedin.com/in/username" />
          </Field>
          <Field name="YouTube">
            <TextField field="youtube_url" value={brand.youtubeUrl} onChange={onUpdate} placeholder="https://youtube.com/user/username" />
          </Field>
          <Field name="Twitter">
            <TextField field="twitter_url" value={brand.twitterUrl} onChange={onUpdate} placeholder="https://twitter.com/username" />
          </Field>
          <Field name="Pinterest">
            <TextField field="pinterest_url" value={brand.pinterestUrl} onChange={onUpdate} placeholder="https://pinterest.com/username" />
          </Field>
        </div>
      </div>
      <div className="mt-5 flex-auto flex flex-col">
        <div>
          <div className="text-sm font-medium">Brand Assets</div>
          <div className="text-xs text-gray">Include links to re-useable asset folders to inject on every brief.</div>
        </div>
        <div className="mt-2 flex flex-col text-sm">
          <Field name="Assets">
            <Multiple type="text" field="assets" value={brand.assets} onUpdate={onUpdate} placeholder="https://example.com/asset" width="300px" multiline />
          </Field>
        </div>
      </div>
      <div className="mt-5 flex-auto flex flex-col">
        <div>
          <div className="text-sm font-medium">Additional Information</div>
          <div className="text-xs text-gray">Write, link or explain any crucial information not offered in the form.</div>
        </div>
        <div className="mt-2 flex flex-col text-sm">
          <Field name="Additional Information">
            <TextField field="additional_info" value={brand.additionalInfo} onChange={onUpdate} placeholder="Info" />
          </Field>
        </div>
      </div>
      <div className="mt-4">
        <Button red onClick={onDelete}>Delete brand</Button>
      </div>
    </div>
  )
}

export default BrandProfile
