import { useState } from 'react'
import api from '~/api'
import Button from '~/components/layout/Button'

const ShareSwipe = props => {
  const [sharedSwipe, setSharedSwipe] = useState(props.swipe.sharedSwipe)
  console.log('ShareSwipe', props, sharedSwipe)

  const onShare = () => {
    api.apiSwipes.share({ data: { id: props.swipe.id } }).then(data => {
      setSharedSwipe(data.sharedSwipe)
    })
  }

  const onRevoke = () => {
    api.apiSwipes.unshare({ data: { id: props.swipe.id } }).then(() => {
      setSharedSwipe(null)
    })
  }

  return (
    <div className="">
      {
        sharedSwipe
        ? <div>
          <div className="mb-4">{ `${window.location.origin}/public/swipes/${sharedSwipe.cid}` }</div>
          <Button onClick={onRevoke}>Revoke</Button>
        </div>
        : <Button onClick={onShare}>Share</Button>
      }
    </div>
  )
}

export default ShareSwipe
