import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import api from '~/api'
import AdStoreTable from '~/components/AdStores/Table'
import Block from './Block'

const TopStores = () => {
  const [adStores, setAdStores] = useState([])
  const navigate = useNavigate()

  const onViewAll = () => {
    navigate('/ad_stores')
  }

  useEffect(() => {
    api.apiAdStores.list({ query: { limit: 5 } }).then(data => setAdStores(data.adStores))
  }, [])

  return (
    <Block title="Top Stores" onViewAll={onViewAll}>
      <div className="mt-5 w-full px-12">
        <AdStoreTable adStores={adStores} />
      </div>
    </Block>
  )
}

export default TopStores
