const TikTok = () => (
  <svg width="69" height="20" viewBox="0 0 69 20" fill="none">
    <g clipPath="url(#clip0_2899_20130)">
      <path d="M13.1171 7.22039C14.4085 8.14304 15.9905 8.68591 17.6991 8.68591V5.39967C17.3757 5.39974 17.0532 5.36604 16.7368 5.29905V7.88578C15.0283 7.88578 13.4466 7.34291 12.1548 6.42033V13.1266C12.1548 16.4814 9.43384 19.2008 6.07753 19.2008C4.82521 19.2008 3.66122 18.8224 2.69431 18.1734C3.79789 19.3012 5.33691 20.0009 7.03957 20.0009C10.3961 20.0009 13.1172 17.2814 13.1172 13.9265V7.22039H13.1171ZM14.3041 3.90499C13.6442 3.18434 13.2108 2.25305 13.1171 1.22346V0.800781H12.2052C12.4347 2.10939 13.2176 3.22738 14.3041 3.90499ZM4.81718 15.599C4.44845 15.1158 4.24919 14.5247 4.25008 13.9169C4.25008 12.3825 5.49464 11.1385 7.0301 11.1385C7.31626 11.1384 7.6007 11.1822 7.8734 11.2687V7.90898C7.55471 7.86533 7.23307 7.84679 6.91156 7.85359V10.4686C6.63865 10.3821 6.35408 10.3382 6.06785 10.3385C4.53239 10.3385 3.2879 11.5824 3.2879 13.117C3.2879 14.202 3.90997 15.1414 4.81718 15.599Z" fill="#FF004F" />
      <path d="M12.1548 6.42027C13.4465 7.34285 15.0283 7.88571 16.7368 7.88571V5.29898C15.7832 5.09594 14.9389 4.59783 14.3041 3.90499C13.2176 3.22731 12.4347 2.10932 12.2052 0.800781H9.80998V13.9264C9.80456 15.4565 8.56212 16.6955 7.02996 16.6955C6.12708 16.6955 5.32496 16.2654 4.81696 15.599C3.90983 15.1414 3.28775 14.202 3.28775 13.117C3.28775 11.5826 4.53225 10.3385 6.0677 10.3385C6.36189 10.3385 6.64544 10.3843 6.91142 10.4687V7.85366C3.61406 7.92175 0.962189 10.6146 0.962189 13.9264C0.962189 15.5797 1.62257 17.0784 2.69437 18.1735C3.66129 18.8224 4.82527 19.2009 6.07759 19.2009C9.43397 19.2009 12.1549 16.4813 12.1549 13.1266V6.42027H12.1548Z" fill="black" />
      <path d="M16.7368 5.29819V4.59875C15.8768 4.60006 15.0338 4.35934 14.3041 3.90413C14.95 4.6109 15.8005 5.09824 16.7368 5.29819ZM12.2052 0.799989C12.1833 0.674929 12.1665 0.549044 12.1548 0.42268V0H8.84765V13.1257C8.84237 14.6557 7.6 15.8947 6.0677 15.8947C5.61784 15.8947 5.1931 15.788 4.81696 15.5983C5.32495 16.2646 6.12707 16.6947 7.02995 16.6947C8.56198 16.6947 9.80462 15.4558 9.80997 13.9256V0.799989H12.2052ZM6.91155 7.85287V7.10827C6.63521 7.07052 6.3566 7.05157 6.07765 7.05171C2.72099 7.05164 0 9.77119 0 13.1257C0 15.2288 1.0694 17.0823 2.69443 18.1726C1.62263 17.0776 0.962252 15.5787 0.962252 13.9256C0.962252 10.6138 3.61406 7.92096 6.91155 7.85287Z" fill="#00F2EA" />
      <path d="M55.0572 16.4491C57.4588 16.4491 59.4058 14.5179 59.4058 12.1358C59.4058 9.75384 57.4588 7.82227 55.0572 7.82227H54.4021C56.8038 7.82227 58.7507 9.75377 58.7507 12.1358C58.7507 14.5179 56.8038 16.4491 54.4021 16.4491H55.0572Z" fill="#FF004F" />
      <path d="M54.3427 7.82227H53.6876C51.2861 7.82227 49.3388 9.75377 49.3388 12.1358C49.3388 14.5179 51.2861 16.4491 53.6876 16.4491H54.3427C51.9408 16.4491 49.9939 14.5179 49.9939 12.1358C49.9938 9.75384 51.9408 7.82227 54.3427 7.82227Z" fill="#00F2EA" />
      <path d="M21.2824 5.87305V8.05941H23.8434V16.3904H26.4054V8.11844H28.4901L29.2049 5.87312L21.2824 5.87305ZM42.2504 5.87305V8.05941H44.8114V16.3904H47.3734V8.11844H49.4581L50.1728 5.87312L42.2504 5.87305ZM29.6819 7.11377C29.6819 6.42868 30.241 5.87305 30.9318 5.87305C31.6232 5.87305 32.183 6.42868 32.183 7.11377C32.183 7.79892 31.6236 8.35456 30.9318 8.35456C30.241 8.35421 29.6819 7.79892 29.6819 7.11377ZM29.6819 9.2411H32.183V16.3904H29.6819V9.2411ZM33.2554 5.87305V16.3903H35.7574V13.6726L36.5319 12.963L38.9738 16.4494H41.6542L38.1394 11.368L41.2966 8.29518H38.2588L35.7574 10.7767V5.87305H33.2554ZM60.2393 5.87305V16.3903H62.7416V13.6726L63.5158 12.963L65.9584 16.4494H68.6391L65.1243 11.368L68.2814 8.29518H65.2437L62.7416 10.7767V5.87305H60.2393Z" fill="black" />
      <path d="M54.4019 16.4491C56.8034 16.4491 58.7507 14.5179 58.7507 12.1358C58.7507 9.75384 56.8034 7.82227 54.4019 7.82227H54.3425C51.9408 7.82227 49.9939 9.75377 49.9939 12.1358C49.9939 14.5179 51.9408 16.4491 54.3425 16.4491H54.4019ZM52.2574 12.1358C52.2574 10.9771 53.2047 10.0386 54.3727 10.0386C55.5399 10.0386 56.4871 10.9771 56.4871 12.1358C56.4871 13.2945 55.5399 14.2336 54.3727 14.2336C53.2047 14.2334 52.2574 13.2945 52.2574 12.1358Z" fill="black" />
    </g>
    <defs>
      <clipPath id="clip0_2899_20130">
        <rect width="68.6391" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default TikTok
