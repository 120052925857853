import NavItem from './NavItem'

const VideosIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.75 16.5H11.25C15 16.5 16.5 15 16.5 11.25V6.75C16.5 3 15 1.5 11.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 15 3 16.5 6.75 16.5Z" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.875 13.5V4.5L12.375 9L7.875 13.5Z" fill="currentColor"/>
  </svg>
)

const VideosMenu = () => (
  <NavItem to="/videos">
    <div className="flex flex-col items-center"><VideosIcon /></div>
    <span className="ml-2 leading-[15px]">Videos</span>
  </NavItem>
)

export default VideosMenu
