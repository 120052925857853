import React, { useState, useEffect } from 'react'
import Button from '~/components/layout/Button'
import Input from '~/components/layout/Input'
import Select from '~/components/layout/Select'

const EditListing = props => {
  const [groupId, setGroupId] = useState(props.swipeGroupId)
  const [name, setName] = useState(props.name)
  const [keyword, setKeyword] = useState(props.keyword)

  useEffect(() => {
    setName(props.name)
    setKeyword(props.keyword)
  }, [props.name, props.keyword])

  const onGroupChange = e => {
    setGroupId(Number(e.target.value))
  }

  const onNameChange = e => {
    setName(e.target.value)
  }

  const onKeywordChange = e => {
    setKeyword(e.target.value)
  }

  const updateSwipe = () => {
    props.onSubmit(name, keyword, groupId)
  }

  const groupsOptions = props.groups.map(group => ({ label: group.name, value: group.id }))

  return (
    <div className="flex flex-col">
      <div className="flex text-secondary">Swipe Folder</div>
      <div className="flex mt-2">
        <Select options={groupsOptions} value={groupId} onChange={onGroupChange} />
      </div>
      <div className="flex text-secondary">Name of Swipe</div>
      <div className="flex mt-2">
        <Input value={name} onChange={onNameChange} placeholder="Swipe Name" />
      </div>
      <div className="flex text-secondary">Keyword</div>
      <div className="flex mt-2">
        <Input value={keyword} onChange={onKeywordChange} placeholder="Keyword" />
      </div>
      <div className="flex flex-1 mt-6">
        <div className="flex flex-1">
          <Button full secondary onClick={props.onCancel}>Cancel</Button>
        </div>
        <div className="flex flex-1 ml-6" onClick={updateSwipe}>
          <Button full>Submit</Button>
        </div>
      </div>
    </div>
  )
}

export default React.memo(EditListing)
