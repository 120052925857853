import React, { useState, useEffect, useRef } from 'react'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import relativeTime from 'dayjs/plugin/relativeTime'
import QRCode from 'react-qr-code'
import { Tooltip } from 'react-tooltip'
import api from '~/api'
import NotificationManager from '~/services/NotificationManager'
import { nFormatter, toPercentage, currencyFormatter } from '~/helpers/formatter'
import AddToListing from '~/components/Research/AddToListing'
import BarCodeIcon from '~/components/icons/BarCode'
import TrendingFavoriteIcon from '~/components/icons/TrendingFavorite'
import LinkIcon from '~/components/icons/Link'
import CopyIcon from '~/components/icons/Copy'
import MobileIcon from '~/components/icons/Mobile'
import DesktopIcon from '~/components/icons/Desktop'
import Button from '~/components/layout/Button'
// import EditableField from './EditableField'
import s from './AnalysisTab.module.css'
import Carousel from '~/components/Home/Carousel'
import { useGetUserQuery, useUpdateUserMutation } from '../../store/api/userApiSlice'

dayjs.extend(relativeTime)

const renderSection = (title, content) => {
  if (!content || content.length === 0) return null;
  return (
    <div className="flex-auto flex border-b border-b-gray/20">
      <div className="flex-0 basis-1/2 py-4 px-5 border-r border-r-gray/20 break-all">
        {Array.isArray(content) ? content.join(', ') : content}
      </div>
      <div className="flex-auto py-4 px-5">{title}</div>
    </div>
  );
}

const AnalysisTab = props => {
  const [itemWidth, setItemWidth] = useState(0);
  const [ad, setAd] = useState()
  const [isModalOpen, setModalOpen] = useState(false)
  const [user, setUser] = useState()
  const { data, status } = useGetUserQuery()
  useEffect(() => {
    if(status === "fulfilled") setUser(data?.user)
  }, [status])

  const [updateUserData] = useUpdateUserMutation();

  const navigate = useNavigate()
  const containerRef = useRef()

  const excludedOrigins = ["ads.tiktok.com"]

  useEffect(() => {
    api.apiAds.get({ id: props.id })
    .then(data => setAd(data.ad))
  }, [props.id])

  const onCopy = val => {
    navigator.clipboard.writeText(val)
  }
  // Function to update the container's width
  const updateContainerWidth = () => {
    if (containerRef.current) {
      const containerRect = containerRef.current.offsetWidth;
      setItemWidth(containerRect);
    }
  };

  useEffect(() => {
    // Initial call to set the container width
    updateContainerWidth();

    // Listen for the window resize event and update the container width
    window.addEventListener('resize', updateContainerWidth);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateContainerWidth);
    };
  }, [ad]);

  const blockAdvertiser = () => {
    updateUserData({ user: {blocked_advertisers: [...new Set([...user.blocked_advertisers, ad.advertiser.id])]} }).unwrap()
    .then(data => {
      setUser(data.user)
      NotificationManager.success({ boldText: 'Advertiser', text: 'have been successfully blocked' })
    })
    .catch(e => {
      NotificationManager.error({ boldText: 'Error', text: 'We are sorry but something went wrong' })
    })
  }
  // const onUpdate = (val, field) => (
  //   api.apiAds.update({ data: { id: props.id, ad: { [field]: val } } })
  // )

  const renderQRCode = url => {
    if (!url?.length) return

    return <QRCode value={url} size={156} />
  }

  const getAdvertiserLink = () => {
    try {
      if (ad.finalUrl?.length) {
        const url = new URL(ad.finalUrl)

        return url.origin
      }
    } catch (e) {
      return null
    }
  }

  const onAdProductClick = () => {
    navigate(`/products/${ad.adProduct.id}`)
  }

  const addToSwipe = e => {
    e.stopPropagation()

    setModalOpen(true)
  }

  const onSubmitToSwipe = swipeId => {
    const { advertiser } = ad

    if (!advertiser) return

    api.apiSwipes.addProducts({ data: {
      id: swipeId,
      product_type: 'Advertiser',
      advertiser_id: advertiser.id
    } }).then(() => {
      setModalOpen(false)
      NotificationManager.success({ boldText: `${advertiser.name} advertiser`, text: 'has been successfully added to swipe' })
    })
  }

  if (!ad) return null

  return (
    <div className={s.AnalysisTab}>
      <div className={s.videoWrapper}>
      { (() => {
          switch (ad.adFormat) {
            case 'video':
              return (
                <video width="345" controls poster={ad.coverUrl} preload="none">
                  <source src={ad.videoUrl} type="video/mp4" />
                </video>
              )

            case 'carousel':
              return (
                <div className="w-full" ref={containerRef}>
                  <Carousel containerPadding={'px-0 mt-4'} itemWidth={itemWidth}>
                    {
                      ad.carouselImages?.map((image, index) => (
                        <div style={{ width: `${itemWidth}px`, minWidth: `${itemWidth}px` }} className="relative" key={index}>
                          <img src={image} />
                        </div>
                      ))
                    }
                  </Carousel>
                </div>
              )

            case 'image':
              return (<img src={ad.coverUrl} />)

            default: {
              return (<img src={ad.coverUrl} />)
            }
          }
        })()
      }
        <div className="flex items-center mt-6">
          <div className="flex-1 relative group">
            <Button secondary full>
              <div className="w-full flex items-center justify-center">
                <BarCodeIcon />
                <span className="ml-2.5">TikTok Code</span>
                {ad.tiktokUrl?.length &&
                  <div className="absolute top-10 left-0 hidden group-hover:flex p-1 bg-white">
                    { renderQRCode(ad.tiktokUrl) }
                  </div>
                }
              </div>
            </Button>
          </div>
          <div className="flex-1 ml-4 relative group">
            <Button secondary full>
              <div className="w-full flex items-center justify-center">
                <LinkIcon />
                <span className="ml-2.5">Mobile Code</span>
                {ad.finalUrl?.length &&
                  <div className="absolute top-10 left-0 hidden group-hover:flex p-1 bg-white">
                    { renderQRCode(ad.finalUrl) }
                  </div>
                }
              </div>
            </Button>
          </div>
        </div>
      </div>
      <div className="flex-auto ml-10 select-text">
        <div className="flex-auto flex flex-col bg-white rounded-lg p-[30px] shadow-[0_4px_50px_rgba(0,0,0,0.05)]">
          <div className="flex justify-between">
            <div className="flex">
              <div className="">
                <div className="w-10 h-10 bg-gray rounded-full">
                  <img className="rounded-full" src={ad.advertiser.logoUrl} />
                </div>
              </div>
              <div className="flex flex-col ml-3">
                <div className="flex items-center">
                  <div className="text-lg font-medium leading-5">{ ad.advertiser?.name }</div>
                  <div className="ml-2 bg-gray1 rounded-[10px] py-1 px-2 text-[10px] leading-[11px]">{ ad.objective }</div>
                </div>
                <div className="mt-1 text-sm leading-[15px]">
                  Advertiser:
                  <span className="ml-0.5 text-blue1"><a href={ad.pageLink} target="_blank" rel="noreferrer">{ getAdvertiserLink() }</a></span>
                </div>
              </div>
              <div className="flex-0 text-gray ml-2" onClick={addToSwipe}>
                <TrendingFavoriteIcon $active={!!ad.advertiser?.swipesIds?.length} />
              </div>
            </div>
            <div className="flex items-center">
              <div className="" data-tooltip-id="advertiser-analysis" data-tooltip-content="Coming soon">
                <Button secondary>See advertiser’s analysis</Button>
                <Tooltip id="advertiser-analysis" />
              </div>
              <div className="ml-2.5" data-tooltip-id="product-analysis" data-tooltip-content="Coming soon">
                <Button secondary>See product’s analysis</Button>
                <Tooltip id="product-analysis" />
              </div>
            </div>
          </div>
          <div className="bg-gray/10 rounded-lg mt-[30px] p-6">
            <div className="flex flex-0 w-full justify-between items-center font-medium">
              <div className="flex-1 flex flex-col items-center border-r border-r-gray/20">
                <div className="">{ nFormatter(ad.impressions) }</div>
                <div className="mt-1 text-[8px] text-gray uppercase tracking-wider">Ad Impression</div>
              </div>
              <div className="flex-1 flex flex-col items-center border-r border-r-gray/20">
                <div className="">{ ad.seenDiff }</div>
                <div className="mt-1 text-[8px] text-gray uppercase tracking-wider">Days</div>
              </div>
              <div className="flex-1 flex flex-col items-center border-r border-r-gray/20">
                <div className="">{ nFormatter(ad.popularity) }</div>
                <div className="mt-1 text-[8px] text-gray uppercase tracking-wider">Popularity</div>
              </div>
              <div className="flex-1 flex flex-col items-center border-r border-r-gray/20">
                <div className="">N/A</div>
                <div className="mt-1 text-[8px] text-gray uppercase tracking-wider">Ad Cost</div>
              </div>
              <div className="flex-1 flex flex-col items-center border-r border-r-gray/20">
                <div className="">N/A</div>
                <div className="mt-1 text-[8px] text-gray uppercase tracking-wider">Conversions</div>
              </div>
              <div className="flex-1 flex flex-col items-center">
                <div className="">{ toPercentage(ad.likes, ad.impressions) }%</div>
                <div className="mt-1 text-[8px] text-gray uppercase tracking-wider">Like Rate</div>
              </div>
            </div>
          </div>
          <div className="flex flex-col mt-[30px] rounded-lg border border-gray/20 text-sm">
            <div className="flex-auto flex border-b border-b-gray/20">
              <div className="flex-0 basis-[195px] p-6 border-r border-r-gray/20 min-w-[195px]">
                Display Name
              </div>
              <div className="flex-auto p-6 break-all">
                { ad.advertiser?.name }
              </div>
            </div>
            <div className="flex-auto flex border-b border-b-gray/20">
              <div className="flex-0 basis-[195px] p-6 border-r border-r-gray/20 min-w-[195px]">
                Ad Text
              </div>
              <div className="flex-auto p-6 break-all">
                { ad.title }
              </div>
            </div>
            <div className="flex-auto flex border-b border-b-gray/20">
              <div className="flex-0 basis-[195px] p-6 border-r border-r-gray/20 min-w-[195px]">
                Ad Scheduling
              </div>
              <div className="flex-auto p-6 break-all">
                { ad.scheduledAt }
              </div>
            </div>
            <div className="flex-auto flex border-b border-b-gray/20">
              <div className="flex-0 basis-[195px] p-6 border-r border-r-gray/20 min-w-[195px]">
                First Seen - Last Seen
              </div>
              <div className="flex-auto p-6 break-all">
                { ad.firstSeen } - { ad.lastSeen }
              </div>
            </div>
            <div className="flex-auto flex border-b border-b-gray/20">
              <div className="flex-0 basis-[195px] p-6 border-r border-r-gray/20 min-w-[195px]">
                Country/Region
              </div>
              <div className="flex-auto p-6 break-all">
                { ad.regionList.length ? ad.regionList.join(", ") : ad.region }
              </div>
            </div>
            <div className="flex-auto flex border-b border-b-gray/20">
              <div className="flex-0 basis-[195px] p-6 border-r border-r-gray/20 min-w-[195px]">
                Audience
              </div>
              <div className="flex-auto p-6 break-all">
                { !!ad.age?.length && <span>{ ad.age }<span className="mx-1">&#x2022;</span></span> }
                <span>{ ad.gender }</span>
              </div>
            </div>
            <div className="flex-auto flex border-b border-b-gray/20">
              <div className="flex-0 basis-[195px] p-6 border-r border-r-gray/20 min-w-[195px]">
                Size
              </div>
              <div className="flex-auto p-6 break-all">
                { ad.size }
              </div>
            </div>
            <div className="flex-auto flex border-b border-b-gray/20">
              <div className="flex-0 basis-[195px] p-6 border-r border-r-gray/20 min-w-[195px]">
                Type
              </div>
              <div className="flex-auto p-6 break-all">
                N/A
              </div>
            </div>
            <div className="flex-auto flex border-b border-b-gray/20">
              <div className="flex-0 basis-[195px] p-6 border-r border-r-gray/20 min-w-[195px]">
                CTA Button
              </div>
              <div className="flex-auto p-6 break-all">
                { ad.ctaButton }
              </div>
            </div>
            <div className="flex-auto flex border-b border-b-gray/20">
              <div className="flex-0 basis-[195px] p-6 border-r border-r-gray/20 min-w-[195px]">
                Platform
              </div>
              <div className="flex-auto p-6 break-all">
                { ad.platform }
              </div>
            </div>
            {ad.utmSource?.length &&
              <div className="flex-auto flex border-b border-b-gray/20">
                <div className="flex-0 basis-[195px] p-6 border-r border-r-gray/20 min-w-[195px]">
                  UTM Source
                </div>
                <div className="flex-auto p-6 break-all">
                  { ad.utmSource }
                </div>
              </div>
            }
            {ad.utmMedium?.length &&
              <div className="flex-auto flex border-b border-b-gray/20">
                <div className="flex-0 basis-[195px] p-6 border-r border-r-gray/20 min-w-[195px]">
                  UTM Medium
                </div>
                <div className="flex-auto p-6 break-all">
                  { ad.utmMedium }
                </div>
              </div>
            }
            {ad.utmCampaign?.length &&
              <div className="flex-auto flex border-b border-b-gray/20">
                <div className="flex-0 basis-[195px] p-6 border-r border-r-gray/20 min-w-[195px]">
                  UTM Campaign
                </div>
                <div className="flex-auto p-6 break-all">
                  { ad.utmCampaign }
                </div>
              </div>
            }
            {ad.utmTerm?.length &&
              <div className="flex-auto flex border-b border-b-gray/20">
                <div className="flex-0 basis-[195px] p-6 border-r border-r-gray/20 min-w-[195px]">
                  UTM Term
                </div>
                <div className="flex-auto p-6 break-all">
                  { ad.utmTerm }
                </div>
              </div>
            }
            {ad.utmContent?.length &&
              <div className="flex-auto flex border-b border-b-gray/20">
                <div className="flex-0 basis-[195px] p-6 border-r border-r-gray/20 min-w-[195px]">
                  UTM Content
                </div>
                <div className="flex-auto p-6 break-all">
                  { ad.utmContent }
                </div>
              </div>
            }
            <div className="flex-auto flex border-b border-b-gray/20">
              <div className="flex-0 basis-[195px] p-6 border-r border-r-gray/20 min-w-[195px]">
                Tags
              </div>
              <div className="flex-auto p-6 break-all">
                {
                  ad.tags.map((tag, index) => (
                    <span key={index} className="m-1 inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">
                      { tag }
                    </span>
                  ))
                }
              </div>
            </div>
            <div className="flex-auto flex border-b border-b-gray/20">
              <div className="flex-0 basis-[195px] p-6 border-r border-r-gray/20 min-w-[195px]">
                Ad URL
              </div>
              <div onClick={() => onCopy(ad.tiktokUrl)} className="flex-auto flex justify-between p-6 text-blue1 cursor-pointer break-all">
                { ad.tiktokUrl }
                <CopyIcon />
              </div>
            </div>
            <div className="flex-auto flex border-b border-b-gray/20">
              <div className="flex-0 basis-[195px] p-6 border-r border-r-gray/20 min-w-[195px]">
                Bio URL
              </div>
              <div onClick={() => onCopy(ad.bioUrl)} className="flex-auto flex justify-between p-6 text-blue1 cursor-pointer break-all">
                { ad.bioUrl }
                <CopyIcon />
              </div>
            </div>
            <div className={`flex-auto flex ${ad.adProduct ? 'border-b border-b-gray/20' : ''}`}>
              <div className="flex-0 basis-[195px] p-6 border-r border-r-gray/20 min-w-[195px]">
                <div>Final Link</div>
                <div className="mt-4 flex items-center">
                  {ad.finalUrlScreenshotDesktop?.length &&
                    <div className="text-xs cursor-pointer">
                      <a href={ad.finalUrlScreenshotDesktop} target="_blank" rel="noreferrer" className="inline-flex flex items-center border border-gray py-1 px-2 rounded">
                        <DesktopIcon />&nbsp;View
                      </a>
                    </div>
                  }
                  {ad.finalUrlScreenshotMobile?.length &&
                    <div className="ml-2 text-xs cursor-pointer">
                      <a href={ad.finalUrlScreenshotMobile} target="_blank" rel="noreferrer" className="inline-flex flex items-center border border-gray py-1 px-2 rounded">
                        <MobileIcon />&nbsp;View
                      </a>
                    </div>
                  }
                </div>
              </div>
              <div onClick={() => onCopy(ad.finalUrl)} className="flex-auto flex justify-between p-6 text-blue1 cursor-pointer break-all">
                { ad.finalUrl }
                <CopyIcon />
              </div>
            </div>
            {ad.adProduct &&
              <div className="flex-auto flex" onClick={onAdProductClick}>
                <div className="flex-0 basis-[195px] p-6 border-r border-r-gray/20 min-w-[195px]">
                  Product
                </div>
                <div onClick={() => onCopy(ad.finalUrl)} className="flex-auto flex justify-between p-6 cursor-pointer">
                  <div className="flex">
                    <div className="flex">
                      <img src={ad.adProduct.imageSrc} width="48" height="48" />
                    </div>
                    <div className="ml-2 flex flex-col justify-between">
                      <div className="flex">{ ad.adProduct.title }</div>
                      <div className="flex">{ currencyFormatter(ad.adProduct.price, ad.adProduct.currencyCode) }</div>
                    </div>
                  </div>
                </div>
              </div>
            }
          </div>
          <div className="flex items-center mt-[30px]">
            <div className="w-[216px]">
              <Button large full style={{ padding: 0 }}>
                <a className="w-full h-full flex items-center justify-center" href={ad.downloadVideoUrl}>Download</a>
              </Button>
            </div>
            <div className="ml-6 text-gray text-sm leading-[15px] font-medium cursor-pointer" onClick={blockAdvertiser}>
              Block this advertiser
            </div>
          </div>
        </div>
        {!excludedOrigins.includes(ad.origin) && (
          <>
            <div className="flex-auto mt-[50px] text-xl font-medium">More Ad Text</div>
            <div className="flex-auto mt-4 bg-white rounded-lg shadow-[0_4px_50px_rgba(0,0,0,0.05)]">
              {renderSection("Video Text", ad.description)}
              {renderSection("Button List", ad.buttonList)}
              {renderSection("Title List", ad.titleList)}
              {renderSection("Text List", ad.textList)}
            </div>
          </>
        )}
      </div>
      {isModalOpen &&
        <AddToListing exceptSwipeIds={ad.advertiser?.swipesIds || []} onSubmit={onSubmitToSwipe} onClose={() => setModalOpen(false)} />
      }
    </div>
  )
}

export default React.memo(AnalysisTab)
