import { useState, useEffect, useRef } from 'react'
import { easepick } from '@easepick/core'
import { RangePlugin } from '@easepick/range-plugin'
import dayjs from 'dayjs'
import CollapseArrowIcon from '~/components/icons/CollapseArrow'
import CheckboxCheckedIcon from '~/components/icons/CheckboxChecked'

const DateFilter = props => {
  const nodeStartRef = useRef()
  const nodeEndRef = useRef()
  const [isOpen, setIsOpen] = useState(false)
  const [min, setMin] = useState(props.value?.min?.toString() || '')
  const [max, setMax] = useState(props.value?.max?.toString() || '')

  useEffect(() => {
    const picker = new easepick.create({
      element: nodeStartRef.current,
      css: ['https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css'],
      zIndex: 10,
      grid: 1,
      calendars: 1,
      plugins: [
        RangePlugin
      ],
      RangePlugin: {
        tooltip: true,
        elementEnd: nodeEndRef.current,
        startDate: min,
        endDate: max
      }
    })

    picker.on('select', e => {
      setMin(dayjs(e.detail.start).format('YYYY-MM-DD'))
      setMax(dayjs(e.detail.end).format('YYYY-MM-DD'))
    })

    return () => {
      picker.destroy()
    }
  }, [])

  useEffect(() => {
    props.onChange(props.id, { min, max })
  }, [min, max])

  const toggleOpen = () => {
    setIsOpen(prev => !prev)
  }

  const filterEnabled = !!(min.length || max.length)

  const onCheckboxClick = e => {
    e.stopPropagation()

    if (filterEnabled) {
      setMin('')
      setMax('')
      setIsOpen(false)
    }
  }

  return (
    <label htmlFor={`range-filters-main-${props.id}`} className="flex flex-col cursor-pointer">
      <div className="flex items-start py-4 px-4 transition-colors hover:bg-blue1/10" onClick={toggleOpen}>
        <div onClick={onCheckboxClick} className={`flex items-center justify-center w-5 h-5 border border-gray rounded-[3px] ${filterEnabled ? 'bg-blue1 border-none' : ''}`}>
          { filterEnabled && <div><CheckboxCheckedIcon /></div> }
        </div>
        <div className="flex flex-auto w-full leading-4 ml-2">
          <div className="flex flex-auto items-center text-sm font-medium select-none">
            { props.name }
          </div>
          <div className="flex items-center w-4">
            <CollapseArrowIcon className={`duration-250 transition-transform ${isOpen ? 'rotate-[-180deg]' : 'rotate-0'}`} />
          </div>
        </div>
      </div>
      <div className="flex flex-col flex-auto items-start justify-between ml-7 px-4">
        <div className={`flex flex w-full overflow-y-auto transition-[max-height] ease-[cubic-bezier(0,1,0,1)] duration-250 ${isOpen ? 'ease-in-out max-h-[300px]' : 'border-b border-b-blue1/20 max-h-0'}`}>
          <div className="mt-2 flex items-center py-2 px-4 border border-gray1">
            <div ref={nodeStartRef} className="">Date Start</div>
            <div className="mx-4">-</div>
            <div ref={nodeEndRef} className="">Date End</div>
          </div>
        </div>
      </div>
    </label>
  )
}

export default DateFilter
