import { useState, useEffect, useRef } from 'react'
import api from '~/api'
import s from './NewBriefDetail.module.scss'

const NewBriefDetail = props => {
  const newRef = useRef()
  const [newOpen, setNewOpen] = useState(false)

  useEffect(() => {
    const handleClickOutside = e => {
      if (newRef.current && !newRef.current.contains(e.target)) {
        setNewOpen(false)
      }
    }

    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  const onNew = () => {
    setNewOpen(true)
  }

  const onCreate = type => {
    api.apiBriefDetails.create({ data: { brief_id: props.brief.id, detail_type: type } }).then(data => {
      props.onAdd(data.briefDetail)
      setNewOpen(false)
    })
  }

  const filteredDetails = props.details.filter(d => !props.brief.details.some(bd => bd.detailType === d.type))

  if (!filteredDetails.length) return null

  return (
    <div className={s.NewBriefDetail}>
      <div onClick={onNew}>Add New &#x2795;</div>
      {newOpen &&
        <div ref={newRef} className={`flex flex-col ${s.dropdown}`}>
          {
            filteredDetails.map(detail => (
              <div key={detail.type} className="flex flex-col" onClick={() => onCreate(detail.type)}>
                <div className="flex">{ detail.label }</div>
                <div className="flex text-xs text-gray">{ detail.description }</div>
              </div>
            ))
          }
        </div>
      }
    </div>
  )
}

export default NewBriefDetail
