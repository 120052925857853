const NewInspiration = () => (
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none">
    <circle cx="50" cy="50" r="50" fill="#3F71EF" fillOpacity="0.1" />
    <path d="M37.3271 31.3555H35.294C35.3875 31.5847 35.4232 31.8334 35.398 32.0796C35.3727 32.3259 35.2873 32.5622 35.1492 32.7676C35.0111 32.9731 34.8246 33.1414 34.6062 33.2578C34.3877 33.3742 34.1439 33.4351 33.8964 33.4352H24.9739C24.7263 33.4351 24.4826 33.3742 24.2641 33.2578C24.0456 33.1414 23.8591 32.9731 23.7211 32.7676C23.583 32.5622 23.4976 32.3259 23.4723 32.0796C23.447 31.8334 23.4827 31.5847 23.5762 31.3555H21.6774C20.8347 31.3555 20.0265 31.6902 19.4306 32.2861C18.8348 32.882 18.5 33.6902 18.5 34.5328V74.8033C18.5 75.646 18.8348 76.4542 19.4306 77.0501C20.0265 77.646 20.8347 77.9807 21.6774 77.9807H37.3271C38.1697 77.9807 38.9779 77.646 39.5738 77.0501C40.1697 76.4542 40.5044 75.646 40.5044 74.8033V34.5328C40.5044 33.6902 40.1697 32.882 39.5738 32.2861C38.9779 31.6902 38.1697 31.3555 37.3271 31.3555Z" fill="white" />
    <path d="M35.2486 64.3227H23.7802C23.4153 64.3227 23.0652 64.1777 22.8071 63.9196C22.549 63.6615 22.4041 63.3115 22.4041 62.9465C22.4041 62.5815 22.549 62.2315 22.8071 61.9734C23.0652 61.7153 23.4153 61.5703 23.7802 61.5703H35.2486C35.6133 61.5706 35.963 61.7158 36.2209 61.9738C36.4787 62.2319 36.6235 62.5817 36.6235 62.9465C36.6235 63.3113 36.4787 63.6611 36.2209 63.9192C35.963 64.1772 35.6133 64.3224 35.2486 64.3227Z" fill="#ECF1FE" />
    <path d="M35.2486 68.5668H23.7803C23.5995 68.567 23.4204 68.5315 23.2533 68.4625C23.0862 68.3934 22.9343 68.292 22.8064 68.1642C22.6784 68.0364 22.577 67.8846 22.5077 67.7176C22.4385 67.5505 22.4028 67.3715 22.4028 67.1907C22.4028 67.0098 22.4385 66.8308 22.5077 66.6637C22.577 66.4967 22.6784 66.3449 22.8064 66.2171C22.9343 66.0893 23.0862 65.9879 23.2533 65.9188C23.4204 65.8498 23.5995 65.8143 23.7803 65.8145H35.2486C35.6136 65.8145 35.9636 65.9594 36.2217 66.2175C36.4798 66.4756 36.6248 66.8257 36.6248 67.1907C36.6248 67.5556 36.4798 67.9057 36.2217 68.1638C35.9636 68.4219 35.6136 68.5668 35.2486 68.5668Z" fill="#ECF1FE" />
    <path d="M78.5153 31.3555H76.4823C76.5758 31.5847 76.6115 31.8334 76.5862 32.0796C76.5609 32.3259 76.4755 32.5622 76.3374 32.7676C76.1993 32.9731 76.0129 33.1414 75.7944 33.2578C75.5759 33.3742 75.3322 33.4351 75.0846 33.4352H66.1621C65.9145 33.4351 65.6708 33.3742 65.4523 33.2578C65.2339 33.1414 65.0474 32.9731 64.9093 32.7676C64.7712 32.5622 64.6858 32.3259 64.6605 32.0796C64.6353 31.8334 64.671 31.5847 64.7644 31.3555H62.8656C62.0229 31.3555 61.2147 31.6902 60.6189 32.2861C60.023 32.882 59.6882 33.6902 59.6882 34.5328V74.8033C59.6882 75.646 60.023 76.4542 60.6189 77.0501C61.2147 77.646 62.0229 77.9807 62.8656 77.9807H78.5153C79.358 77.9807 80.1662 77.646 80.762 77.0501C81.3579 76.4542 81.6927 75.646 81.6927 74.8033V34.5328C81.6927 33.6902 81.3579 32.882 80.762 32.2861C80.1662 31.6902 79.358 31.3555 78.5153 31.3555Z" fill="white" />
    <path d="M76.4365 64.3227H64.9682C64.6032 64.3227 64.2532 64.1777 63.9951 63.9196C63.737 63.6615 63.592 63.3115 63.592 62.9465C63.592 62.5815 63.737 62.2315 63.9951 61.9734C64.2532 61.7153 64.6032 61.5703 64.9682 61.5703H76.4365C76.8013 61.5706 77.151 61.7158 77.4088 61.9738C77.6667 62.2319 77.8115 62.5817 77.8115 62.9465C77.8115 63.3113 77.6667 63.6611 77.4088 63.9192C77.151 64.1772 76.8013 64.3224 76.4365 64.3227Z" fill="#ECF1FE" />
    <path d="M76.4368 68.5668H64.9685C64.7877 68.567 64.6086 68.5315 64.4415 68.4625C64.2744 68.3934 64.1225 68.292 63.9946 68.1642C63.8667 68.0364 63.7652 67.8846 63.6959 67.7176C63.6267 67.5505 63.5911 67.3715 63.5911 67.1907C63.5911 67.0098 63.6267 66.8308 63.6959 66.6637C63.7652 66.4967 63.8667 66.3449 63.9946 66.2171C64.1225 66.0893 64.2744 65.9879 64.4415 65.9188C64.6086 65.8498 64.7877 65.8143 64.9685 65.8145H76.4368C76.8018 65.8145 77.1519 65.9594 77.41 66.2175C77.668 66.4756 77.813 66.8257 77.813 67.1907C77.813 67.5556 77.668 67.9057 77.41 68.1638C77.1519 68.4219 76.8018 68.5668 76.4368 68.5668Z" fill="#ECF1FE" />
    <path d="M59.4387 78H40.4253C39.355 77.9988 38.3289 77.5731 37.5721 76.8163C36.8153 76.0595 36.3896 75.0334 36.3884 73.9631V25.0369C36.3896 23.9666 36.8153 22.9405 37.5721 22.1837C38.3289 21.4269 39.355 21.0012 40.4253 21H42.9949L42.8957 21.2432C42.7931 21.4949 42.7539 21.7679 42.7816 22.0383C42.8093 22.3087 42.9031 22.5681 43.0547 22.7937C43.2063 23.0193 43.4111 23.2042 43.651 23.332C43.8908 23.4599 44.1585 23.5267 44.4303 23.5267H55.2706C55.5424 23.5267 55.81 23.4598 56.0499 23.332C56.2898 23.2042 56.4946 23.0193 56.6462 22.7937C56.7978 22.5681 56.8916 22.3087 56.9194 22.0383C56.9471 21.7679 56.9079 21.4949 56.8053 21.2432L56.7061 21H59.4387C60.509 21.0012 61.5351 21.4269 62.2919 22.1837C63.0487 22.9405 63.4744 23.9666 63.4756 25.0369V73.9631C63.4744 75.0334 63.0487 76.0595 62.2919 76.8163C61.5351 77.5731 60.509 77.9988 59.4387 78Z" fill="white" />
    <path d="M59.4387 78H40.4253C39.355 77.9988 38.3289 77.5731 37.5721 76.8163C36.8153 76.0595 36.3896 75.0334 36.3884 73.9631V25.0369C36.3896 23.9666 36.8153 22.9405 37.5721 22.1837C38.3289 21.4269 39.355 21.0012 40.4253 21H42.9949L42.8957 21.2432C42.7931 21.4949 42.7539 21.7679 42.7816 22.0383C42.8093 22.3087 42.9031 22.5681 43.0547 22.7937C43.2063 23.0193 43.4111 23.2042 43.651 23.332C43.8908 23.4599 44.1585 23.5267 44.4303 23.5267H55.2706C55.5424 23.5267 55.81 23.4598 56.0499 23.332C56.2898 23.2042 56.4946 23.0193 56.6462 22.7937C56.7978 22.5681 56.8916 22.3087 56.9194 22.0383C56.9471 21.7679 56.9079 21.4949 56.8053 21.2432L56.7061 21H59.4387C60.509 21.0012 61.5351 21.4269 62.2919 22.1837C63.0487 22.9405 63.4744 23.9666 63.4756 25.0369V73.9631C63.4744 75.0334 63.0487 76.0595 62.2919 76.8163C61.5351 77.5731 60.509 77.9988 59.4387 78ZM40.4253 21.353C39.4486 21.3542 38.5122 21.7426 37.8216 22.4332C37.131 23.1239 36.7426 24.0602 36.7415 25.0369V73.9631C36.7426 74.9398 37.131 75.8761 37.8216 76.5668C38.5122 77.2574 39.4486 77.6458 40.4253 77.647H59.4387C60.4154 77.6458 61.3517 77.2574 62.0424 76.5668C62.733 75.8761 63.1214 74.9398 63.1226 73.9631V25.0369C63.1214 24.0602 62.733 23.1239 62.0424 22.4332C61.3517 21.7426 60.4154 21.3542 59.4387 21.353H57.214C57.2931 21.6508 57.3026 21.9627 57.242 22.2647C57.1814 22.5667 57.0522 22.8507 56.8644 23.0949C56.6766 23.339 56.4352 23.5368 56.1589 23.6729C55.8825 23.809 55.5786 23.8798 55.2706 23.8798H44.4303C44.1223 23.8797 43.8185 23.8088 43.5422 23.6727C43.2659 23.5366 43.0246 23.3388 42.8368 23.0947C42.6491 22.8505 42.5199 22.5665 42.4593 22.2646C42.3986 21.9626 42.4082 21.6507 42.4871 21.353L40.4253 21.353Z" fill="#080D1A" />
    <path d="M57.4279 59.6096H43.4946C43.0514 59.6093 42.6265 59.4329 42.3132 59.1194C41.9999 58.8059 41.824 58.3808 41.824 57.9376C41.824 57.4944 41.9999 57.0694 42.3132 56.7558C42.6265 56.4423 43.0514 56.266 43.4946 56.2656H57.4279C57.6476 56.2654 57.8652 56.3086 58.0682 56.3925C58.2712 56.4764 58.4557 56.5996 58.6111 56.7548C58.7665 56.9101 58.8898 57.0945 58.9739 57.2975C59.058 57.5004 59.1013 57.7179 59.1013 57.9376C59.1013 58.1573 59.058 58.3749 58.9739 58.5778C58.8898 58.7807 58.7665 58.9651 58.6111 59.1204C58.4557 59.2757 58.2712 59.3988 58.0682 59.4828C57.8652 59.5667 57.6476 59.6098 57.4279 59.6096Z" fill="#6C63FF" />
    <path d="M57.4279 64.7659H43.4946C43.0514 64.7655 42.6265 64.5892 42.3132 64.2757C41.9999 63.9621 41.824 63.5371 41.824 63.0939C41.824 62.6507 41.9999 62.2256 42.3132 61.9121C42.6265 61.5986 43.0514 61.4222 43.4946 61.4219H57.4279C57.6476 61.4217 57.8652 61.4648 58.0682 61.5488C58.2712 61.6327 58.4557 61.7558 58.6111 61.9111C58.7665 62.0664 58.8898 62.2508 58.9739 62.4537C59.058 62.6567 59.1013 62.8742 59.1013 63.0939C59.1013 63.3136 59.058 63.5311 58.9739 63.7341C58.8898 63.937 58.7665 64.1214 58.6111 64.2767C58.4557 64.4319 58.2712 64.5551 58.0682 64.639C57.8652 64.723 57.6476 64.7661 57.4279 64.7659Z" fill="#6C63FF" />
    <path d="M49.9735 72.0784C50.8969 72.0784 51.6455 71.3298 51.6455 70.4064C51.6455 69.483 50.8969 68.7344 49.9735 68.7344C49.0501 68.7344 48.3015 69.483 48.3015 70.4064C48.3015 71.3298 49.0501 72.0784 49.9735 72.0784Z" fill="#080D1A" />
    <path d="M58.1668 70.4071C57.3754 70.1137 56.3936 69.6127 55.7849 69.082L56.2139 70.2677H53.1782V70.5464H56.2139L55.7849 71.7324C56.3936 71.2017 57.3754 70.7007 58.1668 70.4071Z" fill="#080D1A" />
    <path d="M41.7803 70.4071C42.5716 70.1137 43.5535 69.6127 44.1621 69.082L43.7332 70.2677H46.7689V70.5464H43.7332L44.1621 71.7324C43.5535 71.2017 42.5716 70.7007 41.7803 70.4071Z" fill="#080D1A" />
  </svg>
)

export default NewInspiration
