import React, { useState, useEffect, useCallback } from 'react'
import { useSearchParams, useLocation } from 'react-router-dom'
import api from '~/api'
import NotificationManager from '~/services/NotificationManager'
import Drawer from '~/components/layout/Drawer'
import Tabs from '~/components/layout/Tabs'
import AddToListing from '~/components/Research/AddToListing'
import AdsComponent from '~/components/Ads/Ads'
import Search from '~/components/Ads/Search'
import AdvancedSearch from '~/components/Ads/AdvancedSearch'
import AdsList from '~/components/Ads/AdsList'
import Filters from '~/components/AdProducts/Filters'
import AdProductCard from '~/components/AdProducts/AdProductCard'
import ShopifyIcon from '~/components/icons/Shopify'
import AmazonIcon from '~/components/icons/Amazon'
import EbayIcon from '~/components/icons/Ebay'
import WalmartIcon from '~/components/icons/Walmart'
import AliexpressIcon from '~/components/icons/Aliexpress'
import EtsyIcon from '~/components/icons/Etsy'

const searchTypeOptions = [
  { id: 'title', label: 'Name' },
  { id: 'keyword', label: 'Keyword' },
  { id: 'description', label: 'Description' },
  { id: 'link', label: 'Link' },
  { id: 'advertiser', label: 'Advertiser' }
]

const tabsOptions = [
  { value: 'all', label: 'All Platforms' },
  { value: 'shopify', label: <ShopifyIcon /> },
  { value: 'walmart', label: <WalmartIcon /> },
  { value: 'amazon', label: <AmazonIcon /> },
  { value: 'aliexpress', label: <AliexpressIcon /> },
  { value: 'etsy', label: <EtsyIcon /> },
  { value: 'ebay', label: <EbayIcon /> },
]

const emptyObj = {}

const AdProducts = () => {
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const [data, setData] = useState({ adProducts: [], meta: {} })
  const [currentPage, setCurrentPage] = useState(searchParams.get('page') || 1)
  const [isModalOpen, setModalOpen] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)
  const [isFiltersOpen, setFiltersOpen] = useState(false)

  const defaultFilters = JSON.parse(searchParams.get('filters')) || emptyObj
  const [filters, setFilters] = useState(defaultFilters)
  const [activeTab, setActiveTab] = useState(filters.platform?.[0] || 'all')

  const defaultSearchFilters = JSON.parse(searchParams.get('search')) || emptyObj
  const [searchFilters, setSearchFilters] = useState(defaultSearchFilters)

  useEffect(() => {
    if (!location.search.length) {
      setCurrentPage(1)
      setSearchFilters(emptyObj)
      setFilters(emptyObj)
    }
  }, [location.search])

  useEffect(() => {
    const queryParams = { page: currentPage }

    if (filters && Object.keys(filters).length) {
      queryParams.filters = JSON.stringify(filters)
    }

    if (searchFilters?.value) {
      queryParams.search = JSON.stringify(searchFilters)
    }

    if (Object.keys(queryParams).length) {
      setSearchParams(queryParams)
    }

    api.apiAdProducts.list({ query: queryParams }).then(response => {
      setData(response)
    })
  }, [currentPage, filters, searchFilters])

  const showFilters = useCallback(() => {
    setFiltersOpen(true)
  }, [setFiltersOpen])

  const onFiltersChange = value => {
    setFilters(value)
    setCurrentPage(1)
    setFiltersOpen(false)
  }

  useEffect(() => {
    setFilters(prevFilters => ({...prevFilters, platform: activeTab != 'all' ? [activeTab] : undefined}))
  }, [activeTab])

  const onFiltersCancel = () => {
    setFilters({})
    setCurrentPage(1)
    setFiltersOpen(false)
    setActiveTab('all')
  }

  const onPageChange = ({ selected }) => {
    setCurrentPage(selected + 1)
  }

  const onSearch = useCallback(value => {
    setSearchFilters(value)
    setCurrentPage(1)
  }, [setSearchFilters, setCurrentPage])

  const addToSwipe = id => {
    setSelectedProduct(id)
    setModalOpen(true)
  }

  const onModalClose = () => {
    setModalOpen(false)
    setSelectedProduct(null)
  }

  const onSubmitToSwipe = swipeId => {
    api.apiSwipes.addProducts({ data: {
      id: swipeId,
      product_type: 'AdProduct',
      ad_product_id: selectedProduct
    } }).then(() => {
      setData(prev => ({
        ...prev,
        adProducts: prev.adProducts.map(product => (
          product.id === selectedProduct
          ? { ...product, swipesIds: [...product.swipesIds, swipeId] }
          : product
        ))
      }))
      setModalOpen(false)
      NotificationManager.success({ boldText: 'Product', text: 'has been successfully added to swipe' })
    })
  }

  return (
    <AdsComponent>
      <div className="mt-[22px] flex text-8">Products</div>
      <div className="w-full flex flex-wrap mt-6">
        <Search
          searchTypeOptions={searchTypeOptions}
          searchValue={searchFilters?.value}
          searchType={searchFilters?.type}
          onSearch={onSearch}
        />
      </div>
      <AdvancedSearch
        showFilters={showFilters}
      />
      <div className="flex mt-[60px] mb-6">
        <Tabs options={tabsOptions} tab={activeTab} onChange={setActiveTab} />
      </div>
      <AdsList
        pagesCount={data.meta.pagesCount}
        currentPage={currentPage}
        onPageChange={onPageChange}
      >
        {
          data.adProducts.map(product => (
            <AdProductCard
              key={product.id}
              product={product}
              isFavourite={!!product.swipesIds?.length}
              addToSwipe={addToSwipe}
            />
          ))
        }
      </AdsList>
      <Drawer isOpen={isFiltersOpen} setIsOpen={setFiltersOpen}>
        <Filters filters={filters} onChange={onFiltersChange} onCancel={onFiltersCancel} onClose={() => setFiltersOpen(false)}/>
      </Drawer>
      {isModalOpen &&
        <AddToListing onSubmit={onSubmitToSwipe} onClose={onModalClose} />
      }
    </AdsComponent>
  )
}

export default React.memo(AdProducts)
