const values = [
  { value: 'instagram', label: 'Instagram' },
  { value: 'facebook', label: 'Facebook' },
  { value: 'linkedin', label: 'LinkedIn' },
  { value: 'tiktok', label: 'TikTok' },
  { value: 'youtube', label: 'YouTube' }
]

const Platforms = props => {
  const isSelected = val => (
    props.value.includes(val)
  )

  const onSelect = value => {
    if (isSelected(value)) {
      props.onChange(props.value.filter(v => v !== value))
    } else {
      props.onChange([...props.value, value])
    }
  }

  return (
    <div className="p-4 flex items-center">
      {
        values.map(v => (
          <div
            key={v.value}
            style={{ background: isSelected(v.value) ? 'rgba(86, 147, 245, 0.3)' : 'transparent' }}
            className="mr-4 py-2 px-4 rounded-full border border-gray/20 text-sm cursor-pointer"
            onClick={() => onSelect(v.value)}
          >
            { v.label }
          </div>
        ))
      }
    </div>
  )
}

export default Platforms
