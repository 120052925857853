const values = [
  { value: '16:9', label: '16:9 (1920 x 1080)' },
  { value: '9:16', label: '9:16 (1080 x 1920)' },
  { value: '1:1', label: '1:1 (1080 x 1080)' },
  { value: '4:5', label: '4:5 (1080 x 1350)' },
  { value: '2:3', label: '2:3 (1080 x 1620)' }
]

const Size = props => {
  const isSelected = val => (
    props.value.includes(val)
  )

  const onSelect = value => {
    if (isSelected(value)) {
      props.onChange(props.value.filter(v => v !== value))
    } else {
      props.onChange([...props.value, value])
    }
  }

  return (
    <div className="p-4 flex items-center">
      {
        values.map(v => (
          <div
            key={v.value}
            style={{ background: isSelected(v.value) ? 'rgba(86, 147, 245, 0.3)' : 'transparent' }}
            className="mr-4 py-2 px-4 rounded-full border border-gray/20 text-sm cursor-pointer"
            onClick={() => onSelect(v.value)}
          >
            { v.label }
          </div>
        ))
      }
    </div>
  )
}

export default Size
