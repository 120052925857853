import React, { useState, useEffect, useRef } from 'react'
import api from '~/api'
import consumer from '~/channels/consumer'
import { deepConvertKeys, camelCase } from '@js-from-routes/core'
import Gravatar from 'react-gravatar'
import { formatDistanceToNow } from 'date-fns';
import Select from '~/components/layout/Select'
import Button from '~/components/layout/Button'
import Input from '~/components/layout/Input'
import NotificationManager from '../services/NotificationManager'
import { useGetUserQuery } from '../store/api/userApiSlice'

const AICopy = (props) => {
  const [user, setUser] = useState()
  const { data, status } = useGetUserQuery()
  useEffect(() => {
    if(status === "fulfilled") setUser(data?.user)
  }, [status])
  const [name, setName] = useState('')
  const [templates, setTemplates] = useState([])
  const [options, setOptions] = useState([])
  const [prompt, setPrompt] = useState('')
  const [loading, setLoading] = useState(false)
  const [disableButton, setDisableButton] = useState(true)
  const [chats, setChats] = useState([])
  const [activeChat, setActiveChat] = useState(null)
  const [messages, setMessages] = useState([])
  const activeChatRef = useRef()

  const onNameChange = e => {
    setName(e.target.value)
  }

  useEffect(() => {
    api.apiAiPromptTemplates.list().then(data => setTemplates(data.map(d => d.template)))
    api.apiAiChat.list().then(data => setChats(data.aiChats))
  }, [])

  useEffect(() => {
    if(!name) {
      setDisableButton(true);
      return;
    }
    else {
      setPrompt(templates[0]?.replace(/{product}/g,name))
      setOptions(templates.map(t => {return {value: t.replace(/{product}/g,name), label: t.replace(/{product}/g,name)}}))
      setDisableButton(false);
    }
  }, [name])

  useEffect(() => {
    setPrompt(templates[0])
    setOptions(templates.map(t => {return {value: t, label: t}}))
  }, [templates])

  const submit = () => {
    if(disableButton || loading) return;

    api.apiAiChat.create({ data: { product_title: name, prompt: prompt }}).then(res => {
      setActiveChat(res)
      setLoading(true)
    })
  }

  useEffect(() => {
    if(!user) return;
    const subscription = consumer.subscriptions.create({ channel: 'ChatChannel', id: user.id }, {
      received(message) {
        const data = deepConvertKeys(message, camelCase);
        setMessages(prevMessages => [...prevMessages, data]);
      }
    })
    return () => {
      subscription.unsubscribe()
    }
  }, [user])

  useEffect(() => {
    const sortedMessages = [...messages].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

    let message = ''
    sortedMessages.forEach(data => {
      switch (data.event) {
        case 'success':
          setChats(prevChats => [...prevChats, {...activeChat, aiResponse: data.response}]);
          setActiveChat(null);
          setLoading(false);
          message = '';
          break;
        case 'message':
          message += data.newText;
          break;
        case "notification":
          setChats(prevChats => [...prevChats, activeChat]);
          setActiveChat(null);
          setLoading(false);
          message = '';
          NotificationManager.message({text: data.message, time: 10000})
        default:
          break;
      }
    });
    if (!message) return;
    setActiveChat(prevState => ({ ...prevState, aiResponse: message}))
  }, [messages]);

  useEffect(() => {
    if(!activeChat){
      setMessages([]);
    }
    else{
      activeChatRef.current?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
    }
  }, [activeChat])

  const handlePromptSelection = e => {
    setPrompt(e.target.value);
  }

  return (
    <div className="flex flex-col w-full h-full relative">
      <div className="flex items-center text-8 px-[60px] w-full h-[80px] fixed top-0 shadow-sm bg-white">AI Copy</div>
      <div className="flex w-full h-full pt-[80px]">
        <div className="w-1/3 h-full p-8 bg-white">
          <div className="flex flex-col justify-between h-full">
            <div>
              <label htmlFor="name" className="">Product/Service Name</label>
              <div className="my-2">
                <Input type="text" value={name} onChange={onNameChange} placeholder="Enter Product Name" className="block w-full rounded-md border-0 py-1.5 px-2 text-secondary shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-indigo-500" />
              </div>
              <p className="mb-2 mt-8">Select any of these prompt.</p>
              <div className="my-2">
                <Select options={options} onChange={handlePromptSelection} value={prompt} className="block w-full rounded-md border-0 py-1.5 px-2 text-secondary shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-indigo-500" />
              </div>
            </div>

            <div onClick={submit} className={`${disableButton || loading ? 'cursor-not-allowed' : 'cursor-auto'}`}>
              <Button className={`btn !block w-full !disabled:text-opacity-25`} disabled={disableButton || loading} full>Generate</Button>
            </div>
          </div>
        </div>

        <div className="w-2/3 h-full p-8 whitespace-pre-wrap overflow-y-scroll">
          {
            chats && chats.length > 0 &&
            chats.map(chat => (
              <div className="mb-5" key={chat.id}>
                <div className="w-full"><span className="capitalize text-xs text-gray-600">{formatDistanceToNow(new Date(chat?.createdAt), { addSuffix: true })}</span></div>
                <div className="w-full flex items-start justify-start my-2 gap-4">
                  <Gravatar size={27} className="rounded-full" email={user?.email} />
                  <div className="flex-1 text-lg font-bold text-gray-700">{chat.userMessage}</div>
                </div>
                <div className={`bg-white rounded-xl p-5 text-lg text-gray-800 min-h-[60px] shadow-sm`}>{chat.aiResponse}</div>
              </div>
            ))
          }
          {activeChat && (
            <div className="" ref={activeChatRef}>
              <div className="w-full"><span className="capitalize text-xs text-gray-600">{activeChat.createdAt ? formatDistanceToNow(new Date(activeChat.createdAt), { addSuffix: true }) : ''}</span></div>
              <div className="w-full flex items-start justify-start my-2 gap-4">
                <Gravatar size={27} className="rounded-full" email={user?.email} />
                <div className="flex-1 text-lg font-bold text-gray-7000">{activeChat.userMessage}</div>
              </div>
              <div className={`bg-white rounded-xl p-5 text-lg text-gray-700 min-h-[60px] shadow-sm ${activeChat.aiResponse ? '' : 'animate-pulse'}`}>{activeChat.aiResponse || (<div className="h-[40px] w-full bg-slate-200 dark:bg-slate-700 rounded"></div>)}</div>
            </div>
          )}
          {!(chats && chats.length > 0) && !activeChat && (
            <div className="w-full h-full flex items-center justify-center">
              AI Chat will appear here ...
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default React.memo(AICopy)
