import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'
import api from '~/api'
import AdsComponent from '~/components/Ads/Ads'
import AdvertiserTable from '~/components/Advertisers/Table'

const Advertisers = () => {
  const [loading, setLoading] = useState(true)
  const [advertisers, setAdvertisers] = useState([])

  useEffect(() => {
    api.apiAdvertisers.list().then(data => {
      setAdvertisers(data.advertisers)
      setLoading(false)
    })
  }, [])

  console.log('advertisers', advertisers)

  return (
    <AdsComponent>
      <div className="w-full flex flex-col">
        <div className="mt-[22px] flex text-8">Advertisers</div>
        <div className="mt-12 flex flex-col">
          <AdvertiserTable advertisers={advertisers} />
        </div>
      </div>
    </AdsComponent>
  )
}

export default React.memo(Advertisers)
