import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import api from '~/api'
import NotificationManager from '~/services/NotificationManager'
import Card from '~/components/Trending/Card'
import AddToListing from '~/components/Research/AddToListing'
import Block from './Block'
import Carousel from './Carousel'

const itemWidth = 287

const TopSeasonalDropshipProducts = () => {
  const [products, setProducts] = useState([])
  const navigate = useNavigate()
  const [isModalOpen, setModalOpen] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)

  useEffect(() => {
    api.apiDashboard.seasonalTrending({ query: { limit: 20 } }).then(data => setProducts(data.trendingProducts))
  }, [])

  const onProductClick = productId => {
    const product = products.find(p => p.productId === productId)
    navigate(`/trending/${product.marketplace}?productId=${product.productId}`)
  }

  const addToSwipe = id => {
    setSelectedProduct(id)
    setModalOpen(true)
  }

  const onModalClose = () => {
    setModalOpen(false)
    setSelectedProduct(null)
  }

  const onSubmitToSwipe = (swipeId, successFn) => {
    api.apiSwipes.addProducts({ data: {
      id: swipeId,
      product_type: 'TrendingProduct',
      product_ids: [selectedProduct]
    } }).then(() => {
      if (typeof successFn === 'function') successFn()
      onModalClose()
      NotificationManager.success({ boldText: 'Trending Product', text: 'has been successfully added to swipe' })

      setProducts(prev => prev.map(p => (
        p.productId === selectedProduct
        ? { ...p, swipesIds: [...p.swipesIds, Number(swipeId)] }
        : p
      )))
    })
  }

  return (
    <Block title="Top Seasonal Dropshipped Products">
      <Carousel itemWidth={itemWidth + 30}>
        {
          products.map((p, index) => (
            <div style={{ width: `${itemWidth}px`, minWidth: `${itemWidth}px` }} className="mr-[30px] last:mr-0" key={index}>
              <Card
                onClick={onProductClick}
                productId={p.productId}
                imageSrc={p.image}
                title={p.title}
                updatedAt={dayjs(p.updatedAt).fromNow()}
                currentOrdersCount={p.currentOrdersCount}
                ordersCount={p.ordersCount}
                growth={p.growth}
                isFavourite={!!p.swipesIds.length}
                addToSwipe={addToSwipe}
              />
            </div>
          ))
        }
      </Carousel>
      {isModalOpen &&
        <AddToListing selectedIds={[selectedProduct]} onSubmit={onSubmitToSwipe} onClose={onModalClose} />
      }
    </Block>
  )
}

export default TopSeasonalDropshipProducts
