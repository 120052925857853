import React, { useState } from 'react'
import Modal from '~/components/layout/Modal'
import Button from '~/components/layout/Button'
import Input from '~/components/layout/Input'
import Select from '~/components/layout/Select'

const NewSwipe = props => {
  const [groupId, setGroupId] = useState(props.groups[0].id)
  const [name, setName] = useState('')
  const [keyword, setKeyword] = useState('')

  const onGroupChange = e => {
    setGroupId(Number(e.target.value))
  }

  const onNameChange = e => {
    setName(e.target.value)
  }

  const onKeywordChange = e => {
    setKeyword(e.target.value)
  }

  const createSwipe = () => {
    props.onSubmit(groupId, name, keyword).then(() => {
      props.onClose()
    })
  }

  const groupsOptions = props.groups.map(group => ({ label: group.name, value: group.id }))

  return (
    <Modal isOpen onClose={props.onClose} headerText="Create New Swipe">
      <div className="flex flex-col">
        <div className="flex text-secondary">Swipe Folder</div>
        <div className="flex mt-2">
          <Select options={groupsOptions} value={groupId} onChange={onGroupChange} />
        </div>
        <div className="flex text-secondary">Name of Swipe</div>
        <div className="flex mt-2">
          <Input value={name} onChange={onNameChange} placeholder="Swipe Name" />
        </div>
        <div className="flex text-secondary">Keyword</div>
        <div className="flex mt-2">
          <Input value={keyword} onChange={onKeywordChange} placeholder="Keyword" />
        </div>
        <div className="flex flex-1 mt-6">
          <div className="flex flex-1">
            <Button full secondary onClick={props.onClose}>Cancel</Button>
          </div>
          <div className="flex flex-1 ml-6" onClick={createSwipe}>
            <Button full>Submit</Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default React.memo(NewSwipe)
