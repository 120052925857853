import { useState, useEffect, useRef } from 'react'
import { Outlet, useMatch, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { setUpdateSwipe, selectUpdateSwipe } from '~/store/reducers/updateSwipeSlice';
// import cn from 'classnames'
import api from '~/api'
import PlusIcon from '~/components/icons/Plus'
import FolderIcon from '~/components/icons/Folder'
import SwipeIcon from '~/components/icons/Swipe'
import SwipeGroup from './SwipeGroup'
import Swipe from './Swipe'
import NewSwipeGroup from './NewSwipeGroup'
import NewSwipe from './NewSwipe'
import s from './Swipes.module.css'

const Swipes = () => {
  const newRef = useRef()
  const navigate = useNavigate()
  const [newOpened, setNewOpened] = useState(false)
  const [swipeGroups, setSwipeGroups] = useState([])
  const [isNewSwipe, setIsNewSwipe] = useState(false)
  const [isNewFolder, setIsNewFolder] = useState(false)
  const dispatch = useDispatch();
  const updateSwipe = useSelector(selectUpdateSwipe);

  const match = useMatch('/swipes/:id')
  const activeSwipeId = Number(match?.params?.id)

  // const classes = cn(s.root, s[variant], s[color], s[size], {
  //   [s.fullWidth]: fullWidth,
  // })

  const fetchData = async () => {
    api.apiSwipeGroups.list().then(data => {
      setSwipeGroups(data.swipeGroups)
    })
  }

  useEffect(() => {
    fetchData();
  }, [])

  useEffect(() => {
    if (updateSwipe) {
      fetchData();
      dispatch(setUpdateSwipe(false))
    }
  }, [updateSwipe])

  useEffect(() => {
    const handleClickOutside = e => {
      if (newRef.current && !newRef.current.contains(e.target)) {
        setNewOpened(false)
      }
    }

    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  const onSwipeGroupDelete = id => {
    api.apiSwipeGroups.destroy({id}).then(() => fetchData())
  }

  const onAddClick = () => {
    setNewOpened(prev => !prev)
  }

  const onSwipeCreate = (groupId, name, keyword) => (
    api.apiSwipes.create({ data: { swipe_group_id: groupId, swipe: { name, trigger_keyword: keyword } } }).then(data => {
      const newSwipe = data.swipe
      setSwipeGroups(prev => prev.map(group => (
        group.id === groupId
        ? { ...group, swipes: [...group.swipes, newSwipe] }
        : group
      )))
      setNewOpened(false)
      navigate(`/swipes/${newSwipe.id}`)
    })
  )

  const onNewFolder = () => {
    setIsNewFolder(true)
  }

  const onSwipeGroupCreate = groupName => (
    api.apiSwipeGroups.create({ data: { name: groupName } }).then(data => {
      setSwipeGroups(prev => [...prev, data.swipeGroup])
      setNewOpened(false)
    })
  )

  const onNewSwipe = () => {
    setIsNewSwipe(true)
  }

  return (
    <div className={s.swipes}>
      <div className={s.list}>
        <div className={s.label}>Swipes</div>
        <div className={s.addNew} onClick={onAddClick}><PlusIcon /></div>
        {newOpened &&
          <div ref={newRef} className={s.newPopup}>
            <div className="flex items-center" onClick={onNewFolder}>
              <FolderIcon />
              <span className="ml-2">Create Folder</span>
            </div>
            <div className="mt-3 flex items-center" onClick={onNewSwipe}>
              <SwipeIcon />
              <span className="ml-2">Create Swipe</span>
            </div>
          </div>
        }
      </div>
      <ul className="flex-auto overflow-auto mt-[10px]">
        {
          swipeGroups.map(group => (
            <li key={group.id} className="text-sm cursor-pointer">
              <SwipeGroup isActive={group.swipes.some(swipe => swipe.id === activeSwipeId)} name={group.name} onDelete={() => onSwipeGroupDelete(group.id)}>
                {
                  group.swipes.map(swipe => (
                    <Swipe key={swipe.id} id={swipe.id} name={swipe.name} />
                  ))
                }
              </SwipeGroup>
            </li>
          ))
        }
      </ul>
      { isNewSwipe && ( swipeGroups.length > 0 ? <NewSwipe groups={swipeGroups} onClose={() => setIsNewSwipe(false)} onSubmit={onSwipeCreate} /> : <NewSwipeGroup onClose={() => setIsNewFolder(false)} onSubmit={onSwipeGroupCreate} />) }
      { isNewFolder && <NewSwipeGroup onClose={() => setIsNewFolder(false)} onSubmit={onSwipeGroupCreate} /> }
      <Outlet />
    </div>
  )
}

export default Swipes
