import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import api from '~/api'
import AdvertiserTable from '~/components/Advertisers/Table'
import Block from './Block'

const TopTrendingProducts = () => {
  const [advertisers, setAdvertisers] = useState([])
  const navigate = useNavigate()

  const onViewAll = () => {
    navigate('/advertisers')
  }

  useEffect(() => {
    api.apiDashboard.advertisers({ query: { limit: 5 } }).then(data => setAdvertisers(data.advertisers))
  }, [])

  return (
    <Block title="Top 5 Advertisers" onViewAll={onViewAll}>
      <div className="mt-5 w-full px-12">
        <AdvertiserTable advertisers={advertisers} />
      </div>
    </Block>
  )
}

export default TopTrendingProducts
