// import EditableField from '~/components/Briefs/EditableField'
import TextField from '~/components/Briefs/form/TextField'
import DropdownField from '~/components/Briefs/form/DropdownField'

const Multiple = props => {
  const onUpdate = (value, index) => {
    const newColors = props.value.map((color, i) => (
      i === index ? value : color
    ))
    return props.onUpdate(newColors, props.field)
  }

  const addNew = () => {
    props.onUpdate([...props.value, props.defaultValue || ''], props.field)
  }

  const removeColor = color => {
    props.onUpdate(props.value.filter(c => c !== color), props.field)
  }

  const renderField = (color, index) => {
    switch (props.type) {
      case 'text': return (
        <TextField
          field={index}
          value={color}
          onChange={onUpdate}
          placeholder={props.placeholder}
        />
      )
      case 'select': {
        // const options = props.options?.length ? [{ value: props.defaultValue, label: props.defaultValue }, ...props.options] : null

        return (
          <DropdownField
            field={index}
            value={color}
            options={props.options}
            onChange={onUpdate}
            isOpened
          />
        )
      }
      default: return null
    }
  }

  return (
    <div style={{ flexDirection: props.multiline ? 'column' : 'row' }} className="colors flex">
      {
        props.value.map((color, index) => (
          <div style={{ width: props.width, marginTop: props.multiline ? '4px' : 0 }} className="flex items-center mr-2" key={index}>
            { renderField(color, index) }
            <div className="cursor-pointer ml-1" onClick={() => removeColor(color)}>&#x2715;</div>
          </div>
        ))
      }
      <div className="cursor-pointer p-2" onClick={addNew}>&#x2795;</div>
    </div>
  )
}

export default Multiple
