const Block = props => (
  <div className="w-full flex flex-col mt-12">
    <div className="flex items-end font-medium mx-12">
      <div className="text-2xl">{ props.title }</div>
      <div className={`ml-6 text-lg text-blue cursor-pointer ${!props.onViewAll && 'hidden'}`} onClick={props.onViewAll}>View All</div>
    </div>
    <div className="w-full flex">
      { props.children }
    </div>
  </div>
)

export default Block
