import NavItem from './NavItem'

const SettingsIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path d="M9 11.25C10.2426 11.25 11.25 10.2426 11.25 9C11.25 7.75736 10.2426 6.75 9 6.75C7.75736 6.75 6.75 7.75736 6.75 9C6.75 10.2426 7.75736 11.25 9 11.25Z" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1.5 9.6591V8.3391C1.5 7.5591 2.1375 6.9141 2.925 6.9141C4.2825 6.9141 4.8375 5.9541 4.155 4.7766C3.765 4.1016 3.9975 3.2241 4.68 2.8341L5.9775 2.0916C6.57 1.7391 7.335 1.9491 7.6875 2.5416L7.77 2.6841C8.445 3.8616 9.555 3.8616 10.2375 2.6841L10.32 2.5416C10.6725 1.9491 11.4375 1.7391 12.03 2.0916L13.3275 2.8341C14.01 3.2241 14.2425 4.1016 13.8525 4.7766C13.17 5.9541 13.725 6.9141 15.0825 6.9141C15.8625 6.9141 16.5075 7.5516 16.5075 8.3391V9.6591C16.5075 10.4391 15.87 11.0841 15.0825 11.0841C13.725 11.0841 13.17 12.0441 13.8525 13.2216C14.2425 13.9041 14.01 14.7741 13.3275 15.1641L12.03 15.9066C11.4375 16.2591 10.6725 16.0491 10.32 15.4566L10.2375 15.3141C9.5625 14.1366 8.4525 14.1366 7.77 15.3141L7.6875 15.4566C7.335 16.0491 6.57 16.2591 5.9775 15.9066L4.68 15.1641C3.9975 14.7741 3.765 13.8966 4.155 13.2216C4.8375 12.0441 4.2825 11.0841 2.925 11.0841C2.1375 11.0841 1.5 10.4391 1.5 9.6591Z" stroke="currentColor" strokeWidth="1.3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

const SettingsMenu = () => (
  <NavItem to="/settings">
    <div className="flex flex-col items-center"><SettingsIcon /></div>
    <span className="ml-2 leading-[15px]">Settings</span>
  </NavItem>
)

export default SettingsMenu
