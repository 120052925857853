import React, { useState, useEffect, useCallback } from 'react'
import dayjs from 'dayjs'
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom'
import relativeTime from 'dayjs/plugin/relativeTime'
import api from '~/api'
import NotificationManager from '~/services/NotificationManager'
import Drawer from '~/components/layout/Drawer'
import Tabs from '~/components/layout/Tabs'
import AdsComponent from '~/components/Ads/Ads'
import AdCard, {FakeAd} from '~/components/Ads/AdCard'
import Search from '~/components/Ads/Search'
import AdvancedSearch from '~/components/Ads/AdvancedSearch'
import AdsList from '~/components/Ads/AdsList'
import Filters from '~/components/Ads/Filters'
import AddToListing from '~/components/Research/AddToListing'
import TikTokIcon from '~/components/icons/TikTok'
import InstagramIcon from '~/components/icons/Instagram'
import FacebookIcon from '~/components/icons/Facebook'
import Modal from '~/components/layout/Modal'
import Button from '~/components/layout/Button'
import BulkActions from '~/components/Ads/BulkActions';
import { useGetUserQuery } from '../store/api/userApiSlice'

dayjs.extend(relativeTime)

const tabsOptions = [
  { value: 'all', label: 'All Platforms' },
  { value: 'tik_tok', label: <TikTokIcon /> },
  { value: 'instagram', label: <InstagramIcon /> },
  { value: 'facebook', label: <FacebookIcon /> },
]

const emptyObj = {}

const Ads = () => {
  const [loading, setLoading] = useState(true)
  const location = useLocation()
  const [ads, setAds] = useState({ ads: [], meta: {} })
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [isFiltersOpen, setFiltersOpen] = useState(false)
  const defaultActiveTab = searchParams.get('tab') || 'all'
  const [activeTab, setActiveTab] = useState(defaultActiveTab)
  const defaultSearchFilters = JSON.parse(searchParams.get('search')) || emptyObj
  const [searchFilters, setSearchFilters] = useState(defaultSearchFilters)
  const defaultFilters = JSON.parse(searchParams.get('filters')) || emptyObj
  const [filters, setFilters] = useState(defaultFilters)
  const defaultPage = JSON.parse(searchParams.get('page'))
  const [currentPage, setCurrentPage] = useState(defaultPage || 1)
  const [selectedAds, setSelectedAds] = useState([])
  const [isDeleteOpen, setDeleteIsOpen] = useState(false);
  const [user, setUser] = useState()
  const { data, status } = useGetUserQuery()
  useEffect(() => {
    if(status === "fulfilled") setUser(data?.user)
  }, [status])

  const [isModalOpen, setModalOpen] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState(null)

  const defaultSortBy = searchParams.get('sort_by')
  const [currentSortBy, setCurrentSortBy] = useState(defaultSortBy || 'created_at')

  useEffect(() => {
    if (!location.search.length) {
      setCurrentPage(1)
      setSearchFilters(emptyObj)
      setFilters(emptyObj)
    }
  }, [location.search])

  useEffect(() => {
    setLoading(true)
    const queryParams = { page: currentPage, sort_by: currentSortBy, tab: activeTab }
    if (filters && Object.keys(filters).length) {
      queryParams.filters = JSON.stringify(filters)
    }

    if (searchFilters?.value) {
      queryParams.search = JSON.stringify(searchFilters)
    }

    api.apiAds.list({ query: queryParams }).then(data => {
      setAds(data)
      setLoading(false)

      if (Object.keys(queryParams).length) {
        setSearchParams(queryParams)
      }
    })
  }, [filters, searchFilters, currentPage, currentSortBy, activeTab])

  const onSearch = useCallback(value => {
    setSearchFilters(value)
    setCurrentPage(1)
  }, [setSearchFilters, setCurrentPage])

  const onAdClick = useCallback(id => {
    navigate(`/ads/${id}`)
  }, [navigate])

  const showFilters = useCallback(() => {
    setFiltersOpen(true)
  }, [setFiltersOpen])

  const onFiltersChange = value => {
    setFilters(value)
    setCurrentPage(1)
    setFiltersOpen(false)
  }

  const onFiltersCancel = () => {
    setFilters(emptyObj)
    setCurrentPage(1)
    setFiltersOpen(false)
  }

  const onPageChange = useCallback(({ selected }) => {
    setCurrentPage(selected + 1)
  }, [setCurrentPage])

  const onSearchSave = useCallback(name => (
    api.apiAdsFilters.create({ data: {
      ads_filter: {
        name,
        search: searchFilters,
        filters,
        sort_by: currentSortBy
      }
    } })
  ), [searchFilters, filters, currentSortBy])

  const onSavedSearchChange = useCallback(savedSearch => {
    setSearchFilters(savedSearch.search)
    setFilters(savedSearch.filters)
  }, [setSearchFilters, setFilters])

  const onSavedSearchDelete = useCallback(id => (
    api.apiAdsFilters.destroy({ id })
  ), [])

  const addToSwipe = id => {
    setSelectedProduct(id)
    setModalOpen(true)
  }

  const onModalClose = () => {
    setModalOpen(false)
    setSelectedProduct(null)
  }

  const onSubmitToSwipe = (swipeId, successFn) => {
    api.apiSwipes.addProducts({ data: {
      id: swipeId,
      product_type: 'Ad',
      product_ids: [selectedProduct]
    } }).then(() => {
      setAds(prev => ({
        ...prev,
        ads: prev.ads.map(ad => (
          ad.id === selectedProduct
          ? { ...ad, swipesIds: [...ad.swipesIds, swipeId] }
          : ad
        ))
      }))
      if (typeof successFn === 'function') successFn()
      onModalClose()
      NotificationManager.success({ boldText: 'Ad', text: 'has been successfully added to swipe' })
    })
  }

  const toggleSelectAd = (id, add = true) => {
    add ? setSelectedAds(prevState => ([...prevState, id])) :  setSelectedAds(prevState => prevState.filter(i => i !== id));
  }

  const onBulkCancel = () => {
    setSelectedAds([])
  }

  const onBulkDelete = () => {
    setDeleteIsOpen(false)
    api.apiAds.deleteAds({
      data: {
        ad_ids: selectedAds
      }
    }).then(() => {
      setAds(prev => ({...prev, ads: prev.ads.filter(a => !selectedAds.includes(a.id))}))
      setSelectedAds([])
      NotificationManager.success({ boldText: `${selectedAds.length} ads`, text: 'have been successfully deleted.' });
    });
  }

  return (
    <AdsComponent>
      <div className="mt-[22px] flex text-8">Ads</div>
      <div className="w-full flex flex-wrap mt-6">
        <Search
          searchValue={searchFilters?.value}
          searchType={searchFilters?.type}
          onSearch={onSearch}
        />
      </div>
      <AdvancedSearch
        showFilters={showFilters}
        onSearchSave={onSearchSave}
        onSavedSearchChange={onSavedSearchChange}
        onSavedSearchDelete={onSavedSearchDelete}
        currentSortBy={currentSortBy}
        setCurrentSortBy={setCurrentSortBy}
      />
      {!!searchFilters?.value?.length &&
        <div className="w-full flex mt-6">
          <div className="w-auto flex-1 flex items-center h-24">
            <div className="flex-0 text-[32px] leading-9 font-medium">Results for "{searchFilters.value}"</div>
          </div>
        </div>
      }
      <div className="flex xl:mt-[60px] lg:mt-[40px] mt-[20px] xl:mb-6 lg:mb-4 mb-2">
        <Tabs options={tabsOptions} tab={activeTab} onChange={setActiveTab} />
      </div>
      <AdsList
        pagesCount={ads.meta.pagesCount}
        currentPage={currentPage}
        onPageChange={onPageChange}
      >
        {
          loading
          ? Array.from({ length: 16 }, (_, index) => <FakeAd key={index} />)
          : ads.ads.map(ad => (
            <AdCard
              key={ad.id}
              ad={ad}
              onClick={onAdClick}
              isFavourite={!!ad.swipesIds.length}
              addToSwipe={addToSwipe}
              selected={selectedAds.includes(ad.id)}
              toggleSelect={user?.admin && toggleSelectAd}
            />
          ))
        }
      </AdsList>
      <Drawer isOpen={isFiltersOpen} setIsOpen={setFiltersOpen}>
        <Filters filters={filters} onChange={onFiltersChange} onCancel={onFiltersCancel} onClose={() => setFiltersOpen(false)}/>
      </Drawer>
      {isModalOpen &&
        <AddToListing selectedIds={[selectedProduct]} onSubmit={onSubmitToSwipe} onClose={onModalClose} />
      }
      { user?.admin &&
      <BulkActions
          isOpen={selectedAds.length}
          selectedIds={selectedAds}
          onCancel={onBulkCancel}
          onDelete={() => setDeleteIsOpen(true)}
        />
        }
        <Modal isOpen={isDeleteOpen} onClose={() => setDeleteIsOpen(false)} headerText="Delete Ads">
        <div className="flex flex-col">
          <div className="flex text-secondary">Are you sure you want to delete {selectedAds.length} selected ads?</div>
          <div className="flex flex-1 mt-6">
            <div className="flex flex-1">
              <Button full secondary onClick={() => setDeleteIsOpen(false)}>Cancel</Button>
            </div>
            <div className="flex flex-1 ml-6" onClick={onBulkDelete}>
              <Button red full>Delete</Button>
            </div>
          </div>
        </div>
      </Modal>
    </AdsComponent>
  )
}

export default React.memo(Ads)
