import axios from 'axios'
import { useState, useRef, useEffect } from 'react'
import ImageUploading from 'react-images-uploading'

const BriefSceneImageField = props => {
  const [imageUrl, setImageUrl] = useState(props.value)

  console.log('PROPS', props)

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex)
    // setImage(imageList[0])

    const img = imageList[0]

    console.log('img', img)

    setImageUrl(img.data_url)

    const formData = new FormData()

    // brief_brand

    // Update the formData object
    formData.append(
      'brief_scene[image]',
      img.file,
      img.file.name
    )

    // Request made to the backend api
    // Send formData object
    // props.onUpdate(formData, 'logo')

    axios.patch(`/api/brief_scenes/${props.sceneId}?brief_id=${props.briefId}`, formData)
  }

  return (
    <div className="flex items-center justify-center w-full h-full">
      <ImageUploading onChange={onChange} dataURLKey="data_url">
        {({ onImageUpload }) => (
          <button className={`mt-4 w-64 h-64 border-gray/20 rounded-lg flex items-center justify-center ${imageUrl?.length ? '' : 'border'}`} onClick={onImageUpload}>
            { imageUrl?.length ? <img className="w-full h-full rounded-full" src={imageUrl} /> : '+' }
          </button>
        )}
      </ImageUploading>
    </div>
  )
}

export default BriefSceneImageField
