import { useState, useEffect, useCallback } from 'react'
import { createPortal } from 'react-dom'
import api from '~/api'
import AdCard from '~/components/Ads/AdCard'
import Search from '~/components/Ads/Search'
import AdsList from '~/components/Ads/AdsList'
import SwipesBulkActions from './SwipesBulkActions'

const SwipesList = ({ onClose, onSelect, adIds }) => {
  const [selectedProducts, setSelectedProducts] = useState([])
  const [ads, setAds] = useState({ ads: [], meta: {} })
  const [searchFilters, setSearchFilters] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const [currentSortBy, setCurrentSortBy] = useState('created_at')

  useEffect(() => {
    const queryParams = { page: currentPage, sort_by: currentSortBy }
    if (searchFilters?.value) {
      queryParams.search = JSON.stringify(searchFilters)
    }

    if (adIds.length) {
      queryParams.except_ids = adIds
    }

    api.apiAds.list({ query: queryParams }).then(data => {
      setAds(data)
    })
  }, [searchFilters, currentPage, currentSortBy])

  const onSearch = useCallback(value => {
    setSearchFilters(value)
    setCurrentPage(1)
  }, [setSearchFilters, setCurrentPage])

  const onPageChange = useCallback(({ selected }) => {
    setCurrentPage(selected + 1)
  }, [setCurrentPage])

  const onAdSelect = id => {
    setSelectedProducts(prev => prev.includes(id) ? prev : [...prev, id])
  }

  const onAdRemove = id => {
    setSelectedProducts(prev => prev.filter(el => el !== id))
  }

  const onBulkCancel = () => {
    setSelectedProducts([])
  }

  const onBulkSubmit = () => {
    onSelect(selectedProducts)
  }

  return createPortal(
    <div className="transition-opacity fixed w-full h-full top-0 left-0 flex items-center justify-center z-20">
      <div className="modal-overlay absolute w-full h-full bg-primary/85" onClick={onClose}></div>
      <div className="modal-container max-h-[90vh] bg-gray1 h-full w-11/12 rounded-lg shadow-lg z-50 overflow-y-auto">
        <div className="modal-content p-6 text-left border-b border-[#9FA6B7]/30">
          <div className="flex justify-between items-center">
            <p className="text-2xl font-medium">Add Inspirations from Swipes</p>
            <div className="modal-close p-1.5 rounded-full cursor-pointer z-50 bg-[rgba(159,166,183,0.20)] text-gray" onClick={onClose}>
              <svg className="fill-current" width="20" height="20" viewBox="0 0 18 18">
                <path fill="currentColor" d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
              </svg>
            </div>
          </div>
          <div className="flex flex-row flex-wrap flex-1 flex-grow content-start mb-[60px]">
            <div className="w-full flex flex-wrap mt-6">
              <Search
                searchValue={searchFilters?.value}
                searchType={searchFilters?.type}
                onSearch={onSearch}
              />
            </div>
            {!!searchFilters?.value?.length &&
              <div className="w-full flex mt-6">
                <div className="w-auto flex-1 flex items-center h-24">
                  <div className="flex-0 text-[32px] leading-9 font-medium">Results for "{searchFilters.value}"</div>
                </div>
              </div>
            }
            <AdsList
              pagesCount={ads.meta.pagesCount}
              currentPage={currentPage}
              onPageChange={onPageChange}
            >
              {
                ads.ads.map(ad => (
                  <AdCard
                    key={ad.id}
                    ad={ad}
                    isFavourite={false}
                    onSelect={() => onAdSelect(ad.id)}
                    onUnselect={() => onAdRemove(ad.id)}
                    isSelected={selectedProducts.includes(ad.id)}
                  />
                ))
              }
            </AdsList>
            <SwipesBulkActions
              isOpen={selectedProducts.length}
              selectedIds={selectedProducts}
              onAddToSwipe={onBulkSubmit}
              onCancel={onBulkCancel}
            />
          </div>
        </div>
      </div>
    </div>,
    document.getElementById('modal-root'))
}

export default SwipesList
