import NavItem from './NavItem'

const ResearchIcon = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.625 15.75C12.56 15.75 15.75 12.56 15.75 8.625C15.75 4.68997 12.56 1.5 8.625 1.5C4.68997 1.5 1.5 4.68997 1.5 8.625C1.5 12.56 4.68997 15.75 8.625 15.75Z" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16.5 16.5L15 15" stroke="currentColor" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

const ResearchMenu = () => (
  <NavItem to="/research">
    <div className="flex flex-col items-center"><ResearchIcon /></div>
    <span className="ml-2 leading-[15px]">Research</span>
  </NavItem>
)

export default ResearchMenu
