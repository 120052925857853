import { useRef } from 'react'

const Video = props => {

  const videoRef = useRef()

  const onVideoMouseEnter = () => {
    videoRef.current.play()
  }

  const onVideoMouseLeave = () => {
    videoRef.current.pause()
  }

  return (
    <div className="w-full h-full" onMouseEnter={onVideoMouseEnter} onMouseLeave={onVideoMouseLeave}>
      <video className="w-full h-full aspect-square" muted loop ref={videoRef} controls autoPlay={false} playsInline key={props.id}>
        <source src={props.url} type="video/mp4" />
      </video>
    </div>
  )
}
export default Video
