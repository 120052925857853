import { useState } from 'react'
import api from '~/api'
import Button from '~/components/layout/Button'
import s from './BriefDetail.module.scss'
import Description from './Details/Description'
import Size from './Details/Size'
import Format from './Details/Format'
import Duration from './Details/Duration'
import Platforms from './Details/Platforms'
import Style from './Details/Style'
import Products from './Details/Products'

const BriefDetail = props => {
  const [detail, setDetail] = useState(props.detail)

  const onChange = val => {
    console.log('onChange', val)

    api.apiBriefDetails.update({ data: { brief_id: props.briefId, id: detail.id, brief_detail: { value: val } } }).then(data => {
      setDetail(data.briefDetail)
    })
  }

  const onDelete = () => {
    api.apiBriefDetails.destroy({ data: { brief_id: props.briefId, id: detail.id } }).then(() => {
      props.onDelete(detail.id)
    })
  }

  const DetailContent = (() => {
    switch (detail.detailType) {
      case 'description': return Description
      case 'size': return Size
      case 'format': return Format
      case 'duration': return Duration
      case 'platforms': return Platforms
      case 'style': return Style
      case 'products': return Products
      case 'assets': return Products
      case 'sound': return Products
      default: return null
    }
  })()

  return (
    <div className={s.BriefDetail}>
      <div className="flex items-center justify-between p-4 border-b border-gray/20 font-medium">
        <div className={s.title}>{ props.label }</div>
        <div><Button secondary onClick={onDelete}>Delete</Button></div>
      </div>
      { DetailContent && <DetailContent value={detail.value} onChange={onChange} /> }
    </div>
  )
}

export default BriefDetail
