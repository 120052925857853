import { useEffect } from 'react'

const useClickOutside = (newRef, onClick) => {
  useEffect(() => {
    const handleClickOutside = e => {
      if (newRef.current && !newRef.current.contains(e.target)) {
        onClick()
      }
    }

    document.addEventListener('click', handleClickOutside, true)

    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [onClick])
}

export default useClickOutside
