import { useState, useRef, useEffect } from 'react'
import ContentEditable from 'react-contenteditable'
import { useDebounce } from 'use-debounce'
import s from './TextareaField.module.scss'

const TextField = props => {
  const [val, setVal] = useState(props.value || '')
  const [debouncedValue] = useDebounce(val, 500)
  const innerRef = useRef()

  useEffect(() => {
    if (debouncedValue !== props.value) {
      props.onChange(debouncedValue, props.field)
    }
  }, [debouncedValue])

  const onChange = e => {
    setVal(e.target.value)
  }

  return (
    <div className={s.TextareaField}>
      <ContentEditable
        innerRef={innerRef}
        html={val}
        onChange={onChange}
        placeholder={props.placeholder}
      />
    </div>
  )
}

export default TextField
