import { useState } from 'react'
import Modal from '~/components/layout/Modal'
import Button from '~/components/layout/Button'
import Input from '~/components/layout/Input'

const NewSwipeGroup = props => {
  const [name, setName] = useState('')

  const onNameChange = e => {
    setName(e.target.value)
  }

  const onSubmit = () => {
    if (!name?.trim()?.length) return

    props.onSubmit(name).then(() => {
      props.onClose()
    })
  }

  return (
    <Modal isOpen onClose={props.onClose} headerText="Create New Folder">
      <div className="flex flex-col">
        <div className="flex text-secondary">Folder Name</div>
        <div className="flex mt-2">
          <Input value={name} onChange={onNameChange} placeholder="Name" />
        </div>
        <div className="flex flex-1 mt-6">
          <div className="flex flex-1">
            <Button full secondary onClick={props.onClose}>Cancel</Button>
          </div>
          <div className="flex flex-1 ml-6" onClick={onSubmit}>
            <Button full>Submit</Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default NewSwipeGroup
