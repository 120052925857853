import React from 'react'
import { NavLink } from 'react-router-dom'
import SwipeIcon from '~/components/icons/Swipe'

const TreeElement = React.memo(() => (
  <svg width="14" height="52" viewBox="0 0 14 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 51.75C13.4142 51.75 13.75 51.4142 13.75 51C13.75 50.5858 13.4142 50.25 13 50.25L13 51.75ZM1 43L1.75 43L1 43ZM0.250002 -5.461e-07L0.25 43L1.75 43L1.75 -5.02973e-07L0.250002 -5.461e-07ZM9 51.75L13 51.75L13 50.25L9 50.25L9 51.75ZM0.25 43C0.25 47.8325 4.16751 51.75 9 51.75L9 50.25C4.99594 50.25 1.75 47.0041 1.75 43L0.25 43Z" fill="#2B2B2C" />
  </svg>
))

const Swipe = props => (
  <NavLink to={`/swipes/${props.id}`} className="flex relative overflow-hidden ml-[36px] rounded-[10px] pl-[2px]">
    {({ isActive }) => (
      <>
        <div className="absolute left-0 bottom-[18px]"><TreeElement /></div>
        <div className={`px-3 py-[10px] flex items-center rounded-lg hover:text-white ${isActive ? 'text-white' : 'text-white/40'}`}>
          <span className="ml-[6px]"><SwipeIcon /></span>
          <span className="ml-2">{ props.name }</span>
        </div>
      </>
    )}
  </NavLink>
)

export default React.memo(Swipe)
