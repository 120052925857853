import { useState, useEffect } from 'react'
import Button from '~/components/layout/Button'
import SearchIcon from '~/components/icons/Search'
import LoadingIcon from '~/components/icons/Loading'

const SearchBox = props => {
  const [searchValue, setSearchValue] = useState(props.value || '')
  const [inputError, setInputError] = useState(false)

  useEffect(() => {
    setSearchValue(props.value || '')
  }, [props.value])

  const onSearch = () => {
    if (!searchValue) return

    const trimValue = searchValue.trim()

    if (trimValue.length) {
      props.onSearch(trimValue)
    }
  }

  const onSearchKeyDown = e => {
    if (e.key === 'Enter') {
      onSearch()
    }
  }

  const onSearchValueChange = e => {
    setSearchValue(e.target.value)
    if(e.target.value.trim().split(" ").length > 3){
      setInputError(true)
    }
    else{
      setInputError(false)
    }
  }

  return (
    <div className="w-auto flex-1 border-b-1">
      <div className="flex flex-col justify-center">
        <div className="input-group relative flex items-center w-full rounded-lg bg-white h-12 px-1.5 shadow-[0_4px_50px_rgba(0,0,0,0.05)]">
          <SearchIcon className="ml-3" />
          <input type="search" value={searchValue} onKeyDown={onSearchKeyDown} onChange={onSearchValueChange} className="form-control h-[30px] relative flex-auto min-w-0 block w-full px-2 text-base font-normal text-gray-700 bg-white bg-clip-padding transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" placeholder="Search" aria-label="Search" aria-describedby="button-addon3" />
          {
            props.isSearching
            ? <Button disabled><LoadingIcon />Searching...</Button>
            : (props.isSearchCompleted ? <Button >Completed</Button> : <Button onClick={onSearch}>Search</Button>)
          }
        </div>
        { inputError &&
          <div className="p-1 text-error text-sm">Trends Report and Suggested Terms cannot be generated on long tail keywords. Try more generatic and short keyword. </div>
        }
      </div>
    </div>
  )
}

export default SearchBox
