import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import ReactPaginate from 'react-paginate'
import api from '~/api'
import AdCard from '~/components/Ads/AdCard'

dayjs.extend(relativeTime)

const AdvertiserTab = props => {
  const [ads, setAds] = useState([])
  const [meta, setMeta] = useState({})
  const [currentPage, setCurrentPage] = useState(1)
  const navigate = useNavigate()

  useEffect(() => {
    api.apiAds.list({ query: {
      type: 'advertiser',
      ad_id: props.id,
      page: currentPage,
      limit: 20
    } })
    .then(data => {
      setAds(data.ads)
      setMeta(data.meta)
    })
  }, [props.id, currentPage])

  const onAdClick = id => {
    navigate(`/ads/${id}`)
  }

  const onPageChange = ({ selected }) => {
    setCurrentPage(selected + 1)
  }

  return (
    <div className="flex flex flex-col">
      <div className="w-full grid gap-6 grid-cols-4 -mt-1 lg:gap-6 lg:grid-cols-4 md:gap-4 md:grid-cols-3 sm:gap-2 sm:grid-cols-2">
        {
          ads.map(ad => (
            <AdCard
              key={ad.id}
              ad={ad}
              onClick={onAdClick}
              isFavourite={!!ad.swipesIds.length}
            />
          ))
        }
      </div>
      <div className="w-full">
        {meta.pagesCount > 1 &&
          <ReactPaginate
            onPageChange={onPageChange}
            pageRangeDisplayed={5}
            pageCount={meta.pagesCount}
            renderOnZeroPageCount={null}
            className="pagination justify-end mt-4"
            previousLabel="Previous"
            nextLabel="Next"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            forcePage={currentPage - 1}
          />
        }
      </div>
    </div>
  )
}

export default React.memo(AdvertiserTab)
