import React, { useState } from 'react'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import relativeTime from 'dayjs/plugin/relativeTime'
import Tabs from '~/components/layout/Tabs'
import Modal from '~/components/layout/Modal'
import BrandsList from '~/components/Briefs/BrandsList'
import BrandShow from '~/components/Briefs/BrandShow'

dayjs.extend(relativeTime)

const tabsOptions = [
  { value: 'briefs', label: 'Briefs' },
  { value: 'brands', label: 'Brands' },
]

const Brands = () => {
  const navigate = useNavigate()
  const [selectedBrandId, setSelectedBrandId] = useState()

  const setActiveTab = tab => {
    navigate(`/${tab}`)
  }

  const onBrandSelect = id => {
    setSelectedBrandId(id)
  }

  return (
    <div className="flex flex-row flex-wrap flex-1 flex-grow content-start py-4 px-[60px]">
      <div className="flex-1 mt-[22px] flex items-center justify-between">
        <div className="text-8 basis-[150px]">Briefs</div>
        <div className="flex-auto flex justify-center">
          <div className="flex">
            <Tabs options={tabsOptions} tab="brands" onChange={setActiveTab} />
          </div>
        </div>
        <div className="min-w-[120px]"></div>
      </div>
      <BrandsList onSelect={onBrandSelect} />
      <Modal isOpen={!!selectedBrandId} onClose={() => setSelectedBrandId(null)} headerText="Edit Brand Profile" wide>
        { selectedBrandId && <BrandShow id={selectedBrandId} /> }
      </Modal>
    </div>
  )
}

export default React.memo(Brands)
