// import Dropdown from '~/components/layout/Dropdown'
import { useState, useRef } from 'react'
import useClickOutside from '~/components/layout/hooks/useClickOutside'
import s from './DropdownField.module.scss'

const DropdownField = props => {
  const newRef = useRef()
  const [isOpen, setOpen] = useState(!!props.isOpened)

  useClickOutside(newRef, () => setOpen(false))

  const onSelect = value => {
    props.onChange(value, props.field)
    setOpen(false)
  }

  const getLabel = () => (
    props.options.find(o => o.value === props.value)?.label
  )

  return (
    <div className={s.DropdownField}>
      <div className={s.control} onClick={() => setOpen(prev => !prev)}>
        { props.icon && <div className="mr-1">{ props.icon }</div> }
        { getLabel() }
      </div>
      {isOpen &&
        <div ref={newRef} className={s.dropdown}>
          {
            props.options.map(option => (
              <div key={option.value} className={s.itemWrapper} onClick={() => onSelect(option.value)}>
                <div className={s.item}>
                  { option.label }
                </div>
              </div>
            ))
          }
        </div>
      }
    </div>
  )
}

export default DropdownField
