import React from 'react'
import DailyTrends from './DailyTrends'
import RealtimeTrends from './RealtimeTrends'
function TrendsSection() {
  return (
    <div className="w-full grid grid-cols-2 gap-4">
      <DailyTrends />
      <RealtimeTrends />
    </div>
  )
}

export default TrendsSection
