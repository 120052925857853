import React, { useState, useEffect } from 'react'
import Gravatar from 'react-gravatar'
import { Popover } from 'react-tiny-popover'
import api from '~/api'
import HomeMenu from '~/components/Nav/HomeMenu'
import ResearchMenu from '~/components/Nav/ResearchMenu'
import TrendingMenu from '~/components/Nav/TrendingMenu'
import AdsMenu from '~/components/Nav/AdsMenu'
import SettingsMenu from '~/components/Nav/SettingsMenu'
import VideosMenu from '~/components/Nav/VideosMenu'
import HelpMenu from '~/components/Nav/HelpMenu'
import ProductsMenu from '~/components/Nav/ProductsMenu'
import BriefsMenu from '~/components/Nav/BriefsMenu'
import HobbiesMenu from '~/components/Nav/HobbiesMenu'
import AICopyMenu from '~/components/Nav/AICopyMenu'
import LogoIcon from '~/components/icons/Logo'
import ArrowDownIcon from '~/components/icons/ArrowDown'
import Swipes from './Swipes'
import { useDispatch } from 'react-redux';
import { useGetUserQuery } from '../../store/api/userApiSlice'
import { purgeState } from '../../store/store'

const Sidebar = () => {
  const [popoverIsOpen, setPopoverIsOpen] = useState(false)
  const [user, setUser] = useState()
  const { data, status } = useGetUserQuery()
  useEffect(() => {
    if(status === "fulfilled") setUser(data?.user)
  }, [status])

  return (
    <div id="sidebar" className="flex flex-col justify-between h-screen xl:w-[214px] lg:w-[194px] w-[174px] menu flex nunito static fixed bg-[#111112]">
      <div className="flex flex-col h-[calc(100%-210px)]">
        <div className="p-6 flex-none">
          <LogoIcon />
        </div>
        <div className="flex-none px-[10px] py-4 pt-0 w-full lg:h-auto h-[50%] overflow-auto">
          <ul className="list-reset flex flex-col items-start w-full text-sm font-medium">
            <HomeMenu />
            <ResearchMenu />
            <TrendingMenu />
            <AdsMenu />
            <ProductsMenu />
            <BriefsMenu />
            <HobbiesMenu />
            <AICopyMenu />
          </ul>
        </div>
        <Swipes />
      </div>
      <div className="p-[10px] flex flex-col justify-between h-[210px] border-t border-t-white/[.17]">
        <div className="text-sm">
          <SettingsMenu />
          <VideosMenu />
          <HelpMenu />
        </div>
        <Popover
          isOpen={popoverIsOpen}
          positions={['top']}
          onClickOutside={() => setPopoverIsOpen(false)}
          content={
            <div className="shadow-md bg-white px-4 py-3 rounded-lg w-48 text-sm" onClick={() => purgeState()} >
              <a href="/users/sign_out">Log Out</a>
            </div>
          }
          padding={10}
          containerStyle={{ zIndex: '100' }}
        >
          <div className="flex items-center justify-between bg-white/[.07] rounded-lg px-3 py-[10px] cursor-pointer min-h-[47px]" onClick={() => setPopoverIsOpen(!popoverIsOpen)}>
            <div className="flex items-center overflow-hidden">
              {user &&
                <>
                  <Gravatar size={27} className="rounded-full" email={user.email} />
                  <div className="mx-2 font-medium text-sm text-white truncate">{user.name}</div>
                </>
              }
            </div>
            <div className="">
              <ArrowDownIcon />
            </div>
          </div>
        </Popover>
      </div>
    </div>
  )
}

export default React.memo(Sidebar)
