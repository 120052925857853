import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import api from '~/api'
import NotificationManager from '~/services/NotificationManager'
import ResearchComponent from '~/components/Research/Research'
import RecentSearch from '~/components/Research/RecentSearch'
import SearchBox from '~/components/Research/SearchBox'
import ArrowLeftIcon from '~/components/icons/ArrowLeft'

const Research = () => {
  const navigate = useNavigate()
  const [search, setSearch] = useState()
  const initialSearchValue = useParams().query
  const [isSearching, setIsSearching] = useState()

  const performSearch = (query, category, callback) => {
    setSearch(null)
    api.apiSearch.get({ query: { query, category } }).then(data => {
      setSearch(data.search)
      callback?.()
    }).catch(e => {
      NotificationManager.error({ boldText: 'Forbidden', text: e.response.data.error })
    })
  }

  const onSearch = (query, category) => {
    setIsSearching(true)
    performSearch(query, category, () => {
      setIsSearching(false)
      navigate(`/research/${encodeURIComponent(query)}`)
    })
  }

  // const onBack = () => {
  //   setSearch(null)
  //   navigate('/research')
  // }

  useEffect(() => {
    if (!initialSearchValue?.length) {
      setSearch(null)
      return
    }
    if (search && search.query === initialSearchValue) return

    performSearch(initialSearchValue)
  }, [initialSearchValue])

  const updateSearchStatus = () => {
    if(!isSearching && search) {
      setSearch({...search, status: 'success'})
    }
  }

  return (
    <div className="flex flex-row flex-wrap flex-1 flex-grow content-start py-4 px-[60px] w-full">
      <div className="w-full flex flex-wrap">
        { initialSearchValue && <Link className="flex items-center w-[60px] ml-[-15px] mr-[15px] px-[15px] cursor-pointer" to="/research"><ArrowLeftIcon /></Link> }
        <SearchBox value={initialSearchValue} onSearch={onSearch} isSearching={isSearching || ['fetching', 'pending'].includes(search?.status)} isSearchCompleted={search?.status == 'success'} />
      </div>
      {
        search
        ? <ResearchComponent search={search} updateIsSearching={updateSearchStatus}/>
        : (!initialSearchValue && <RecentSearch />)
      }
    </div>
  )
}

export default Research
