import { useState, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import api from '~/api'
import Button from '~/components/layout/Button'
import TextField from '~/components/Briefs/form/TextField'
import BriefSceneImageField from '~/components/Briefs/form/BriefSceneImageField'
import s from './BriefScene.module.scss'

const BriefScene = props => {
  const [scene, setScene] = useState(props.scene)

  const ref = useRef(null)

  const onChange = (val, field) => {
    console.log('onChange', val, field)

    api.apiBriefScenes.update({ data: { brief_id: props.briefId, id: scene.id, brief_scene: { [field]: val } } }).then(data => {
      setScene(data.briefScene)
    })
  }

  const [{ handlerId }, drop] = useDrop({
    accept: 'SCENE',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = props.index
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      // Get horizontal middle
      const hoverMiddleX =
        (hoverBoundingRect.right - hoverBoundingRect.left) / 2
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      // Determine mouse position
      const clientOffset = monitor.getClientOffset()
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      // Get pixels to the left
      const hoverClientX = clientOffset.x - hoverBoundingRect.left
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY && hoverClientX < hoverMiddleX) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY && hoverClientX > hoverMiddleX) {
        return
      }
      // Time to actually perform the action
      props.moveScenePreview(dragIndex, hoverIndex)
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })
  const [{ isDragging }, drag] = useDrag({
    type: 'SCENE',
    item: () => ({ id: scene.id, index: props.index }),
    end: item => {
      onChange(item.index + 1, 'position')
    },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0.7 : 1
  drag(drop(ref))

  const onDelete = () => {
    api.apiBriefScenes.destroy({ data: { brief_id: props.briefId, id: scene.id } }).then(() => {
      props.onDelete(scene.id)
    })
  }

  return (
    <div style={{ opacity }} ref={ref} className={s.BriefScene}>
      <div className="flex items-center justify-between p-4 border-b border-gray/20 font-medium">
        <div className="flex">
          <div className="text-xs p-3 rounded-full border border-gray w-6 h-6 flex justify-center items-center font-normal">{ props.index + 1 }</div>
          <div className="ml-4">
            <TextField field="name" value={scene.name} onChange={onChange} placeholder="Scene label" />
          </div>
        </div>
        <div><Button secondary onClick={onDelete}>Delete</Button></div>
      </div>
      <div className="flex">
        <BriefSceneImageField briefId={props.briefId} sceneId={scene.id} value={scene.imageUrl} />
      </div>
      <div className="flex flex-col p-4 text-xs">
        <div className="flex">
          Script Copy
        </div>
        <div className="flex mt-1">
          <TextField field="script_copy" value={scene.scriptCopy} onChange={onChange} placeholder="Script copy" />
        </div>
        <div className="mt-2 flex">
          Action Description
        </div>
        <div className="flex mt-1">
          <TextField field="action_description" value={scene.actionDescription} onChange={onChange} placeholder="Action description" />
        </div>
        <div className="mt-2 flex">
          Text Overlay
        </div>
        <div className="flex mt-1">
          <TextField field="text_overlay" value={scene.textOverlay} onChange={onChange} placeholder="Text overlay" />
        </div>
      </div>
    </div>
  )
}

export default BriefScene
