import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Provider } from "react-redux"
import store, { persistor } from '~/store/store'
import { PersistGate } from 'redux-persist/integration/react';
import Home from '~/pages/Home'
import Research from '~/pages/Research'
import Trending from '~/pages/Trending'
import Listing from '~/pages/Listing'
import Ads from '~/pages/Ads'
import AdProducts from '~/pages/AdProducts'
import AdProduct from '~/pages/AdProduct'
import Advertiser from '~/pages/Advertiser'
import Ad from '~/pages/Ad'
import Advertisers from '~/pages/Advertisers'
import AdStores from '~/pages/AdStores'
import Settings from '~/pages/Settings'
import Briefs from '~/pages/Briefs'
import Brief from '~/pages/Brief'
import Hobbies from '~/pages/Hobbies'
import AICopy from '~/pages/AICopy'
import Videos from '~/pages/Videos'
import Brands from '~/pages/Brands'
import Sidebar from '~/components/Sidebar/Sidebar'
import Notification from '~/components/Notification'

const App = () => {
  return (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <Router>
      <Sidebar />
      <div className="flex flex-row flex-wrap flex-1 flex-grow content-start xl:pl-[214px] lg:pl-[194px] pl-[174px] overflow-x-hidden">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/research" element={<Research />}>
            <Route path="/research/:query" element={<Research />} />
          </Route>
          <Route path="/trending" element={<Trending />}>
            <Route path=":type" element={<Trending />} />
          </Route>
          <Route path="/swipes/:id" element={<Listing />} />
          <Route path="/ads" element={<Ads />} />
          <Route path="/ads/:id" element={<Ad />} />
          <Route path="/advertisers" element={<Advertisers />} />
          <Route path="/ad_stores" element={<AdStores />} />
          <Route path="/products" element={<AdProducts />} />
          <Route path="/products/:id" element={<AdProduct />} />
          <Route path="/advertisers/:id" element={<Advertiser />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/briefs" element={<Briefs />} />
          <Route path="/hobbies" element={<Hobbies />} />
          <Route path="/ai" element={<AICopy />} />
          <Route path="/videos" element={<Videos />} />
          <Route path="/briefs/:id" element={<Brief />} />
          <Route path="/brands" element={<Brands />} />
        </Routes>
        <Notification />
      </div>
    </Router>
    </PersistGate>
  </Provider>
)}
export default App
